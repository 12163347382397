import { type SVGProps, useRef } from "react";

const ChangeLanguageIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M12 22.2002C17.5228 22.2002 22 17.723 22 12.2002C22 6.67735 17.5228 2.2002 12 2.2002C6.47715 2.2002 2 6.67735 2 12.2002C2 17.723 6.47715 22.2002 12 22.2002Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99961 3.2002H8.99961C7.04961 9.0402 7.04961 15.3602 8.99961 21.2002H7.99961"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3.2002C16.95 9.0402 16.95 15.3602 15 21.2002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 16.2002V15.2002C8.84 17.1502 15.16 17.1502 21 15.2002V16.2002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.20029C8.84 7.25029 15.16 7.25029 21 9.20029"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChangeLanguageIcon;
