import classNames from "classnames";
import type { FC, HTMLAttributes } from "react";

interface BackdropProps {
  /**
   * Set z-index CSS property.
   */
  zIndex?: number;
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions.
   */
  blur?: boolean;
}

const Backdrop: FC<BackdropProps & HTMLAttributes<HTMLDivElement>> = ({
  zIndex,
  className,
  blur: blurBackdrop,
  ...restProps
}) => (
  <div
    className={classNames(
      "fixed top-0 start-0 end-0 bottom-0 bg-[#00000091]",
      className,
      {
        "backdrop-blur": blurBackdrop,
      }
    )}
    style={{ zIndex }}
    {...restProps}
  />
);

export default Backdrop;
