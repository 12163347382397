import { BundleCategoryItemType } from "@/helpers/api/types/api-response";
import { BundleCategoryDisplayTypes } from "@/global/enum";
import classNames from "classnames";
import { FC, createElement } from "react";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "./cn";
import BundleGridDisplay from "./grid/list";
import BundleSliderDisplay from "./slider/list";
import { BundleDisplayProps } from "./types";

interface BundleCategoryProps {
  data: BundleCategoryItemType;
}

const BUNDLE_DISPLAY_ITEMS_MAP: Record<
  BundleCategoryDisplayTypes,
  FC<BundleDisplayProps>
> = {
  [BundleCategoryDisplayTypes.GRID]: BundleGridDisplay,
  [BundleCategoryDisplayTypes.SLIDER]: BundleSliderDisplay,
};

const BundleCategory: FC<BundleCategoryProps> = ({ data }) => {
  const { title, displayType, color, bundles } = data;
  const { titleClassNames } = BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];
  return (
    <div className="mb-4 w-full">
      <div className="px-4">
        <div
          className={classNames(
            titleClassNames,
            "mb-4 text-center px-4 py-1.5 font-bold text-xl relative rounded-lg box-shadow-inner-0-1-md overflow-hidden before:absolute before:inset-0 before:z-10 before:box-shadow-inner-0-10 before:shadow-neutral-0/[0.15] before:rounded-lg"
          )}
        >
          {title}
        </div>
      </div>
      {createElement(BUNDLE_DISPLAY_ITEMS_MAP[displayType], {
        bundles,
        color,
      })}
    </div>
  );
};

export default BundleCategory;
