import { StateCreator } from "zustand";

export type AdState = {
  lastAdWatchedTimestamp?: number;
  setLastAdWatchedTimestamp: (l: number) => void;
};

export const createAdState: StateCreator<AdState> = (set) => ({
  lastAdWatchedTimestamp: undefined,
  setLastAdWatchedTimestamp: (lastAdWatchedTimestamp) =>
    set({
      lastAdWatchedTimestamp,
    }),
});
