import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

const copyToClipBoard = (text: string, withoutToast?: boolean) => {
  try {
    copy(text, { format: "text/plain" });
    !withoutToast &&
      toast.success("The address was successfully copied", {
        position: "top-center",
        duration: 5000,
      });
  } catch (e) {
    toast.error("Something went wrong", {
      position: "top-center",
      duration: 5000,
    });
  }
};

export default copyToClipBoard;
