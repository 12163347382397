import { ComponentProps, FC } from "react";
import { FormattedNumber } from "react-intl";

const NumberWithSuffix: FC<ComponentProps<typeof FormattedNumber>> = ({
  value,
  ...restProps
}) => {
  let formattedValue;
  let suffix = "";

  if (value >= 1_000_000) {
    formattedValue = value / 1_000_000;
    suffix = "m";
  } else if (value >= 1_000) {
    formattedValue = value / 1_000;
    suffix = "k";
  } else {
    formattedValue = value;
  }

  return (
    <FormattedNumber
      minimumFractionDigits={0}
      maximumFractionDigits={2}
      {...restProps}
      value={formattedValue}
    >
      {(formatted) => (
        <>
          {formatted}
          {suffix}
        </>
      )}
    </FormattedNumber>
  );
};

export default NumberWithSuffix;
