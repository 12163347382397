import { FC, useState } from "react";
import classNames from "classnames";
import Image from "@/components/common/image";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import useAdsgram from "@/hooks/use-adsgram";
import { IMAGES } from "@/constants";
import QuestActionButton from "./quest-action-button";
import {
  BundleItemType,
  UserAdQuestResponseType,
  VerifyWatchAdResponse,
} from "@/helpers/api/types/api-response";
import toast from "react-hot-toast";
import useApi from "@/helpers/api/hooks/use-api";
import { useData } from "@/store/store";
import { AssetUnit } from "@/global/enum";
import { usePersistData } from "@/store/persist-store";
import { showErrorMessage } from "@/utils/string";
import useBalance from "@/hooks/use-balance";
import CountDown from "@/components/common/count-down";

type AdItemProps = Omit<UserAdQuestResponseType, "date"> & {
  bundle: BundleItemType;
  setLastWatchedRewardPurchase: (p?: VerifyWatchAdResponse["purchase"]) => void;
};

const AD_DELAY_TIME = 5 * 60 * 1000;

const AdItem: FC<AdItemProps> = ({
  bundle,
  maxCount,
  count,
  purchase,
  setLastWatchedRewardPurchase,
}) => {
  const { balances } = useData();
  const { updateBalances } = useBalance();
  const { verifyWatchedAd, claimReward } = useApi();
  const [loading, setLoading] = useState<boolean>(false);

  const lastWatchedRewardPurchaseId = purchase?.id;

  const { lastAdWatchedTimestamp, setLastAdWatchedTimestamp } =
    usePersistData();

  const [remainingTime, setRemainingTime] = useState<number>(
    lastAdWatchedTimestamp &&
      lastAdWatchedTimestamp + AD_DELAY_TIME > Date.now()
      ? lastAdWatchedTimestamp + AD_DELAY_TIME
      : 0
  );

  const { formatMessage } = useIntl();
  const { amount: rewardAmount, asset } = bundle?.bundleProducts[0] || {};

  const onWatchCompleted = () => {
    setLoading(true);
    verifyWatchedAd({
      blockId: parseInt(import.meta.env.VITE_ADSGRAM_BLOCKID),
    })
      .then(({ purchase }) => {
        setLastWatchedRewardPurchase(purchase);
      })
      .catch((e) => {
        toast.error(e.message, {
          duration: 5000,
        });
      })
      .finally(() => setLoading(false));
  };

  const onError = (error: any) => {
    toast.error(showErrorMessage(error.description));
  };

  const { showAd, loadingAd } = useAdsgram({
    blockId: import.meta.env.VITE_ADSGRAM_BLOCKID,
    onWatchCompleted,
    onError,
  });

  const handleClaimReward = () => {
    setLoading(true);
    claimReward(lastWatchedRewardPurchaseId!)
      .then(() => {
        toast.success(
          formatMessage(
            { id: "successfully-claimed-reward" },
            { amount: rewardAmount, asset: asset?.title }
          ),
          {
            duration: 5000,
          }
        );
        setLastWatchedRewardPurchase(undefined);
        setLastAdWatchedTimestamp(Date.now());
        setRemainingTime(Date.now() + AD_DELAY_TIME);
        updateBalances();
      })
      .catch((e) => {
        toast.error(e.message, {
          duration: 5000,
        });
      })
      .finally(() => setLoading(false));
  };

  const dailyLimitReached = count >= maxCount && !lastWatchedRewardPurchaseId;

  const renderActionButtonContent = () => {
    if (lastWatchedRewardPurchaseId) return <FormattedMessage id="claim" />;
    if (loading) return <FormattedMessage id="loading" />;
    if (dailyLimitReached) return <FormattedMessage id="daily-limit" />;
    else {
      if (remainingTime)
        return (
          <CountDown
            expireAt={remainingTime}
            onExpire={() => setRemainingTime(0)}
          />
        );
      return <FormattedMessage id="open" />;
    }
  };

  const disableClick =
    loading || loadingAd || dailyLimitReached || remainingTime;

  return (
    <div
      className={classNames(
        "p-2 flex items-center gap-3 bg-blue-900 rounded-xl border border-blue-700",
        { "animate-pulse": loading || loadingAd }
      )}
      onClick={
        disableClick
          ? undefined
          : lastWatchedRewardPurchaseId
          ? handleClaimReward
          : showAd
      }
    >
      <Image
        src={IMAGES.AD_ICON}
        className="size-11 object-contain animate-fade-in shrink-0"
        alt="icon"
      />
      <div className="grow">
        <p className="text-sm font-bold text-neutral-50">
          <FormattedMessage id="watch-ads" />
        </p>
        <div className="flex items-center gap-1 text-sm text-neutral-50">
          <Image
            src={balances![AssetUnit.GEM].asset.logo.path}
            className="size-6 object-contain animate-fade-in"
          />
          <span className="text-green-300 font-bold">
            +<FormattedNumber value={rewardAmount} />
          </span>
          <span>{asset?.title}</span>
        </div>
      </div>
      <div className="animate-grow-up">
        <div className="flex flex-col items-center gap-1">
          <QuestActionButton
            appearance={lastWatchedRewardPurchaseId ? "secondary" : "primary"}
            disabled={dailyLimitReached}
          >
            {renderActionButtonContent()}
          </QuestActionButton>
          {count < maxCount && (
            <span className="text-yellow-600 text-xs">
              {count} / {maxCount} <FormattedMessage id="times" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdItem;
