import qs from "qs";

export const createQueryString = qs.stringify;

export const urlWithParams = (
  url: string,
  params: Record<string, string>
): string => {
  let result = url;
  Object.keys(params).forEach((p) => {
    result = result.replace(`:${p}`, params[p]);
  });
  return result;
};
