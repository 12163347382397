import { emailRegex, tonWalletAddressRegex } from "@/global/regex";
import useBalance from "@/hooks/use-balance";
import { useIntl } from "react-intl";
import * as Yup from "yup";

const useValidation = () => {
  const { formatMessage, formatNumber } = useIntl();
  const walletAddress = Yup.string()
    .matches(
      tonWalletAddressRegex,
      formatMessage({ id: "invalid-ton-wallet-address" })
    )
    .required(formatMessage({ id: "wallet-address-is-required" }));
  const amountValidation = Yup.number().required(
    formatMessage({ id: "amount-is-required" })
  );
  const { minWithdrawableBalance } = useBalance();
  const withdrawValidationSchema = (withdrawableAmount: number) =>
    Yup.object({
      walletAddress,
      amount: amountValidation
        .min(
          minWithdrawableBalance,
          formatMessage(
            { id: "minimum-withdrawable-amount" },
            {
              amount: formatNumber(minWithdrawableBalance, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }),
            }
          )
        )
        .max(
          +withdrawableAmount,
          formatMessage({ id: "you-do-not-have-enough-balance" })
        ),
    });
  const depositValidationSchema = (availableBalance: number) =>
    Yup.object({
      amount: amountValidation
        .moreThan(
          0,
          formatMessage({ id: "deposit-amount-must-be-greater-than-zero" })
        )
        .lessThan(
          availableBalance + +import.meta.env.VITE_TON_TRANSACTION_FEE,
          formatMessage({ id: "insufficient-balance-with-exclamation" })
        ),
    });
  const taskFormValidationSchema = Yup.object().shape({
    hasUsernameField: Yup.boolean(),
    hasEmailField: Yup.boolean(),
    username: Yup.string()
      .trim()
      .when(
        ["hasUsernameField", "hasEmailField"],
        ([hasUsernameField, hasEmailField], sch) => {
          if (hasEmailField)
            return sch
              .required(formatMessage({ id: "email-is-required" }))
              .email(formatMessage({ id: "the-email-is-invalid" }));
          else if (hasUsernameField)
            return sch
              .required(formatMessage({ id: "username-is-required" }))
              .test(
                "is-not-email",
                formatMessage({ id: "username-cannot-be-an-email-address" }),
                (value) => !emailRegex.test(value)
              );
          return sch.notRequired();
        }
      ),
  });
  return {
    withdrawValidationSchema,
    depositValidationSchema,
    taskFormValidationSchema,
  };
};

export default useValidation;
