import { type SVGProps, useRef } from "react";

const ExchangeIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <path
        d="M4.70001 6.75371H20.3M20.3 6.75371L16.7679 3.15381M20.3 6.75371L16.7679 10.3538"
        stroke="url(#paint0_linear_2297_28609)"
        strokeWidth="1.38462"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3 17.6463H4.70002M4.70002 17.6463L8.23209 14.0464M4.70002 17.6463L8.23209 21.2464"
        stroke="url(#paint1_linear_2297_28609)"
        strokeWidth="1.38462"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2297_28609"
          x1="12.5"
          y1="3.15381"
          x2="12.5"
          y2="10.3538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B2E6FF" />
          <stop offset="1" stopColor="#41C0FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2297_28609"
          x1="12.5"
          y1="14.0464"
          x2="12.5"
          y2="21.2464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B2E6FF" />
          <stop offset="1" stopColor="#41C0FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ExchangeIcon;
