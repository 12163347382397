import { ProSubmissionStatus, SubmissionStatus } from "@/global/enum";
import { ProSubmissionItemType } from "@/helpers/api/types/api-response";

export interface GroupedSubmissionData {
  status: ProSubmissionStatus;
  items: ProSubmissionItemType[];
}

const STATUSES_MAP: Partial<Record<SubmissionStatus, ProSubmissionStatus>> = {
  [SubmissionStatus.PEND]: ProSubmissionStatus.PEND,
  [SubmissionStatus.LIVE]: ProSubmissionStatus.LIVE,
  [SubmissionStatus.SUBMITTED]: ProSubmissionStatus.NOT_STARTED,
  [SubmissionStatus.WON]: ProSubmissionStatus.FINISHED,
  [SubmissionStatus.FAILED]: ProSubmissionStatus.FINISHED,
};

const sortedStatuses: ProSubmissionStatus[] = [
  ProSubmissionStatus.PEND,
  ProSubmissionStatus.LIVE,
  ProSubmissionStatus.NOT_STARTED,
  ProSubmissionStatus.FINISHED,
];

export const groupByUniqueStatus = (
  data: ProSubmissionItemType[]
): GroupedSubmissionData[] =>
  data.reduce(
    (acc, submission) => {
      const groupKey = STATUSES_MAP[submission.status];
      if (!groupKey) return acc;
      const keyIndex = sortedStatuses.findIndex(
        (status) => status === groupKey
      )!;
      (acc[keyIndex].items as any).push(submission);
      return acc as any;
    },
    sortedStatuses.map((status) => ({ status, items: [] }))
  ) as any;
