import { Reducer, useEffect, useReducer, useRef } from "react";
import useAuth from "./use-auth";
import endpoints from "@/helpers/api/endpoints";
import { useData } from "@/store/store";
import { EventNames } from "@/global/enum";

interface EventData {
  data: string;
  eventName: EventNames;
}

const backendURL = import.meta.env.VITE_API_URL as string;

const safeParse = (d: any) => {
  try {
    return JSON.parse(d);
  } catch (e) {
    return d;
  }
};

// Function to parse text/event-stream data
const parseEventStream = (eventStreamData: string): EventData[] => {
  // Split the event stream data into individual events
  const events = eventStreamData.trim().split("\n\n");

  // Map over each event and parse it into JSON
  const jsonData: EventData[] = events
    .map((event) => {
      const lines = event.split("\n");
      const dataLine = lines.find((line) => line.startsWith("data: "));
      const eventNameLine = lines.find((line) => line.startsWith("event: "));
      if (dataLine) {
        const data = safeParse(dataLine?.replace("data: ", ""));
        const eventName = eventNameLine?.replace("event: ", "");
        return { data, eventName };
      }
      return null;
    })
    .filter((event): event is EventData => event !== null);

  return jsonData;
};

export type EventsAction = Partial<EventsState>;

export type EventsState = {
  [EventNames.SubmissionUpdated]: { id?: string; count: number };
  [EventNames.TapPurchaseCreated]: string[];
  [EventNames.LeaderBoardUpdated]: number;
  [EventNames.SubmissionProFirstUnclaimed]: number;
  [EventNames.LeaderBoardFirstUnclaimed]: number;
};

export const initialEventsState: EventsState = {
  [EventNames.SubmissionUpdated]: { id: undefined, count: 0 },
  [EventNames.TapPurchaseCreated]: [],
  [EventNames.LeaderBoardUpdated]: 0,
  [EventNames.SubmissionProFirstUnclaimed]: 0,
  [EventNames.LeaderBoardFirstUnclaimed]: 0,
};

export const useSSE = () => {
  const [events, setEvents] = useReducer<Reducer<EventsState, EventsAction>>(
    (s, p) => ({ ...s, ...p }),
    initialEventsState
  );
  const eventsRef = useRef<EventsState>(events);
  eventsRef.current = events;
  const { getStoredTokens } = useAuth();
  const { setIsSessionExpired } = useData();
  useEffect(() => {
    const controller = new AbortController();
    let retryTimeout = 1000;

    const connect = async () => {
      try {
        const signal = controller.signal;
        const token = getStoredTokens().accessToken;

        const response = await fetch(`${backendURL}${endpoints.sse}`, {
          signal,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");

        while (reader) {
          const { value, done } = await reader.read();
          if (done) break;
          const events = parseEventStream(decoder.decode(value));
          events.forEach(({ data, eventName }) => {
            if (data === "close" && process.env.NODE_ENV !== "development") {
              setIsSessionExpired(true);
              if (!signal.aborted) {
                controller.abort();
              }
            }
            switch (eventName) {
              case EventNames.TapPurchaseCreated:
                return setEvents({
                  [EventNames.TapPurchaseCreated]: [
                    ...(eventsRef.current[EventNames.TapPurchaseCreated] || []),
                    data,
                  ],
                });
              case EventNames.SubmissionUpdated:
                return setEvents({
                  [EventNames.SubmissionUpdated]: {
                    id: data,
                    count:
                      eventsRef.current[EventNames.SubmissionUpdated].count + 1,
                  },
                });
              case EventNames.LeaderBoardUpdated:
                return setEvents({
                  [EventNames.LeaderBoardUpdated]:
                    eventsRef.current[EventNames.LeaderBoardUpdated] + 1,
                });
              case EventNames.SubmissionProFirstUnclaimed:
                return setEvents({
                  [EventNames.SubmissionProFirstUnclaimed]:
                    eventsRef.current[EventNames.SubmissionProFirstUnclaimed] +
                    1,
                });
              case EventNames.LeaderBoardFirstUnclaimed:
                return setEvents({
                  [EventNames.LeaderBoardFirstUnclaimed]:
                    eventsRef.current[EventNames.LeaderBoardFirstUnclaimed] +
                    1,
                });
            }
          });
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          console.error("SSE connection failed, retrying...", error);
          setTimeout(connect, retryTimeout);
          retryTimeout = Math.min(retryTimeout * 2, 30000); // Exponential backoff
        }
      }
    };

    connect();

    return () => controller.abort();
  }, []);
  return [events, setEvents] as const;
};
