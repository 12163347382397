import Page from "@/components/common/page";
import QuestContent from "@/components/pages/quest/content";
import { FC } from "react";

const QuestPage: FC = () => (
  <Page>
    <QuestContent />
  </Page>
);

export default QuestPage;
