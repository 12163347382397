import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { type FC, useMemo } from "react";
// import UAParser from "ua-parser-js";

import { App } from "@/components/App.tsx";
import { ErrorBoundary } from "@/components/ErrorBoundary.tsx";
import Maintenance from "./common/maintenance";
import { DangerIcon } from "./icons";
import Button from "./common/button";
// import BannedDesktop from "./common/banned-desktop";

const isMaintenance =
  (import.meta.env.VITE_IS_MAINTENANCE as string) === "true";

// const isDevelopment = process.env.NODE_ENV === "development";

const isStage = import.meta.env.VITE_IS_STAGE === "true";

// const parser = new UAParser(navigator.userAgent);

// const isDesktop = parser.getDevice().type === undefined;

const ErrorBoundaryError: FC<{ error: unknown }> = () => (
  <div className="flex flex-col h-screen justify-center items-center gap-4 p-10 max-w-2xl mx-auto">
    <DangerIcon />
    <p className="text-xl font-bold text-stroke-sm-blue-900 text-shadow-0-3-blue-900">
      Oops! Something Went Wrong!
    </p>
    <p className="text-sm ps-2">
      We&apos;re facing some technical issues right now. Please wait a moment
      and try again.
    </p>
    <Button color="tertiary" fullWidth onClick={() => window.location.reload()}>
      Try Again
    </Button>
  </div>
);

const Inner: FC = () => {
  const manifestUrl = useMemo(() => {
    return new URL(
      isStage ? "tonconnect-manifest.stage.json" : "tonconnect-manifest.json",
      window.location.href
    ).toString();
  }, []);

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <App />
    </TonConnectUIProvider>
  );
};

export const Root: FC = () => {
  // const { platform } = useLaunchParams();
  return (
    <ErrorBoundary fallback={ErrorBoundaryError}>
      {
        // (isDesktop || !["android", "android_x", "ios"].includes(platform)) &&
        // !isDevelopment ? (
        //   <BannedDesktop />
        // ) :
        isMaintenance ? <Maintenance /> : <Inner />
      }
    </ErrorBoundary>
  );
};
