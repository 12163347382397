import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import { TapOnboardingState } from "@/global/enum";
import { usePersistData } from "@/store/persist-store";
import { useData } from "@/store/store";
import { FC, ReactNode, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

const TITLE_MAPPING: Partial<Record<TapOnboardingState, ReactNode>> = {
  [TapOnboardingState.Step1]: <FormattedMessage id="tap-on-my-head" />,
  [TapOnboardingState.Step2]: <FormattedMessage id="tap-again" />,
};

const TapOnboarding: FC = () => {
  const { onboardTap, setOnboarding } = usePersistData();
  const { tapData } = useData();
  const initialTapAmount = useRef<number>(tapData!.amount);
  useEffect(() => {
    if (tapData!.amount - initialTapAmount.current >= 30) {
      setOnboarding({ onboardTap: TapOnboardingState.Complete });
    } else if (tapData!.amount - initialTapAmount.current >= 10) {
      setOnboarding({ onboardTap: TapOnboardingState.Step2 });
    }
  }, [tapData!.amount]);
  return (
    <div className="absolute -bottom-4 right-1/2 h-[5.25rem] w-[9rem] flex items-center justify-center p-3 pb-6">
      <Image
        src={IMAGES.THINKING_CLOUD}
        className="absolute top-0 left-0 w-full h-full object-contain"
      />
      <div className="text-center text-blue-850 font-bold text-base z-10 relative">
        {TITLE_MAPPING[onboardTap]}
      </div>
    </div>
  );
};

export default TapOnboarding;
