import Page from "@/components/common/page";
import PredictProContent from "@/components/pages/predict-pro/content";
import { FC } from "react";

const PredictProPage: FC = () => (
  <Page>
    <PredictProContent />
  </Page>
);

export default PredictProPage;
