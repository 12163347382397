import { EventsState, initialEventsState, useSSE } from "@/hooks/use-sse";
import { FC, PropsWithChildren, useState } from "react";
import Dialog from "../dialog";
import { EventNames } from "@/global/enum";
import Giveaway from "./giveaway";
import { Dispatch, createContext } from "react";

export const SSEContext = createContext<
  [EventsState, Dispatch<Partial<EventsState>>]
>([initialEventsState, () => {}]);

const SSEProvider: FC<PropsWithChildren> = ({ children }) => {
  const [events, setEvents] = useSSE();
  const [giveawayIndex, setGiveawayIndex] = useState<number>(0);
  const dialogPurchaseId = events[EventNames.TapPurchaseCreated][giveawayIndex];
  const closePurchaseDialog = () => setGiveawayIndex((prev) => prev + 1);

  return (
    <SSEContext.Provider value={[events, setEvents]}>
      <Dialog
        seedClassName="p-4 pb-3 overflow-y-visible"
        open={!!dialogPurchaseId}
        color="purple"
        withoutCloseIcon
        onClose={() => {}}
      >
        <Giveaway id={dialogPurchaseId} onClose={closePurchaseDialog} />
      </Dialog>
      {children}
    </SSEContext.Provider>
  );
};

export default SSEProvider;
