import { FC, HTMLAttributes } from "react";
import Button from "../button";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { IMAGES } from "@/constants";
import { showErrorMessage } from "@/utils/string";
import Image from "../image";

interface FetchErrorProps extends HTMLAttributes<HTMLDivElement> {
  onRetry?: () => void;
  message?: string;
}

const FetchError: FC<FetchErrorProps> = ({
  onRetry = () => {},
  className,
  message,
  children,
  ...restProps
}) => (
  <div
    className={classNames(
      "flex flex-col gap-2 justify-center items-center animate-fade-in",
      className
    )}
    {...restProps}
  >
    <Image src={IMAGES.ALERT} alt="alert" className="w-25" />
    <p className="text-sm text-center">{showErrorMessage(message)}</p>
    <Button color="secondary" onClick={onRetry} size="sm">
      <FormattedMessage id="retry" />
    </Button>
    {children}
  </div>
);

export default FetchError;
