import { FC } from "react";
import Submission from "@/components/pages/predict-pro/tabs/submission/submission";
import { ProSubmissionStatus } from "@/global/enum";
import { ProSubmissionItemType } from "@/helpers/api/types/api-response";
import { useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";
import { urlWithParams } from "@/utils/url";

interface SubmissionStatusProps {
  status: ProSubmissionStatus;
  submissions: ProSubmissionItemType[];
}

const SubmissionStatus: FC<SubmissionStatusProps> = ({
  status,
  submissions,
}) => {
  const navigate = useNavigate();
  return (
    <div className="p-4 bg-blue-950 mb-2">
      <div className="max-w-2xl mx-auto w-full">
        <p className="font-bold ms-2 capitalize mb-3">{status}</p>
        <div className="grid grid-cols-3 gap-x-2 gap-y-3">
          {submissions.map((submission) => (
            <Submission
              key={submission.id}
              {...submission}
              status={status}
              onClick={() =>
                navigate(
                  urlWithParams(paths.predictPro.submission, {
                    id: submission.id,
                  })
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubmissionStatus;
