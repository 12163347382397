import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { CACHE_KEYS } from "@/constants";
import classNames from "classnames";
import { price } from "@/utils/number";
import { TaskActionFieldType, TaskActionType, TaskStatus } from "@/global/enum";
import Dialog from "@/components/common/dialog";
import Button from "@/components/common/button";
import { openTelegramLink, openLink } from "@telegram-apps/sdk-react";
import useApi, { CheckQuestBodyType } from "@/helpers/api/hooks/use-api";
import { Field, Form, Formik } from "formik";
import formikFields from "@/components/common/formik-fields";
import toast from "react-hot-toast";
import Image from "@/components/common/image";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {
  QuestType,
  UserQuestEntityType,
} from "@/helpers/api/types/api-response";
import { useChangeCache } from "@/helpers/api/hooks/use-api-cache";
import useValidation from "@/helpers/validations";
import { showErrorMessage } from "@/utils/string";
import QuestActionButton from "./quest-action-button";

interface QuestItemProps extends QuestType {
  isQuestLoading: boolean;
  setIsQuestLoading: Dispatch<SetStateAction<boolean>>;
  categoryId: string;
  isFirst: boolean;
  isLast: boolean;
}

interface InitialValuesType {
  hasUsernameField: boolean;
  hasEmailField: boolean;
  username: string;
}

const QuestItem: FC<QuestItemProps> = ({
  logo,
  title,
  status,
  description,
  actionUrl,
  checkDescription,
  actionField,
  actionType,
  id,
  categoryId,
  isFirst,
  isLast,
  purchase,
  bundle,
  actionButton,
  isQuestLoading,
  setIsQuestLoading,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { checkQuest, startQuest, claimReward } = useApi();
  const { formatMessage } = useIntl();
  const hasUsernameField = actionField === TaskActionFieldType.Username;
  const hasEmailField = actionField === TaskActionFieldType.Email;
  const [actionButtonTitle, setActionButtonTitle] = useState(actionButton);
  const [purchaseId, setPurchaseId] = useState(purchase?.id);

  const { changeCache, cachedData } = useChangeCache<UserQuestEntityType[]>([
    CACHE_KEYS.USER_TASKS,
  ]);

  const { taskFormValidationSchema } = useValidation();

  const updateTaskStatus = (status: TaskStatus) => {
    const questCategory = cachedData!.find(
      (itm) => itm.category.id === categoryId
    )!;

    const clonedQuestCategory = { ...questCategory };

    const updatedQuests = clonedQuestCategory.quests.map((data) =>
      data.id === id ? { ...data, status } : data
    );

    changeCache(
      cachedData!.map((data) =>
        data.category.id === categoryId
          ? { category: clonedQuestCategory.category, quests: updatedQuests }
          : data
      )
    );
  };

  const start = () => {
    if (status === TaskStatus.NOT_STARTED) {
      setIsQuestLoading(true);
      startQuest(id)
        .then(() => {
          setOpenModal(true);
          updateTaskStatus(TaskStatus.STARTED);
        })
        .catch((err) => {
          toast.error(showErrorMessage(err.message), {
            position: "top-center",
            duration: 5000,
          });
        })
        .finally(() => setIsQuestLoading(false));
    } else {
      setOpenModal(true);
    }
  };

  const check = async (body?: Omit<CheckQuestBodyType, "id">) => {
    try {
      setLoading(true);
      const res = await checkQuest({ id, ...(body || {}) });
      if (res.purchase) {
        setPurchaseId(res.purchase.id);
      }
      if (actionType === TaskActionType.Redirect && res.actionUrl) {
        openLink(res.actionUrl);
      } else {
        setTimeout(() => {
          updateTaskStatus(TaskStatus.CONFIRMED);
          setActionButtonTitle("Claim");
        }, 320);
      }
      setOpenModal(false);
      return res;
    } catch (e: any) {
      toast.error(showErrorMessage(e.message), {
        position: "top-center",
        duration: 5000,
      });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const removeQuestItem = () => {
    const questCategory = cachedData!.find(
      (itm) => itm.category.id === categoryId
    )!;

    const clonedQuestCategory = { ...questCategory };

    if (clonedQuestCategory.quests.length === 1) {
      changeCache(
        cachedData!.filter((data) => data.category.id !== categoryId)
      );
    } else {
      const updatedQuests = clonedQuestCategory.quests.filter(
        (data) => data.id !== id
      );

      changeCache(
        cachedData!.map((data) =>
          data.category.id === categoryId
            ? { category: clonedQuestCategory.category, quests: updatedQuests }
            : data
        )
      );
    }
  };

  const handleClaimReward = () => {
    setLoading(true);
    claimReward(purchaseId!)
      .then(() => {
        setLoading(false);
        setOpenModal(false);
        removeQuestItem();
        toast.success("Reward claimed successfully", {
          position: "top-center",
          duration: 5000,
        });
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setLoading(false));
  };

  const onPrimaryButtonClick = () => {
    if (!actionUrl) {
      return check();
    } else if (actionUrl.startsWith("https://t.me/")) {
      return openTelegramLink(actionUrl);
    } else {
      openLink(actionUrl);
    }
  };

  const ACTION_FIELD_NAME_MAP = useMemo<Record<TaskActionFieldType, string>>(
    () => ({
      [TaskActionFieldType.Email]: formatMessage({ id: "email" }),
      [TaskActionFieldType.Username]: formatMessage({ id: "username" }),
    }),
    []
  );

  const {
    amount: reward_amount,
    asset,
    product,
  } = bundle?.bundleProducts[0] || {};

  const giveawayTitle = product?.title || asset?.title;

  const giveawayLogo = product?.logo.path || asset?.logo.path;

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        seedClassName="flex flex-col items-center justify-center"
        disableBackdropClose={loading}
        disableEscapeKeyDown={loading}
      >
        <div className="p-4">
          <div className="flex justify-center items-center gap-2">
            <Image src={logo?.path} alt="icon" className="w-10 h-10" />
            <p className="text-stroke-sm-blue-900 text-xl font-bold">{title}</p>
          </div>
          {!!reward_amount && (
            <div className="mt-4 flex justify-center items-center gap-1">
              <Image
                src={giveawayLogo}
                alt="airdrop"
                className="w-10 h-10 object-contain"
              />
              <p className="text-2xl text-shadow-0-3-green-950 text-green-300 font-bold">
                +{price(reward_amount)}
              </p>
              <p className="text-2xl text-stroke-md-blue-900 font-bold">
                {asset?.title}
              </p>
            </div>
          )}
          {status !== TaskStatus.CONFIRMED ? (
            <>
              <div
                className={classNames(
                  checkDescription ? "mt-10" : "mt-6",
                  "quest-modal-section"
                )}
              >
                <div className="bg-blue-900 rounded-xl p-4">
                  <p className="mb-4 text-sm text-start">{description}</p>
                  <Button
                    color="secondary"
                    disabled={loading}
                    fullWidth
                    onClick={onPrimaryButtonClick}
                  >
                    {!actionUrl ? (
                      <FormattedMessage id="check" />
                    ) : (
                      actionButton || <FormattedMessage id="open" />
                    )}
                  </Button>
                </div>
              </div>
              {!!checkDescription && (
                <div className="mt-3 quest-modal-section px-4 before:absolute bg-blue-900 rounded-xl p-4">
                  <p className="mb-4 text-sm text-start">{checkDescription}</p>
                  <Formik<InitialValuesType>
                    initialValues={{
                      hasUsernameField,
                      hasEmailField,
                      username: "",
                    }}
                    validationSchema={taskFormValidationSchema}
                    onSubmit={({ username }) =>
                      check(
                        username
                          ? {
                              actionField: username,
                            }
                          : undefined
                      )
                    }
                  >
                    <Form className="w-full border-blue-700">
                      {(hasUsernameField || hasEmailField) && (
                        <div className="mb-4">
                          <Field
                            component={formikFields.TextField}
                            name="username"
                            placeholder={formatMessage(
                              { id: "enter-your-value" },
                              { value: ACTION_FIELD_NAME_MAP[actionField] }
                            )}
                          />
                        </div>
                      )}
                      <Button
                        color="secondary"
                        disabled={loading}
                        fullWidth
                        type="submit"
                      >
                        <FormattedMessage id="check" />
                      </Button>
                    </Form>
                  </Formik>
                </div>
              )}
            </>
          ) : (
            <div
              className={classNames(
                checkDescription ? "mt-10" : "mt-6",
                "quest-modal-section"
              )}
            >
              <div className="bg-blue-900 rounded-xl p-4">
                <p className="mb-4 text-sm text-start">
                  <FormattedMessage id="claim-reward-description" />
                </p>
                <Button
                  color="primary"
                  disabled={loading || !purchaseId}
                  fullWidth
                  onClick={handleClaimReward}
                >
                  <FormattedMessage id="claim-reward" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <div
        className={classNames(
          "p-2 flex items-center gap-3 bg-blue-900 cursor-pointer",
          isQuestLoading && "opacity-30 animate-pulse",
          isFirst && "rounded-t-xl",
          isLast ? "rounded-b-xl" : "border-b border-b-blue-850"
        )}
        onClick={() => {
          if (!isQuestLoading) {
            status === TaskStatus.CONFIRMED ? setOpenModal(true) : start();
          }
        }}
      >
        <Image
          src={logo.path}
          className="size-11 object-contain animate-fade-in shrink-0"
          alt="icon"
        />
        <div className="grow">
          <p className="text-sm font-bold text-neutral-50">{title}</p>
          {!!reward_amount && (
            <div className="flex items-center gap-1 text-sm text-neutral-50">
              <Image
                src={giveawayLogo}
                className="w-6 h-6 object-contain"
                alt="airdrop"
              />
              <span className="text-green-300 font-bold">
                +<FormattedNumber value={reward_amount} />
              </span>
              <span>{giveawayTitle}</span>
            </div>
          )}
        </div>
        <div className="animate-grow-up">
          <QuestActionButton
            appearance={
              status === TaskStatus.CONFIRMED ? "secondary" : "primary"
            }
          >
            {status === TaskStatus.CONFIRMED ? (
              <FormattedMessage id="claim" />
            ) : (
              actionButtonTitle
            )}
          </QuestActionButton>
        </div>
      </div>
    </>
  );
};

export default QuestItem;
