import { ExclamationIcon } from "@/components/icons";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

interface NoticeProps extends HTMLAttributes<HTMLDivElement> {}

const Notice: FC<NoticeProps> = ({ className, ...restProps }) => (
  <div
    className={classNames(
      "w-6 h-6 bg-purple-400 box-shadow-0-1 shadow-purple-800 flex items-center justify-center border border-purple-200 rounded-lg",
      className
    )}
    {...restProps}
  >
    <ExclamationIcon />
  </div>
);

export default Notice;
