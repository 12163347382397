import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

interface StepCircleProps extends HTMLAttributes<HTMLDivElement> {
  showSmallBall?: boolean;
  showBigBall?: boolean;
  stepTextClassName?: string;
  stepNumberClassName?: string;
  smallBallClassName?: string;
  stepNumber: number;
}

const StepCircle: FC<StepCircleProps> = ({
  showSmallBall = false,
  showBigBall = false,
  stepTextClassName,
  stepNumberClassName,
  smallBallClassName,
  stepNumber,
  className,
  ...restProps
}) => (
  <div
    className={classNames(
      "pb-2 flex flex-col justify-center items-center box-shadow-inner-0--8 rounded-[50%] w-[7.25rem] h-[6.875rem] bg-blue-500 shadow-blue-750 relative",
      className
    )}
    {...restProps}
  >
    {showBigBall ? (
      <img src={IMAGES.BLUE_BALL} alt="blue-ball" className="w-16" />
    ) : (
      <div className="flex flex-col items-center">
        <p
          className={classNames(
            "font-bold text-blue-850 uppercase",
            stepTextClassName
          )}
        >
          <FormattedMessage id="step" />
        </p>
        <p
          className={classNames(
            "text-shadow-0-4-blue-850 text-4xl font-bold text-blue-200 text-stroke-lg-blue-850",
            stepNumberClassName
          )}
        >
          <FormattedNumber value={stepNumber} />
        </p>
      </div>
    )}

    {/* Small Ball */}
    {showSmallBall && (
      <Image
        src={IMAGES.BLUE_BALL}
        alt="blue-ball"
        className={classNames(
          "absolute w-11 h-11 bottom-6 translate-y-1/2 start-20",
          smallBallClassName
        )}
      />
    )}
  </div>
);

export default StepCircle;
