import { FC, ReactNode } from "react";
import Dialog, { DialogProps } from "@/components/common/dialog";
import Image from "@/components/common/image";
import { FormattedMessage } from "react-intl";
import Button from "../button";
import classNames from "classnames";

interface SuccessFulModalProps extends DialogProps {
  imgSrc: string;
  imageClassName: string;
  textClassName?: string,
  title: string;
  description: string;
  buttonText?: string | ReactNode;
  onSubmit?: () => void;
}

const SuccessFulModal: FC<SuccessFulModalProps> = ({
  imgSrc,
  imageClassName,
  textClassName,
  title,
  description,
  buttonText = <FormattedMessage id="ok" />,
  seedClassName,
  onSubmit,
  ...restProps
}) => {
  return (
    <Dialog
      seedClassName={classNames(
        seedClassName,
        "flex flex-col items-center p-4 pb-3 gap-6"
      )}
      {...restProps}
    >
      <Image src={imgSrc} alt={title} className={imageClassName} />
      <p className={classNames("font-bold text-xl text-shadow-0-3-blue-900 text-stroke-md-blue-900 self-start ps-2", textClassName)}>
        <FormattedMessage id={title} />
      </p>
      <p className="text-base ps-2">
        <FormattedMessage id={description} />
      </p>
      <Button color="secondary" fullWidth onClick={onSubmit}>
        {buttonText}
      </Button>
    </Dialog>
  );
};

export default SuccessFulModal;
