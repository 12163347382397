import { useData } from "@/store/store";
// import { calculateAverage } from "@/utils/array";
import classNames from "classnames";
import { FC, HTMLAttributes, useEffect, useRef } from "react";

// interface DistanceFromOrigin {
//   y: number;
//   x: number;
// }

interface TapAreaProps extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  onChangeCount?: (
    tapCount: number
    // distanceFromOrigin: DistanceFromOrigin
  ) => void;
  powerClassName?: string;
}

const isTouchableDevice =
  "ontouchstart" in window || navigator.maxTouchPoints > 0;

const mouseUpEventName = isTouchableDevice ? "touchend" : "mouseup";

const TapArea: FC<TapAreaProps> = ({
  disabled,
  className,
  children,
  onChangeCount = () => {},
  powerClassName,
  ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { tapData } = useData();
  useEffect(() => {
    const element = ref.current!;
    const {
      x,
      y,
      // width, height
    } = element.getBoundingClientRect();
    const handleClick = (e: TouchEvent | MouseEvent) => {
      e.preventDefault();
      if (!disabled) {
        const touches = "changedTouches" in e ? e.changedTouches : [e];
        // const distanceFromOrigin: { x: number[]; y: number[] } = {
        //   y: [],
        //   x: [],
        // };
        for (let i = 0; i < touches.length; i++) {
          const { clientX, clientY } = touches[i];
          // distanceFromOrigin.x.push((2 * (clientX - x)) / width - 1);
          // distanceFromOrigin.y.push((2 * (clientY - y)) / height - 1);
          const pointEl = document.createElement("div");
          pointEl.className = classNames(
            powerClassName,
            "absolute text-3xl tap-reward-animation z-10"
          );
          pointEl.style.top = `${clientY - y}px`;
          pointEl.style.insetInlineStart = `${clientX - x}px`;
          pointEl.innerText = `+${tapData!.power}`;
          element.appendChild(pointEl);
          setTimeout(() => element.removeChild(pointEl), 1500);
        }
        navigator.vibrate?.(20);
        onChangeCount(
          touches.length
          // {
          // y: calculateAverage(distanceFromOrigin.y),
          // x: calculateAverage(distanceFromOrigin.x),
          // }
        );
      }
    };
    element.addEventListener(mouseUpEventName, handleClick);
    return () => {
      element.removeEventListener(mouseUpEventName, handleClick);
    };
  }, [disabled, powerClassName, tapData!.power]);
  return (
    <div
      className={classNames(
        "relative touch-none",
        className
        // "perspective-100"
      )}
      {...restProps}
    >
      {children}
      <div ref={ref} className="absolute top-0 start-0 end-0 bottom-0 z-20" />
    </div>
  );
};

export default TapArea;
