import { useCallback, useEffect, useRef, useState } from "react";

export type EventType =
  | "onReward"
  | "onStart"
  | "onSkip"
  | "onBannerNotFound"
  | "onError";

type HandlerType = () => void;

type BannerType = "RewardedVideo" | "FullscreenMedia";

export interface ShowPromiseResult {
  done: boolean;
  description: string;
  state: "load" | "render" | "playing" | "destroy";
  error: boolean;
}

export interface AdController {
  show(): Promise<ShowPromiseResult>;
  addEventListener(event: EventType, handler: HandlerType): void;
  removeEventListener(event: EventType, handler: HandlerType): void;
  destroy(): void;
}

export interface AdsgramParams {
  blockId: string;
  onWatchCompleted: () => void;
  onError?: (result: ShowPromiseResult) => void;
}

export interface AdsgramInitParams {
  blockId: string;
  debug?: boolean;
  debugBannerType?: BannerType;
}

const useAdsgram = ({
  blockId,
  onWatchCompleted,
  onError = () => {},
}: AdsgramParams) => {
  const [loading, setLoading] = useState<boolean>(false);
  const AdControllerRef = useRef<AdController>();
  const throwError: AdsgramParams["onError"] = (e) => {
    setLoading(false);
    onError(e);
  };
  useEffect(() => {
    AdControllerRef.current = window.Adsgram?.init({
      blockId,
      debug: import.meta.env.DEV,
      debugBannerType: "FullscreenMedia",
    });
  }, [blockId]);
  return {
    loadingAd: loading,
    showAd: useCallback(async () => {
      if (AdControllerRef.current) {
        setLoading(true);
        AdControllerRef.current
          .show()
          .then(() => {
            // after user watches the ad till the end
            setLoading(false);
            onWatchCompleted();
          })
          .catch((result: ShowPromiseResult) => {
            // user gets an error during playing the ad or skips the ad
            throwError(result);
          });
      } else {
        throwError({
          error: true,
          done: false,
          state: "load",
          description: "Adsgram script not loaded",
        });
      }
    }, [throwError, onWatchCompleted]),
  };
};

export default useAdsgram;
