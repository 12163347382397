import { usePersistData } from "@/store/persist-store";
import { OptionalRefreshTokensType } from "@/store/token";
import { useLaunchParams } from "@telegram-apps/sdk-react";

const useAuth = () => {
  const { initData } = useLaunchParams();
  const userId = initData?.user?.id || "UNKNOWN";
  const { setTokens, removeTokens, getTokens } = usePersistData();
  const internalLogin = (t: OptionalRefreshTokensType) => setTokens(userId, t);
  const getStoredTokens = () => getTokens(userId);
  const internalLogout = () => {
    removeTokens(userId);
    setTimeout(() => location.reload(), 100);
  };
  return { internalLogin, getStoredTokens, internalLogout };
};

export default useAuth;
