import { AssetUnit } from "@/global/enum";
import { AssetEntityType } from "@/helpers/api/types/api-response";
import React, {
  useMemo,
  ReactNode,
  ComponentProps,
  createElement,
} from "react";
import { FormattedNumber } from "react-intl";
import NumberWithSuffix from "../number-with-suffix";

interface BundleAmountProps {
  asset?: AssetEntityType;
  rewardAmount: number;
  withSuffix?: boolean;
}

const BundleAmount: React.FC<BundleAmountProps> = ({
  asset,
  rewardAmount,
  withSuffix,
}) => {
  const bundleAmount = useMemo<ReactNode>(() => {
    const rewardProps: ComponentProps<typeof FormattedNumber> = {
      value: rewardAmount,
    };

    if (!!asset?.unit && asset.unit === AssetUnit.USD) {
      rewardProps.style = "currency";
      rewardProps.currency = "USD";
    }

    return createElement(
      withSuffix ? NumberWithSuffix : FormattedNumber,
      rewardProps
    );
  }, [asset, rewardAmount]);

  return <>{bundleAmount}</>;
};

export default BundleAmount;
