import { FC, PropsWithChildren, useEffect } from "react";
import Image from "@/components/common/image";
import { CACHE_KEYS, IMAGES } from "@/constants";
import { FormattedMessage } from "react-intl";
import ConnectButton from "../connect-button";
import useWallet from "@/hooks/use-wallet";
import Gems from "@/components/common/gems";
import Wallet from "../wallet";
import TokenItem from "./token-item";
import { useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";
import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import useApi from "@/helpers/api/hooks/use-api";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { TonBalancesResponse } from "@/helpers/api/types/api-response";
import { urlWithParams } from "@/utils/url";

const Ribbon: FC<PropsWithChildren> = ({ children }) => (
  <div className="w-full bg-blue-850 h-7 border-t border-t-neutral-950 mt-11 relative shadow-blue-750">
    <div className="box-shadow-0--2-md shadow-blue-800 bg-gradient-to-t from-blue-750 to-blue-500 absolute inset-x-4 flex justify-center py-2 -top-1/3 rounded-lg">
      {children}
    </div>
  </div>
);

const BalancesList: FC = () => {
  const { getTonBalances } = useApi();

  const navigate = useNavigate();
  const { fetch, data, error } = useApiCache<TonBalancesResponse[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getTonBalances()
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch((err) => {
            reject(err);
          })
      ),
    [CACHE_KEYS.TON_BALANCES]
  );

  useEffect(() => {
    fetch();
  }, []);

  if (error)
    return (
      <FetchError message={error.message} className="grow" onRetry={fetch} />
    );

  return (
    <div className="flex flex-col grow w-full">
      <div className="p-4">
        <Gems
          cardProps={{
            badgeClassName: "bg-blue-850 border border-blue-shade-900",
          }}
        />
      </div>
      <div className="mt-10 w-full p-4">
        <Wallet />
      </div>
      <Ribbon>
        <h3 className="font-bold text-xl text-stroke-md-blue-800 text-shadow-0-3-blue-900">
          <FormattedMessage id="your-wallet-balance" />
        </h3>
      </Ribbon>
      <div className="p-4 flex flex-col">
        <div className="mt-7 py-2 px-4 w-full bg-blue-800 text-center rounded-md">
          <p>
            <FormattedMessage id="choose-coin-to-deposit" />
          </p>
        </div>
        <div className="flex flex-col mt-6">
          <div className="flex justify-around font-bold">
            <h5>
              <FormattedMessage id="name" />
            </h5>
            <h5>
              <FormattedMessage id="balance" />
            </h5>
          </div>
          <div className="flex flex-col gap-3 mt-4">
            {!data?.length ? (
              <div className="flex py-40 items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                {data.map((token) => (
                  <TokenItem
                    key={token.id}
                    className="bg-blue-900 box-shadow-0-4 shadow-blue-950"
                    onClick={() =>
                      navigate(
                        urlWithParams(paths.wallet.depositStep2, {
                          id: token.id,
                        })
                      )
                    }
                    {...token}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DepositContent: FC = () => {
  const { isWalletConfirmed, fetchWalletInfo, error, walletStatus } =
    useWallet();

  useEffect(() => {
    fetchWalletInfo();
  }, []);

  return (
    <div className="flex-1 max-w-2xl pb-6 mx-auto w-full flex flex-col items-center">
      {error ? (
        <FetchError
          message={error.message}
          onRetry={fetchWalletInfo}
          className="grow"
        />
      ) : !walletStatus ? (
        <div className="flex grow items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {isWalletConfirmed ? (
            <BalancesList />
          ) : (
            <div className="flex flex-col grow gap-6 w-full">
              <Image
                src={IMAGES.DEPOSIT_BANNER}
                alt="deposit-banner"
                className="h-[25rem] w-full object-cover rounded-b-[2.5rem] border border-blue-300 border-t-0"
              />
              <div className="flex flex-col items-center justify-center gap-7 flex-1 text-center px-4">
                <div className="flex flex-col gap-2">
                  <h4 className="font-bold text-xl">
                    <FormattedMessage id="connect-ton-wallet" />
                  </h4>
                  <p>
                    <FormattedMessage id="connect-ton-wallet-description" />
                  </p>
                </div>
                <ConnectButton />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DepositContent;
