import { SVGProps, useRef } from "react";

const FlashIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="vuesax/linear/flash">
        <g id="vuesax/linear/flash_2">
          <g id="flash">
            <path
              id="Vector"
              d="M7.49538 10.3661V8.69234H11.3954C11.9695 8.69234 12.2404 7.97735 11.807 7.59818L7.14871 3.49776C6.74246 3.14026 6.10871 3.42734 6.10871 3.96901V5.64276H2.20871C1.63455 5.64276 1.36371 6.35777 1.79705 6.73693L6.45538 10.8373C6.86163 11.1894 7.49538 10.9023 7.49538 10.3661Z"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FlashIcon;
