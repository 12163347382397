import { FC } from "react";
import Button from "../button";
import Image from "../image";
import { IMAGES } from "@/constants";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { BundleItemType } from "@/helpers/api/types/api-response";

interface GiveawayContentProps {
  bundle: BundleItemType;
  onClaim: () => void;
  loading?: boolean;
  amountCoefficient?: number;
}

const GiveawayContent: FC<GiveawayContentProps> = ({
  bundle,
  onClaim,
  loading = false,
  amountCoefficient = 1,
}) => {
  const { bundleProducts, logo, title } = bundle;
  const description = bundleProducts?.[0]?.product?.description;
  const assetUrl = bundleProducts?.[0].asset?.logo.path;
  const bundleAmount = (
    <FormattedNumber value={amountCoefficient * bundleProducts?.[0]?.amount} />
  );
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <Image
        src={logo?.path || bundleProducts?.[0].product?.logo.path}
        alt="bundle"
        className="-mt-20 h-36"
      />
      <p className="font-bold text-xl text-shadow-0-2-purple-950 text-stroke-md-purple-900">
        {title}
      </p>
      {!!description && <p className="text-sm ">{description}</p>}
      {!!assetUrl && <Image className="w-14" src={assetUrl} alt="asset" />}
      <div className="px-4 py-2 flex justify-center items-center gap-1 rounded-lg border border-purple-800 bg-purple-700 box-shadow-inner-0-1 shadow-purple-700">
        <Image className="h-6" src={IMAGES.ARROW_TOP} alt="top arrow" />
        <p>
          {bundleProducts?.[0]?.product ? (
            <FormattedMessage
              id="count-cards"
              values={{
                count: bundleAmount,
              }}
            />
          ) : (
            <>
              {bundleAmount} {bundleProducts?.[0].asset?.title}
            </>
          )}
        </p>
      </div>
      <Button fullWidth color="secondary" onClick={onClaim} disabled={loading}>
        <FormattedMessage id="claim" />
      </Button>
    </div>
  );
};

export default GiveawayContent;
