import { SubmissionResult } from "@/global/enum";

const predictionOrder: SubmissionResult[] = [
  SubmissionResult.HOME,
  SubmissionResult.DRAW,
  SubmissionResult.AWAY,
];

export const convertPredictionToString = (
  prediction?: boolean[]
): string | undefined =>
  prediction
    ?.map((p, i) => (p ? predictionOrder[i] : undefined))
    .filter(Boolean)
    .join("_");

export const convertStringToPrediction = (
  predictionString: string
): boolean[] => {
  const predictionArray = predictionString.split("_");

  return predictionOrder.map((result) => predictionArray.includes(result));
};
