import { FC } from "react";
import SubmissionStatus from "@/components/pages/predict-pro/tabs/submission/submission-status";
import { ProSubmissionStatus } from "@/global/enum";
import { GroupedSubmissionData } from "@/utils/group-by-status";
import { FormattedMessage } from "react-intl";

interface SubmissionTabProps {
  data: GroupedSubmissionData[];
}

const SubmissionTab: FC<SubmissionTabProps> = ({ data }) => {
  return (
    <>
      {/* Note: This title is temporary and should be removed once the Tabs component is activated */}
      <div className="bg-blue-900 font-bold p-4 mb-4 text-center box-shadow-0-2 shadow-blue-900">
        <FormattedMessage id="submissions" />
      </div>
      {data
        .filter(({ items }) => items.length)
        .map(({ status, items }) => (
          <SubmissionStatus
            key={status}
            status={status as ProSubmissionStatus}
            submissions={items}
          />
        ))}
    </>
  );
};

export default SubmissionTab;
