import { FC, useState } from "react";
import Dialog, { DialogProps } from "@/components/common/dialog";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Button from "@/components/common/button";
import Image from "@/components/common/image";
import useApi from "@/helpers/api/hooks/use-api";
import { useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";
import toast from "react-hot-toast";
import { StepResponseType } from "@/helpers/api/types/api-response";
import StepCircle from "../step-circle";
import { showErrorMessage } from "@/utils/string";

interface PredictModalProps extends DialogProps {
  step: StepResponseType;
  onClose: () => void;
  isNeedOnboarding?: boolean;
}

const PredictModal: FC<PredictModalProps> = ({
  open,
  onClose,
  step,
  isNeedOnboarding,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { createSubmissionStep } = useApi();
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      seedClassName="pb-4 overflow-y-visible"
      {...props}
    >
      <div className="flex flex-col justify-center gap-4 px-4">
        <StepCircle
          showSmallBall
          stepNumber={step.displayOrder}
          className="mx-auto -mt-14"
        />
        <p className="text-xl font-bold text-stroke-md-blue-900 text-shadow-0-3-blue-900 text-blue-50 self-center">
          <FormattedMessage id="are-you-ready" />
        </p>

        <ul className="list-decimal list-outside ps-4 flex flex-col text-start gap-4">
          <li>
            <FormattedMessage
              id="predict-matches"
              values={{ number: step?.matchCounts }}
            />
          </li>
          <li>
            <FormattedMessage id="predict-correctly" />
          </li>
        </ul>

        <div className="rounded-lg font-bold w-full">
          <div className="rounded-t-lg border-x border-t border-blue-900 bg-blue-850 box-shadow-inner-0-1-md shadow-purple-950 flex justify-center items-center gap-2 p-2">
            <p className="text-stroke-md-blue-850 text-shadow-0-1-blue-900">
              <FormattedMessage id="match-number" />
            </p>
            <p>{step?.matchCounts}</p>
          </div>
          <div className="rounded-b-lg border-x border-b border-blue-600 bg-blue-500 box-shadow-inner-0-1-md shadow-blue-750 flex justify-center items-center gap-2 text-stroke-md-blue-850 text-shadow-0-1-purple-950 py-2">
            <p>
              <FormattedMessage id="predict-again" />
            </p>
            <p className="flex justify-center items-center gap-1">
              <span>
                <FormattedNumber value={step?.nextCost} />{" "}
              </span>
              <span>{step?.nextCostAsset?.unit}</span>
              <Image
                src={step?.nextCostAsset?.logo?.path}
                className="h-[1.125rem] w-[1.125rem]"
                alt="coin"
              />
            </p>
          </div>
        </div>

        <Button
          color="secondary"
          fullWidth
          onClick={() => {
            setLoading(true);
            createSubmissionStep()
              .then(() =>
                navigate(
                  isNeedOnboarding
                    ? paths.journeys.matches
                    : paths.journeys.matches,
                  {
                    state: isNeedOnboarding
                      ? { onboarding: "true" }
                      : undefined,
                  }
                )
              )
              .catch((err) =>
                toast.error(showErrorMessage(err.message), {
                  position: "top-center",
                  duration: 5000,
                })
              )
              .finally(() => setLoading(false));
          }}
          disabled={loading}
        >
          <FormattedMessage id="start" />
        </Button>
      </div>
    </Dialog>
  );
};

export default PredictModal;
