import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import { FC, ReactElement, useEffect } from "react";
import ConnectButton from "./connect-button";
import { FormattedMessage } from "react-intl";
import useWallet from "@/hooks/use-wallet";
import Wallet from "./wallet";
import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import { ExcahngeIcon, TonIcon } from "@/components/icons";

const WalletBox: FC<{ icon: ReactElement; title: string }> = ({
  icon,
  title,
}) => {
  return (
    <div className="size-24 justify-center rounded-3xl bg-gradient-to-b flex flex-col gap-2 items-center text-stroke-sm-blue-850 font-bold from-blue-700 to-blue-850 border border-blue-200">
      {icon}
      <h4>
        <FormattedMessage id={title} />
      </h4>
    </div>
  );
};

const Airdrop = () => {
  const { isWalletConfirmed, fetchWalletInfo, error, walletStatus } =
    useWallet();
  useEffect(() => {
    fetchWalletInfo();
  }, []);
  return (
    <div className="mt-20 flex flex-col justify-between flex-1">
      {error ? (
        <FetchError
          message={error.message}
          onRetry={fetchWalletInfo}
          className="grow"
        />
      ) : !walletStatus ? (
        <div className="grow flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex justify-center">
              <Image src={IMAGES.AIRDROP} className="mt-6 w-60" />
            </div>
            <div className="flex self-center items-center gap-4 mt-8 animate-from-top">
              <WalletBox
                icon={
                  <Image
                    src={IMAGES.SIMPLE_WALLET}
                    alt={IMAGES.SIMPLE_WALLET}
                    className="w-8"
                  />
                }
                title="Predipie"
              />
              <ExcahngeIcon />
              <WalletBox icon={<TonIcon />} title="TON" />
            </div>
            <div className="flex items-center flex-col text-center mt-6 gap-4 animate-fade-in">
              <h6 className="font-bold">
                <FormattedMessage id="connect-wallet-title" />
              </h6>
              <p>
                <FormattedMessage id="connect-wallet-description" />
              </p>
            </div>
          </div>
          <div className="mx-4 mt-6">
            {isWalletConfirmed ? <Wallet /> : <ConnectButton />}
          </div>
        </>
      )}
    </div>
  );
};

export default Airdrop;
