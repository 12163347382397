import { CACHE_KEYS } from "@/constants";
import useApi from "@/helpers/api/hooks/use-api";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { InventoryEntityType } from "@/helpers/api/types/api-response";

const useInventory = () => {
  const { getUserInventory } = useApi();
  const inventory = useApiCache<InventoryEntityType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getUserInventory()
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch(reject)
      ),
    [CACHE_KEYS.INVENTORIES]
  );

  const { changeCache, hash, data } = inventory;

  const updateInventory = (productId: string, increment?: boolean) => {
    const count = increment ? 1 : -1;
    changeCache(
      hash,
      data!.map((p) =>
        productId === p.product.id ? { ...p, amount: p.amount + count } : p
      )
    );
  };

  return { updateInventory, ...inventory };
};

export default useInventory;
