import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import Tab, { TabItem } from "@/components/common/tab";
import Transfer from "./transfer";
import Airdrop from "./airdrop";

type TabValues = "transfer" | "airdrop";

const tabs: TabItem<TabValues>[] = [
  {
    value: "transfer",
    title: <FormattedMessage id="transfer" />,
    content: <Transfer />,
  },
  {
    value: "airdrop",
    title: <FormattedMessage id="airdrop" />,
    content: <Airdrop />,
  },
];

const WalletContent: FC = () => {
  const [tab, setTab] = useState<TabValues>(tabs[0].value);

  return (
    <div className="flex-1 flex flex-col w-full max-w-2xl mx-auto pb-6">
      <Tab<TabValues>
        tabs={tabs}
        fixed
        wrapperClassName="px-4 pt-4 max-w-2xl mx-auto absolute"
        value={tab}
        onValueChange={setTab}
      />
    </div>
  );
};

export default WalletContent;
