import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import Image, { ImageProps } from "../image";

interface MaskedImageProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ImageProps, "src" | "alt"> {
  innerClassName?: string;
}

const MaskedImage: FC<MaskedImageProps> = ({
  className,
  children,
  src,
  alt,
  innerClassName,
  ...restProps
}) => (
  <div
    className={classNames(
      "relative overflow-hidden pb-6 animate-fade-in",
      className
    )}
    {...restProps}
  >
    {children && (
      <div
        className={classNames(
          "max-w-2xl relative z-20 mx-auto p-4",
          innerClassName
        )}
      >
        {children}
      </div>
    )}
    <div className="absolute -start-[9.375rem] -end-[9.375rem] bottom-6 top-0 rounded-b-[50%] box-shadow-0-24 shadow-neutral-0/[0.1] bg-blue-850 overflow-hidden">
      <div className="absolute top-0 bottom-0 start-[9.375rem] end-[9.375rem]">
        <Image src={src} alt={alt} className="w-full h-full object-cover" />
      </div>
    </div>
  </div>
);

export default MaskedImage;
