import classNames from "classnames";
import {
  forwardRef,
  useRef,
  type InputHTMLAttributes,
  type ReactNode,
} from "react";
import { disableAutoCompleteInputAttrs } from "@/constants";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  label?: string;
  containerClassName?: string;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  helperText?: string;
  disableAutoComplete?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  icon?: ReactNode;
  onCustomChange?: (v: string) => void;
  labelClassName?: string;
  helperTextClassName?: string;
}

export const labelTextClassNames = {
  error: "!text-red-600",
  success: "!text-green-400",
  warning: "!text-warning-1",
};

export const inputStageClassNames = {
  error: "border !border-red-600",
  success: "!border-green-400",
  warning: "!border-warning-1",
};

export const getLabelColorClass = ({
  error,
  warning,
  success,
}: {
  error?: boolean;
  warning?: boolean;
  success?: boolean;
}) =>
  error
    ? labelTextClassNames["error"]
    : success
    ? labelTextClassNames["success"]
    : warning
    ? labelTextClassNames["warning"]
    : "";

export const getInputColorClass = ({
  error,
  warning,
  success,
}: {
  error?: boolean;
  warning?: boolean;
  success?: boolean;
}) =>
  error
    ? inputStageClassNames.error
    : success
    ? inputStageClassNames.success
    : warning
    ? inputStageClassNames.warning
    : "";

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      icon,
      warning,
      containerClassName,
      fullWidth,
      error,
      success,
      label,
      helperText,
      disableAutoComplete = false,
      startAdornment,
      endAdornment,
      value,
      labelClassName,
      helperTextClassName,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const beforeIcon = startAdornment || icon;
    return (
      <div
        className={classNames(
          "form-control",
          { "w-full": fullWidth },
          containerClassName
        )}
      >
        {label && (
          <label className="label">
            <span className={classNames("label-text ps-2", labelClassName)}>
              {label}
            </span>
          </label>
        )}
        <div className="relative flex items-center">
          {beforeIcon && (
            <span className="absolute start-3 ">{beforeIcon}</span>
          )}
          <input
            ref={(node) => {
              inputRef.current = node;
              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }}
            type="text"
            placeholder="Type here"
            className={classNames(
              "w-full placeholder-neutral-50/[0.3] text-base block py-4 outline-0 px-2 bg-blue-850 rounded-lg",
              getInputColorClass({ error, success, warning }),
              {
                "!ps-9": icon || startAdornment,
                "!pe-9": endAdornment,
                "opacity-70 pointer-events-none !cursor-not-allowed": disabled,
              },
              className
            )}
            value={value}
            disabled={disabled}
            {...(disableAutoComplete ? disableAutoCompleteInputAttrs : {})}
            {...restProps}
          />
          {endAdornment && (
            <span className="absolute end-3">{endAdornment}</span>
          )}
        </div>
        {helperText && (
          <label className="label pt-1 inline-block">
            <span
              className={classNames(
                "label-text text-blue-200 text-sm font-normal ps-2",
                getLabelColorClass({ error, success, warning }),
                helperTextClassName
              )}
            >
              {helperText}
            </span>
          </label>
        )}
      </div>
    );
  }
);

export default TextInput;
