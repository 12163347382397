import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import { InventoryEntityType } from "@/helpers/api/types/api-response";
import { FC, useEffect } from "react";
import DescriptionCard from "./description-card";
import { FormattedMessage } from "react-intl";
import Stand from "./stand";
import { chunkArray } from "@/utils/array";
import InventoryItem from "./item";
import useInventory from "@/hooks/use-inventory";

const InventoryTab: FC = () => {
  const { fetch, error, data } = useInventory();

  useEffect(() => {
    fetch();
  }, []);

  if (error)
    return (
      <FetchError className="grow" onRetry={fetch} message={error.message} />
    );
  else if (data) {
    const dataCards = data.filter(({ product }) => !product.functionality);
    const toolCards = data.filter(({ product }) => !!product.functionality);
    return (
      <div className="grow max-w-2xl w-full mx-auto px-4">
        <DescriptionCard
          className="mt-2"
          title={<FormattedMessage id="data-cards" />}
          description={
            <FormattedMessage id="inventories-data-cards-description" />
          }
        />
        <div className="mt-2">
          {chunkArray<InventoryEntityType>(dataCards, 4).map((stand, index) => (
            <Stand
              key={`data-card-${index}`}
              className="flex justify-center pb-4 px-4 gap-2 mt-6"
            >
              {stand.map((d) => (
                <InventoryItem
                  key={d.id}
                  data={d}
                  cardClassName="aspect-[14/17]"
                />
              ))}
            </Stand>
          ))}
        </div>
        <DescriptionCard
          className="mt-6"
          title={<FormattedMessage id="tool-cards" />}
          description={
            <FormattedMessage id="inventories-tool-cards-description" />
          }
        />
        <div className="mt-2">
          {chunkArray<InventoryEntityType>(toolCards, 3).map((stand, index) => (
            <Stand
              key={`tool-card-${index}`}
              className="flex justify-center pb-4 px-5 gap-3 mt-6"
            >
              {stand.map((d) => (
                <InventoryItem
                  key={d.id}
                  data={d}
                  cardClassName="aspect-[31/40]"
                />
              ))}
            </Stand>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="grow flex items-center justify-center">
      <Spinner />
    </div>
  );
};

export default InventoryTab;
