import { type SVGProps, useRef } from "react";

const ImportIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1.625rem"
      height="1.625rem"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <path
        d="M9.81189 11.8791L12.3796 14.4468L14.9473 11.8791"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3796 4.17593V14.3766"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5241 12.3806C20.5241 16.8139 17.5151 20.4047 12.5 20.4047C7.48499 20.4047 4.47595 16.8139 4.47595 12.3806"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImportIcon;
