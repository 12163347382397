import { FC, HTMLAttributes, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Image from "@/components/common/image";
import { StepResponseType } from "@/helpers/api/types/api-response";
import { CurrentStepStatus } from "@/global/enum";
import useCurrentStep from "./context/use-current-step";
import useStepUI from "./use-step-ui";
import PredictModal from "./modal/predict-modal";
import { useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";
import StepCircle from "./step-circle";
import CountDown from "@/components/common/count-down";
import { FormattedMessage } from "react-intl";
import { urlWithParams } from "@/utils/url";
import { usePersistData } from "@/store/persist-store";
import PrediBoyDialog from "@/components/common/predi-boy-dialog";
import useDebounce from "@/hooks/use-debounce";

interface StepProps {
  step: StepResponseType;
}

const STEP_OVERLAP_TIMEOUT = 300;

const Step: FC<StepProps & HTMLAttributes<HTMLDivElement>> = ({
  step,
  className,
  ...props
}) => {
  const {
    border,
    isCurrentStep,
    tooltipBg,
    tooltipTitle,
    tooltipIcon,
    isLocked,
  } = useStepUI(step);
  const { currentStep } = useCurrentStep();

  const { isGamePlayOnboardingDone } = usePersistData();

  const stepRef = useRef<HTMLDivElement>(null);

  const getCurrentStatus = (status: CurrentStepStatus) =>
    isCurrentStep && currentStep.currentStatus === status;

  const isNeedOnboarding =
    step.displayOrder === 1 &&
    getCurrentStatus(CurrentStepStatus.START) &&
    !isGamePlayOnboardingDone;

  const [onboardingMode, setOnboardingMode] =
    useState<boolean>(isNeedOnboarding);

  const isStepOverDialogs = useDebounce(onboardingMode, STEP_OVERLAP_TIMEOUT);

  useEffect(() => {
    if (isCurrentStep && stepRef.current) {
      setTimeout(
        () =>
          stepRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          }),
        100
      );
    }
  }, [isCurrentStep]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleStepClick = () => {
    if (isLocked) return;
    if (getCurrentStatus(CurrentStepStatus.START)) {
      setOnboardingMode(false);
      return setTimeout(
        () => setShowModal(true),
        onboardingMode ? STEP_OVERLAP_TIMEOUT : 0
      );
    }
    navigate(
      getCurrentStatus(CurrentStepStatus.WAITING)
        ? paths.journeys.matches
        : urlWithParams(paths.journeys.submission, { id: step.id })
    );
  };

  return (
    <>
      <PrediBoyDialog
        open={onboardingMode}
        onClose={() => setOnboardingMode(false)}
        description={
          <FormattedMessage
            id="journeys-onboarding-description"
            values={{
              pink: (chunks) => <span className="text-pink-500">{chunks}</span>,
            }}
          />
        }
        cloudClassName="w-[11rem]"
      />
      <div
        className={classNames(
          "relative pb-0.5",
          className,
          isStepOverDialogs && "z-[2100]"
        )}
        {...props}
        onClick={handleStepClick}
        ref={stepRef}
      >
        {border}

        {/* Tooltip */}
        <div
          className={classNames(
            "bg-contain bg-center bg-no-repeat w-[5.34rem] h-8 flex justify-center items-center gap-1 pb-2 absolute -top-[3.25rem] left-1/2 -translate-x-1/2",
            {
              "!w-25 !h-16 -top-[5rem] flex-col !gap-0 !justify-start pt-0.5":
                getCurrentStatus(CurrentStepStatus.SUBMITTED),
            }
          )}
          style={{
            backgroundImage: `url(${tooltipBg})`,
          }}
        >
          <p
            className={classNames(
              "mt-0.5 text-sm font-bold",
              getCurrentStatus(CurrentStepStatus.PEND) && "!text-blue-900"
            )}
          >
            <FormattedMessage id={tooltipTitle} />
          </p>
          {getCurrentStatus(CurrentStepStatus.SUBMITTED) && (
            <CountDown
              showHours
              expireAt={currentStep.startTimeStamp!}
              className="bg-yellow-800 rounded px-2 pt-1 text-xs font-bold min-w-20 text-center"
            />
          )}
          {!!tooltipIcon && (
            <Image src={tooltipIcon} alt="icon" className="h-4" />
          )}
        </div>

        <StepCircle
          showSmallBall={
            ![CurrentStepStatus.START, CurrentStepStatus.WAITING].some(
              getCurrentStatus
            ) && !isLocked
          }
          showBigBall={[
            CurrentStepStatus.START,
            CurrentStepStatus.WAITING,
          ].some(getCurrentStatus)}
          stepNumber={step.displayOrder}
          className={classNames({
            "!bg-blue-850 shadow-blue-900": isLocked,
            "!bg-blue-300 shadow-blue-500": getCurrentStatus(
              CurrentStepStatus.PEND
            ),
          })}
          stepTextClassName={classNames(isLocked && "!text-blue-650")}
          stepNumberClassName={classNames(isLocked && "!text-blue-750")}
        />
      </div>

      {getCurrentStatus(CurrentStepStatus.START) && (
        <PredictModal
          isNeedOnboarding={isNeedOnboarding}
          open={showModal}
          step={step}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Step;
