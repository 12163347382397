import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

const NotFound: FC = () => (
  <div className="py-10 flex grow flex-col items-center justify-center gap-4">
    <Image
      src={IMAGES.NO_TRANSACTIONS}
      alt="no transactions"
      className="w-25 h-28"
    />
    <p className="text-sm">
      <FormattedMessage id="no-transactions" />
    </p>
  </div>
);

export default NotFound;
