import type { ComponentProps, FC } from "react";
import { type FieldProps, getIn } from "formik";
import TextInput from "../inputs/text-input";

const TextField: FC<
  {
    field: FieldProps["field"];
    form: FieldProps["form"];
  } & ComponentProps<typeof TextInput>
> = ({ form, field, ...restProps }) => {
  const hasError =
    Boolean(
      getIn(form.errors, field.name) && getIn(form.touched, field.name),
    ) && !restProps.disabled;

  return (
    <TextInput
      fullWidth
      error={hasError}
      helperText={hasError && getIn(form.errors, field.name)}
      {...field}
      {...restProps}
    />
  );
};

export default TextField;
