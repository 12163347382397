import { IMAGES } from "@/constants";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import Image from "../image";

const SessionExpired: FC = () => (
  <div
    className="h-stable-screen grow bg-center bg-cover bg-no-repeat flex flex-col items-center justify-start relative"
    style={{ backgroundImage: `url("${IMAGES.SESSION_BANNER}")` }}
  >
    <Image src={IMAGES.PREDIPIE} alt="predipie" className="mt-10 mb-20 w-32" />
    <div
      className="w-[15rem] h-[7.75rem] pt-6 bg-contain bg-center bg-no-repeat px-8 flex flex-col text-center items-center gap-1 mx-auto mb-28"
      style={{ backgroundImage: `url("${IMAGES.THINKING_CLOUD}")` }}
    >
      <div className="text-blue-850 font-bold text-base leading-5">
        <FormattedMessage id="session-intro" />
      </div>
    </div>

    <div className="relative z-20">
      <div className="p-4 border border-neutral-0 rounded-2xl bg-blue-850 text-sm mx-10 z-10 relative">
        <FormattedMessage
          id="access-only-one-device"
          values={{
            primary: (chunks) => (
              <span className="text-blue-300">{chunks}</span>
            ),
            br: <div className="mb-4" />,
          }}
        />
      </div>
      <Image
        src={IMAGES.LAUGHING_PREDI}
        alt="laughing predi"
        className="w-28 h-36 absolute right-14 -top-28 z-[-10]"
      />
    </div>
  </div>
);

export default SessionExpired;
