import type { ButtonSizeType, ButtonType } from "@/global/types";
import classNames from "classnames";
import type { FC, HTMLAttributes } from "react";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: ButtonType;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: ButtonSizeType;
  type?: "submit" | "reset" | "button";
  innerClassName?: string;
}

const normalBtnSizeMap: Record<ButtonSizeType, string> = {
  sm: "text-sm box-shadow-0-4 pt-3 pb-2 mb-1",
  md: "text-base box-shadow-0-6 pt-4 pb-2.5 mb-1.5",
  lg: "text-lg box-shadow-0-8 pt-4 pb-2 mb-2",
};

const btnTypeMap: Record<
  ButtonType,
  { btnClassName: string; sizeClassName: Record<ButtonSizeType, string> }
> = {
  primary: {
    btnClassName:
      "bg-gradient-to-b from-yellow-400 via-yellow-600 via-[52%] to-yellow-600 text-yellow-950 border border-yellow-200 shadow-yellow-800 px-8",
    sizeClassName: normalBtnSizeMap,
  },
  tertiary: {
    btnClassName:
      "bg-gradient-to-b from-blue-400 to-blue-600 text-blue-900 border-2 border-blue-300 !box-shadow-0-6 !shadow-blue-750 px-8",
    sizeClassName: normalBtnSizeMap,
  },
  secondary: {
    btnClassName:
      "bg-gradient-to-b from-green-100 via-33% via-green-300 to-green-500 text-green-900 border border-green-300 shadow-green-800 px-8",
    sizeClassName: normalBtnSizeMap,
  },
  error: {
    btnClassName:
      "bg-gradient-to-b from-red-500 to-red-700 text-red-950 border border-red-300 shadow-red-900 px-8",
    sizeClassName: normalBtnSizeMap,
  },
  white: {
    btnClassName:
      "bg-neutral-50 text-blue-750 border border-blue-850 box-shadow-0-2 shadow-blue-850 !rounded-lg leading-4",
    sizeClassName: {
      sm: "text-base p-2",
      md: "text-lg p-3",
      lg: "text-lg p-4",
    },
  },
};

const Button: FC<ButtonProps> = ({
  className,
  color = "secondary",
  children,
  fullWidth = false,
  disabled = false,
  type,
  size = "md",
  innerClassName,
  ...restProps
}) => {
  const { btnClassName, sizeClassName } = btnTypeMap[color];
  return (
    <button
      className={classNames(
        className,
        sizeClassName[size],
        btnClassName,
        "btn rounded-xl cursor-pointer transition-transform active:translate-y-0.5 shadow-blue-50/50",
        { "w-full block": fullWidth }
      )}
      disabled={disabled}
      type={type}
      {...restProps}
    >
      <div
        className={classNames(
          disabled && "opacity-50",
          "flex items-center justify-center",
          innerClassName,
          color !== "white" && "font-bold"
        )}
      >
        {children}
      </div>
    </button>
  );
};

export default Button;
