import Button from "@/components/common/button";
import Dialog from "@/components/common/dialog";
import Image from "@/components/common/image";
import { FilledTriangleIcon } from "@/components/icons";
import { CACHE_KEYS, IMAGES } from "@/constants";
import { BundleItemType, JourneyType } from "@/helpers/api/types/api-response";
import { AssetUnit, CurrentStepStatus } from "@/global/enum";
import { ComponentProps, FC, ReactNode, useMemo, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import useApi from "@/helpers/api/hooks/use-api";
import useCurrentStep from "./context/use-current-step";
import toast from "react-hot-toast";
import { useChangeCache } from "@/helpers/api/hooks/use-api-cache";
import classNames from "classnames";
import { showErrorMessage } from "@/utils/string";

interface LevelRewardProps {
  bundle: BundleItemType;
  showReward?: boolean;
  isRewardClaimable?: boolean;
  isRewardClaimed?: boolean;
}

const LevelReward: FC<LevelRewardProps> = ({
  bundle,
  showReward = false,
  isRewardClaimable,
  isRewardClaimed,
}) => {
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const { claimReward } = useApi();
  const { amount, asset } = bundle.bundleProducts[0] || {};
  const { currentStep, setCurrentStep, activeStepNumber } = useCurrentStep();

  const { cachedData } = useChangeCache<JourneyType>([CACHE_KEYS.JOURNEYS]);

  const claimLevelReward = () => {
    claimReward(currentStep.purchaseId!)
      .then(() => {
        setShowRewardModal(true);
        setCurrentStep({
          ...currentStep,
          purchaseId: undefined,
          currentStatus: CurrentStepStatus.START,
          currentStepId:
            cachedData!.levels
              .flatMap(({ steps }) => steps)
              .find(({ displayOrder }) => displayOrder === activeStepNumber + 1)
              ?.id || currentStep.currentStepId,
        });
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      );
  };

  const closeModal = () => setShowRewardModal(false);

  const bundleAmount = useMemo<ReactNode>(() => {
    const rewardProps: ComponentProps<typeof FormattedNumber> = {
      value: amount,
    };
    if (asset?.unit === AssetUnit.USD) {
      rewardProps.style = "currency";
      rewardProps.currency = "USD";
    }
    return <FormattedNumber {...rewardProps} />;
  }, [asset, amount]);

  return (
    <>
      <div className="flex flex-col items-center gap-1 mt-6 mb-20 px-8">
        <div
          className="flex flex-col gap-2 p-2 pb-9 bg-no-repeat bg-center bg-[length:100%_100%] w-full"
          style={{
            backgroundImage: `url("${IMAGES.REWARD_BG}")`,
          }}
        >
          <div className="aspect-[262/128]">
            <Image
              src={bundle.logo.path}
              alt="reward"
              className="relative rounded-xl h-full object-cover"
            />
          </div>
          {showReward && (
            <div className="absolute -translate-x-1/2 left-1/2 mt-2">
              <Image
                src={IMAGES.REWARD_AMOUNT_BG}
                alt="reward box"
                className="mb-4 w-24 h-9"
              />
              <div className="absolute -translate-x-1/2 left-1/2 top-0 flex justify-center items-center font-bold text-stroke-sm-yellow-950 mt-0.5 gap-1 text-yellow-50">
                <p>{bundleAmount}</p>
                {asset && asset.unit !== AssetUnit.USD && (
                  <p className="capitalize">
                    {amount > 1 ? asset.units : asset.unit}
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="w-full max-w-2xl mx-auto">
            <Button
              color="primary"
              fullWidth
              onClick={claimLevelReward}
              disabled={!isRewardClaimable || isRewardClaimed}
              className="flex justify-center items-center"
              innerClassName={classNames({
                "!opacity-100": isRewardClaimed,
              })}
            >
              <FormattedMessage id={isRewardClaimed ? "claimed" : "claim"} />
              {isRewardClaimed && (
                <Image
                  src={IMAGES.CHECK_ICON}
                  alt="check"
                  className="w-6 h-6 ml-2"
                />
              )}
            </Button>
          </div>
        </div>
        <div className="w-full aspect-[278/64]">
          <Image src={IMAGES.PITCH} alt="pitch" className="w-full h-full" />
        </div>
      </div>
      <Dialog
        open={showRewardModal}
        onClose={closeModal}
        seedClassName="px-4 py-6"
      >
        <Image
          src={bundle.logo.path}
          alt="reward-box"
          className="mb-4 h-40 mx-auto w-full max-w-80 rounded-xl"
        />
        <div className="flex flex-col justify-center items-center gap-6">
          <div className="flex justify-center items-center gap-1 px-2 py-1 bg-yellow-600 rounded-lg border border-yellow-800 box-shadow-inner-0-1-md shadow-yellow-950">
            <FilledTriangleIcon className="text-yellow-400" />
            <p className="font-bold text-stroke-sm-yellow-900 text-shadow-0-1-yellow-950 flex justify-center items-center gap-0.5">
              <span>+</span>
              {bundleAmount}
              {asset?.unit !== AssetUnit.USD && (
                <span className="capitalize block">
                  {amount > 1 ? asset?.units : asset?.unit}
                </span>
              )}
            </p>
          </div>
          <div>
            <h1 className="text-xl text-center font-bold text-stroke-md-blue-900 text-shadow-0-3-blue-900">
              <FormattedMessage id="reward-claimed" />
            </h1>
            <p className="text-blue-50">
              <FormattedMessage id="reward-claimed-desc" />
            </p>
          </div>
          <Button color="secondary" fullWidth onClick={closeModal}>
            <FormattedMessage id="ok" />
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default LevelReward;
