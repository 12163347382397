import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import Spinner from "../spinner";
import { useIntersectionObserver } from "@/hooks/use-intersection-observer";

export interface InfiniteScrollProps {
  loadFunction: (v: { page: number }) => Promise<void>;
  hasMore: boolean;
  pageStart?: number;
  customLoading?: ReactNode;
}

const InfiniteScroll: FC<PropsWithChildren<InfiniteScrollProps>> = ({
  loadFunction,
  hasMore,
  pageStart = 1,
  customLoading,
  children,
}) => {
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const isObserved = useRef<boolean>(false);
  const page = useRef<number>(pageStart);

  const entry = useIntersectionObserver(loaderRef, {});

  useEffect(() => {
    if (entry?.isIntersecting && !isObserved.current) {
      isObserved.current = true;
      loadFunction({ page: page.current })
        .then(() => {
          page.current++;
        })
        .finally(() => {
          isObserved.current = false;
        });
    }
  }, [entry?.isIntersecting]);

  return (
    <>
      {children}
      {hasMore && (
        <div ref={loaderRef}>
          {customLoading || (
            <div className="flex items-center justify-center py-10">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InfiniteScroll;
