import { FilledArrowRightIcon } from "@/components/icons";
import classNames from "classnames";
import { FC } from "react";
import Image from "@/components/common/image";
import { TonBalancesResponse } from "@/helpers/api/types/api-response";
import FormattedBalance from "./formatted-balance";

interface TokenItemProps extends TonBalancesResponse {
  onClick?(): void;
  className?: string;
}

const TokenItem: FC<TokenItemProps> = ({
  prices,
  metadata,
  className,
  balance,
  onClick,
}) => {
  const { USD = 0 } = prices || {};
  const { image, name } = metadata;

  return (
    <div
      role={onClick ? "button" : ""}
      onClick={onClick}
      className={classNames(
        "flex px-2 pt-3 pb-3 rounded-lg gap-2 items-center justify-between",
        className
      )}
    >
      <Image
        src={image}
        className="size-12 rounded-lg object-cover animate-fade-in shrink-0"
      />

      <div className="flex grow">
        <div className="flex flex-col items-start basis-1/2 text-start">
          <h4 className="line-clamp-1">{name}</h4>
          <p className="text-blue-300">
            <FormattedBalance style="USD" balance={USD} />
          </p>
        </div>
        <div className="basis-1/2 flex flex-col items-center justify-center">
          <h4>
            <FormattedBalance style="JETTON" balance={balance} />
          </h4>
          <p className="text-blue-300">
            <FormattedBalance style="USD" balance={balance * USD} />
          </p>
        </div>
      </div>
      {onClick && <FilledArrowRightIcon />}
    </div>
  );
};

export default TokenItem;
