import { type SVGProps, useRef } from "react";

const InCompleteCircleIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.5rem"
      height="8.25rem"
      viewBox="0 0 136 132"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M134.04 64.5C135.122 64.5 136.003 63.6217 135.968 62.5396C135.449 46.5805 128.606 31.3519 116.742 19.8734C104.393 7.92578 87.5782 0.98392 69.8749 0.524332C52.1716 0.0647455 34.9763 6.1237 21.9462 17.4125C8.91619 28.7013 1.07964 44.3291 0.103393 60.9721C-0.872858 77.615 5.08821 93.9598 16.7187 106.53C28.3492 119.1 44.7314 126.904 62.3809 128.281C80.0304 129.658 97.5545 124.501 111.226 113.905C124.354 103.731 132.935 89.3123 135.322 73.5128C135.486 72.433 134.699 71.4526 133.612 71.3394C132.546 71.2282 131.593 71.9954 131.431 73.0549C129.169 87.9192 121.088 101.482 108.734 111.057C95.8504 121.041 79.3367 125.902 62.7049 124.604C46.0731 123.306 30.6354 115.952 19.6755 104.107C8.71564 92.2612 3.09828 76.8588 4.01824 61.1755C4.9382 45.4921 12.3229 30.7654 24.6016 20.1275C36.8804 9.48962 53.0843 3.78002 69.7668 4.2131C86.4494 4.64619 102.294 11.1878 113.931 22.4465C125.083 33.2361 131.528 47.5419 132.045 62.5396C132.083 63.6216 132.957 64.5 134.04 64.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InCompleteCircleIcon;
