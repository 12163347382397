import Button from "@/components/common/button";
import { ConnectIcon } from "@/components/icons";
import useWallet from "@/hooks/use-wallet";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
const ConnectButton: FC = () => {
  const { fetchGenerateTonPayload, loading } = useWallet();
  return (
    <Button
      color="white"
      fullWidth
      disabled={loading}
      className="h-[3.125rem] animate-from-left"
      onClick={fetchGenerateTonPayload}
    >
      <div className="flex items-center gap-2">
        <ConnectIcon />
        <span>
          <FormattedMessage id="connect-wallet" />
        </span>
      </div>
    </Button>
  );
};

export default ConnectButton;
