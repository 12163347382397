import { createElement } from "react";
import { SubmissionResult } from "./global/enum";
import { FormattedMessage } from "react-intl";

const cdnUrl = import.meta.env.VITE_CDN_URL;

export const IMAGES = {
  AIRDROP_ICON: `${cdnUrl}/images/airdrop-icon.png`,
  GOLDEN_BALL: `${cdnUrl}/images/golden-ball.png`,
  BOY_AVATAR: `${cdnUrl}/images/boy-avatar.png`,
  GOLD_COIN: `${cdnUrl}/images/gold-coin.png`,
  SILVER_COIN: `${cdnUrl}/images/silver-coin.png`,
  BRONZE_COIN: `${cdnUrl}/images/bronze-coin.png`,
  TAP_IMAGE: `${cdnUrl}/images/tap-image.png`,
  ALERT: `${cdnUrl}/images/alert.png`,
  EMPTY_LIST: `${cdnUrl}/images/empty-list.png`,
  SIMPLE_TAP: `${cdnUrl}/images/simple-tap.png`,
  TAP_WITH_NUMBERS: `${cdnUrl}/images/tap-with-numbers.png`,
  BALL_ERROR: `${cdnUrl}/images/ball-error.png`,
  PREDI_BOY: `${cdnUrl}/images/predi-boy.png`,
  REPAIRS: `${cdnUrl}/images/repairs.png`,
  COIN_ICON: `${cdnUrl}/images/coin-icon.png`,
  GEM_ICON: `${cdnUrl}/images/gem-icon.png`,
  PREDI_ICON: `${cdnUrl}/images/predi-icon.png`,
  PROFILE_ICON: `${cdnUrl}/images/profile-avatar.png`,
  SHOP_ICON: `${cdnUrl}/images/shop-icon.png`,
  QUEST_ICON: `${cdnUrl}/images/quest-icon.png`,
  HOME_ICON: `${cdnUrl}/images/home-icon.png`,
  PREDICT_PRO_ICON: `${cdnUrl}/images/predict-pro-icon.png`,
  WALLET_ICON: `${cdnUrl}/images/wallet-icon.png`,
  HOME_SCREEN_STADIUM: `${cdnUrl}/images/home-stadium.jpg`,
  START_TOOLTIP: `${cdnUrl}/images/start-tooltip.png`,
  PASSED_TOOLTIP: `${cdnUrl}/images/passed-tooltip.png`,
  FAILED_TOOLTIP: `${cdnUrl}/images/failed-tooltip.png`,
  LOCK_TOOLTIP: `${cdnUrl}/images/locked-tooltip.png`,
  PENDING_TOOLTIP: `${cdnUrl}/images/pending-tooltip.png`,
  LIVE_TOOLTIP: `${cdnUrl}/images/live-tooltip.png`,
  BLUE_BALL: `${cdnUrl}/images/blue-ball.png`,
  GATE: `${cdnUrl}/images/gate.png`,
  PITCH: `${cdnUrl}/images/pitch.png`,
  REWARD_BG: `${cdnUrl}/images/reward-bg.png`,
  REWARD_AMOUNT_BG: `${cdnUrl}/images/reward-amount-bg.png`,
  HOME_SCREEN_STADIUM_LARGE: `${cdnUrl}/images/home-stadium-large.jpg`,
  THINKING_CLOUD: `${cdnUrl}/images/thinking-cloud.png`,
  LOCK_ICON: `${cdnUrl}/images/lock-icon.png`,
  PREDICT_PRO_BANNER: `${cdnUrl}/images/predict-pro-banner.jpg`,
  FOOT_STEP: `${cdnUrl}/images/foot-step.png`,
  YELLOW_TROPHY: `${cdnUrl}/images/yellow-trophy.png`,
  BOX_PREDI: `${cdnUrl}/images/box-predi.png`,
  TIMER: `${cdnUrl}/images/timer.png`,
  CALENDAR: `${cdnUrl}/images/calendar.png`,
  PURPLE_TIME: `${cdnUrl}/images/purple-time.png`,
  FILLED_CHECK: `${cdnUrl}/images/filled-check.png`,
  FILLED_FAILED: `${cdnUrl}/images/filled-failed.png`,
  FILLED_START: `${cdnUrl}/images/filled-start.png`,
  WALLET: `${cdnUrl}/images/wallet.png`,
  PREDI: `${cdnUrl}/images/predi.png`,
  BTN_CLOSE: `${cdnUrl}/images/btn-close.png`,
  INSUFFICIENT_PREDI: `${cdnUrl}/images/insufficient-predi.png`,
  FILLED_BLUE_CHECK: `${cdnUrl}/images/filled-blue-check.png`,
  UNION: `${cdnUrl}/images/union.png`,
  REWARD_BOX: `${cdnUrl}/images/reward-box.png`,
  DEPOSIT_WALLET: `${cdnUrl}/images/deposit-wallet.png`,
  WITHDRAW_WALLET: `${cdnUrl}/images/withdraw-wallet.png`,
  SHOP_BG: `${cdnUrl}/images/shop-bg.png`,
  SHOP_SKY_BLUE_UNION: `${cdnUrl}/images/shop-sky-blue-union.png`,
  SHOP_GREEN_UNION: `${cdnUrl}/images/shop-green-union.png`,
  SHOP_3_IN_1_PURPLE_BG: `${cdnUrl}/images/shop-3-in-1-purple-bg.png`,
  NOT_STARTED_TOOLTIP: `${cdnUrl}/images/not-started-tooltip.png`,
  NO_TRANSACTIONS: `${cdnUrl}/images/no-transactions.png`,
  NO_TASKS: `${cdnUrl}/images/no-tasks.png`,
  COMING_SOON: `${cdnUrl}/images/coming-soon.jpg`,
  CLOCK_ICON: `${cdnUrl}/images/clock-icon.png`,
  CHECK_LIST: `${cdnUrl}/images/check-list.png`,
  FILLED_INFO: `${cdnUrl}/images/filled-Info.png`,
  DATA_CARD_INVENTORY_BANNER: `${cdnUrl}/images/data-card-inventory-banner.jpg`,
  TOOL_CARD_INVENTORY_BANNER: `${cdnUrl}/images/tool-card-inventory-banner.jpg`,
  SUBMISSION_WON_BANNER: `${cdnUrl}/images/submission-won-banner.jpg`,
  SUBMISSION_FAILED_BANNER: `${cdnUrl}/images/submission-failed-banner.jpg`,
  SUBMISSION_WAITING_BANNER: `${cdnUrl}/images/submission-waiting-banner.jpg`,
  SUBMISSION_LIVE_BANNER: `${cdnUrl}/images/submission-live-banner.jpg`,
  SUCCESSFUL_PURCHASE: `${cdnUrl}/images/successful-purchase.png`,
  INSUFFICIENT_DOLLAR: `${cdnUrl}/images/insufficient-dollar.png`,
  INSUFFICIENT_GEM: `${cdnUrl}/images/insufficient-gem.png`,
  INSUFFICIENT_TPPOG: `${cdnUrl}/images/insufficient-tppog.png`,
  BUNDLE_LIMITATION: `${cdnUrl}/images/bundle-limitation.png`,
  LAUGHING_PREDI: `${cdnUrl}/images/laughing-predi.png`,
  SESSION_BANNER: `${cdnUrl}/images/session-banner.jpg`,
  PREDIPIE: `${cdnUrl}/images/predipie.png`,
  ARROW_TOP: `${cdnUrl}/images/arrow-top.png`,
  CHECK_ICON: `${cdnUrl}/images/check-icon.png`,
  PREDI_BOY_WIN: `${cdnUrl}/images/predi-boy-win.png`,
  PREDI_BOY_WITH_TEN_THOUSAND: `${cdnUrl}/images/predi-boy-with-ten-thousand.png`,
  PASSED_LEVELS: `${cdnUrl}/images/passed-levels.png`,
  REVERSE_CLOUD: `${cdnUrl}/images/reverse-cloud.png`,
  FIRST_PLACE: `${cdnUrl}/images/first-place.png`,
  SECOND_PLACE: `${cdnUrl}/images/second-place.png`,
  THIRD_PLACE: `${cdnUrl}/images/third-place.png`,
  BEARDED_AVATAR: `${cdnUrl}/images/bearded-avatar.png`,
  HAPPY_AVATAR: `${cdnUrl}/images/happy-avatar.png`,
  SERIOUS_AVATAR: `${cdnUrl}/images/serious-avatar.png`,
  ORANGE_OUTFIT_AVATAR: `${cdnUrl}/images/orange-outfit-avatar.png`,
  ORANGE_HAIR_AVATAR: `${cdnUrl}/images/orange-hair-avatar.png`,
  PRO_SUBMIT_BANNER: `${cdnUrl}/images/pro-submit-banner.jpeg`,
  RETRY_BANNER: `${cdnUrl}/images/retry-banner.png`,
  EMPTY_LEADER_BOARD: `${cdnUrl}/images/empty-leader-board.png`,
  LEADER_BOARD_REWARDS_BANNER: `${cdnUrl}/images/leader-board-rewards-banner.jpg`,
  LEADER_BOARD_REWARDS_BACKGROUND: `${cdnUrl}/images/leaderboard-reward-background.jpg`,
  AIRDROP: `${cdnUrl}/images/airdrop.png`,
  SIMPLE_WALLET: `${cdnUrl}/images/simple-wallet.png`,
  POINTER_CURSOR: `${cdnUrl}/images/pointer-cursor.png`,
  STACK_OF_COINS: `${cdnUrl}/images/stack-of-coins.png`,
  DAILY_REWARD: `${cdnUrl}/images/daily-reward.png`,
  SIMPLE_CHECK: `${cdnUrl}/images/simple-check.png`,
  AD_ICON: `${cdnUrl}/images/ad.png`,
  CONGRATULATIONS: `${cdnUrl}/images/congratulations.png`,
  DEPOSIT_BANNER: `${cdnUrl}/images/deposit-banner.jpg`,
  DOGS_COIN: `${cdnUrl}/images/dogs-coin.png`,
  HAMSTER_COIN: `${cdnUrl}/images/hamster-coin.png`,
  NOT_COIN: `${cdnUrl}/images/not-coin.png`,
  USDT_COIN: `${cdnUrl}/images/usdt-coin.png`,
  TELEGRAM_ERROR: `${cdnUrl}/images/telegram-error.png`,
};

export const CACHE_KEYS = {
  INVENTORIES: "INVENTORIES",
  USER_FRIENDS: "USER_FRIENDS",
  USER_TASKS: "USER_TASKS",
  BUNDLE_CATEGORY: "BUNDLE_CATEGORY",
  BUNDLES: "BUNDLES",
  JOURNEYS: "JOURNEYS",
  WITHDRAWABLE_AMOUNT: "WITHDRAWABLE_AMOUNT",
  USER_INVENTORY: "USER_INVENTORY",
  WALLET_PAGE: "WALLET_PAGE",
  WALLET_PAGE_DOLLAR_LOG: "WALLET_PAGE_DOLLAR_LOG",
  DEPOSIT_WALLETS: "DEPOSIT_WALLETS",
  PRO_SUBMISSIONS: "PRO_SUBMISSIONS",
  PRO_STATS: "PRO_STATS",
  LEADER_BOARD: "LEADER_BOARD",
  PRO_SUBMISSIONS_REWARDS: "PRO_SUBMISSIONS_REWARDS",
  LEADER_BOARD_REWARDS: "LEADER_BOARD_REWARDS",
  DAILY_REWARDS: "DAILY_REWARDS",
  AD_TASK: "AD_TASK",
  TON_BALANCES: "TON_BALANCES",
};

export const disableAutoCompleteInputAttrs = {
  "aria-autocomplete": "both",
  "aria-haspopup": false,
  autoCapitalize: "off",
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: false,
  title: "Pesquisar",
} as const;

export const ERROR_STATUS = {
  NOT_FOUND: 404,
  UNAUTHORIZE: 401,
  BAD_REQUEST: 400,
};

export const ERROR_CODES = {
  INSUFFICIENT_BALANCE: "ERR_INSUFFICIENT_BALANCE",
  MAX_BALANCE_REACHED: "ERR_MAX_BALANCE_REACHED",
  TOKEN_DEPRECATED: "ERR_TOKEN_DEPRECATED",
  PREDICT_PRO_STEP_CONDITION_NOT_MATCHED:
    "ERR_PREDICT_PRO_STEP_CONDITION_NOT_MATCHED",
};

export const DEFAULT_OTP_LENGTH = 6;

export const FETCH_ONCHAIN_BALANCES_INTERVAL = 10000;

export const WEBSITE_URL = "https://predipie.com";

export const MAX_JOURNEY_ONBOARDING_COUNT = 2;

export const predictionResults = [
  {
    id: SubmissionResult.HOME,
    title: createElement(FormattedMessage, { id: "home" }),
  },
  {
    id: SubmissionResult.DRAW,
    title: createElement(FormattedMessage, { id: "draw" }),
  },
  {
    id: SubmissionResult.AWAY,
    title: createElement(FormattedMessage, { id: "away" }),
  },
];
