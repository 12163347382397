import { SVGProps, useRef } from "react";

const BarsIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="Content / bars">
        <g id="Vector" filter="url(#filter0_d_3123_152575)">
          <path
            d="M3 8.2002H21C21.2652 8.2002 21.5196 8.09484 21.7071 7.9073C21.8946 7.71977 22 7.46541 22 7.2002C22 6.93498 21.8946 6.68063 21.7071 6.49309C21.5196 6.30555 21.2652 6.2002 21 6.2002H3C2.73478 6.2002 2.48043 6.30555 2.29289 6.49309C2.10536 6.68063 2 6.93498 2 7.2002C2 7.46541 2.10536 7.71977 2.29289 7.9073C2.48043 8.09484 2.73478 8.2002 3 8.2002ZM21 16.2002H3C2.73478 16.2002 2.48043 16.3056 2.29289 16.4931C2.10536 16.6806 2 16.935 2 17.2002C2 17.4654 2.10536 17.7198 2.29289 17.9073C2.48043 18.0948 2.73478 18.2002 3 18.2002H21C21.2652 18.2002 21.5196 18.0948 21.7071 17.9073C21.8946 17.7198 22 17.4654 22 17.2002C22 16.935 21.8946 16.6806 21.7071 16.4931C21.5196 16.3056 21.2652 16.2002 21 16.2002ZM21 11.2002H3C2.73478 11.2002 2.48043 11.3056 2.29289 11.4931C2.10536 11.6806 2 11.935 2 12.2002C2 12.4654 2.10536 12.7198 2.29289 12.9073C2.48043 13.0948 2.73478 13.2002 3 13.2002H21C21.2652 13.2002 21.5196 13.0948 21.7071 12.9073C21.8946 12.7198 22 12.4654 22 12.2002C22 11.935 21.8946 11.6806 21.7071 11.4931C21.5196 11.3056 21.2652 11.2002 21 11.2002Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3123_152575"
          x="2"
          y="6.2002"
          width="20"
          height="13"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.247059 0 0 0 0 0.643137 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3123_152575"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3123_152575"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BarsIcon;
