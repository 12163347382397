import { type SVGProps, useRef } from "react";

const SupportIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M22 8.7002C22 12.2902 19.09 15.2002 15.5 15.2002C15.33 15.2002 15.15 15.1902 14.98 15.1802C14.73 12.0102 12.19 9.47018 9.01999 9.22018C9.00999 9.05018 9 8.8702 9 8.7002C9 5.1102 11.91 2.2002 15.5 2.2002C19.09 2.2002 22 5.1102 22 8.7002Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15.7002C15 19.2902 12.09 22.2002 8.5 22.2002C4.91 22.2002 2 19.2902 2 15.7002C2 12.1102 4.91 9.2002 8.5 9.2002C8.67 9.2002 8.84999 9.21018 9.01999 9.22018C12.19 9.47018 14.73 12.0102 14.98 15.1802C14.99 15.3502 15 15.5302 15 15.7002Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62 14.8202L8.5 13.2002L9.38 14.8202L11 15.7002L9.38 16.5802L8.5 18.2002L7.62 16.5802L6 15.7002L7.62 14.8202Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;
