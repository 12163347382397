import { FC, useEffect, useState } from "react";
import Header from "@/components/common/header";
import Level from "./level";
import { useIntl } from "react-intl";
import useApi from "@/helpers/api/hooks/use-api";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { CACHE_KEYS } from "@/constants";
import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import {
  JourneyCurrentStep,
  JourneyType,
} from "@/helpers/api/types/api-response";
import useErrorState from "@/hooks/use-error-state";
import LevelReward from "./reward";
import { CurrentStepContext } from "./context/current-step";

const JourneysContent: FC = () => {
  const { formatMessage } = useIntl();
  const [stepError, setStepError] = useState();
  const [currentStep, setCurrentStep] = useState<JourneyCurrentStep>();
  const { getJourneys, getJourneyCurrentStep } = useApi();

  const { fetch, data, error } = useApiCache<JourneyType>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getJourneys()
          .then((res) => {
            const levels = res.data[0];
            setCache(levels);
            resolve(levels);
          })
          .catch((err) => reject(err))
      ),
    [CACHE_KEYS.JOURNEYS]
  );

  const { errorMessage, hasError } = useErrorState({ stepError, error });

  const fetchCurrentStep = () => {
    return getJourneyCurrentStep().then(setCurrentStep).catch(setStepError);
  };

  useEffect(() => {
    if (!data) {
      fetch();
    } else {
      fetchCurrentStep();
    }
  }, [data]);

  return (
    <div className="flex-1 flex flex-col">
      <Header title={formatMessage({ id: "journey" })} />
      <div className="max-w-lg mx-auto px-6 pt-24 grow flex flex-col">
        {hasError ? (
          <FetchError onRetry={fetch} message={errorMessage} className="grow" />
        ) : !data || !currentStep ? (
          <div className="grow flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <CurrentStepContext.Provider
            value={{
              currentStep,
              activeStepNumber: data.levels
                .flatMap(({ steps }) => steps)
                .find(({ id }) => id === currentStep.currentStepId)!
                .displayOrder,
              setCurrentStep: setCurrentStep,
            }}
          >
            <LevelReward bundle={data.bundle} isRewardClaimable={false} />
            {data?.levels
              .sort((a, b) => b.displayOrder - a.displayOrder)
              .map((level) => (
                <Level key={level.title} level={level} />
              ))}
          </CurrentStepContext.Provider>
        )}
      </div>
    </div>
  );
};

export default JourneysContent;
