import classNames from "classnames";
import { FC } from "react";
import Image from "@/components/common/image";
import { FormattedMessage } from "react-intl";

interface SpecialQuestProps {
  titleId: string;
  descriptionId: string;
  mainIcon: string;
  actionButtonTitleId: string;
  appearance: "orange" | "purple";
  descriptionIcon?: string;
  onActionButtonClick(): void;
}

const specialQuestContainerStyleMapping: Record<
  SpecialQuestProps["appearance"],
  string
> = {
  orange: "from-yellow-400 to-yellow-600 shadow-yellow-900 text-yellow-950",
  purple: "from-purple-300 to-purple-500 shadow-purple-800 text-purple-950",
};

const actionButtonStyleMapping: Record<
  SpecialQuestProps["appearance"],
  string
> = {
  orange:
    "bg-yellow-800 border-yellow-900 text-shadow-0-1-yellow-950 shadow-yellow-950 text-stroke-md-yellow-950",
  purple:
    "bg-purple-800 border-purple-950 text-shadow-0-1-purple-950 shadow-red-950 text-stroke-md-purple-950",
};

const SpecialQuest: FC<SpecialQuestProps> = ({
  actionButtonTitleId,
  appearance,
  descriptionIcon,
  descriptionId,
  mainIcon,
  onActionButtonClick,
  titleId,
}) => {
  return (
    <div
      className={classNames(
        "bg-gradient-to-b gap-2 px-2 py-3 flex justify-between items-center rounded-lg box-shadow-inner-0--4",
        specialQuestContainerStyleMapping[appearance]
      )}
    >
      <Image
        src={mainIcon}
        alt="quest icon"
        className="w-12 aspect-square object-contain"
      />
      <div className="flex justify-between flex-1 items-center">
        <div className="flex flex-col gap-1">
          <p className="text-lg font-bold">
            <FormattedMessage id={titleId} />
          </p>
          <div className="flex gap-0.5 items-center">
            {descriptionIcon && (
              <Image
                src={descriptionIcon}
                alt="airdrop coin"
                className="w-6 h-6"
              />
            )}
            <p className="text-sm font-bold">
              <FormattedMessage id={descriptionId} />
            </p>
          </div>
        </div>
        <div
          className={classNames(
            "box-shadow-inner-0-1-md px-4 py-1 rounded-lg border font-bold text-sm text-neutral-50",
            actionButtonStyleMapping[appearance]
          )}
          onClick={() => onActionButtonClick()}
        >
          <FormattedMessage id={actionButtonTitleId} />
        </div>
      </div>
    </div>
  );
};

export default SpecialQuest;
