import { postEvent, useLaunchParams } from "@telegram-apps/sdk-react";
import { type FC, useEffect, useState, Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { routes } from "@/navigation/routes.tsx";
import MainLayout from "./layout/main";
import SplashScreen from "@/components/common/splash-screen";
import { useTonConnectUI } from "@tonconnect/ui-react";
import useSplashScreen from "@/components/common/splash-screen/use-splash-screen";
import useApi, { CheckProofBodyType } from "@/helpers/api/hooks/use-api";
import { useData } from "@/store/store";
import { IntlProvider } from "react-intl";
import useLocale from "@/i18n/hooks/use-locale";
import FixedSpinner from "@/components/common/spinner/fiexed";
import SessionExpired from "@/components/common/session-expired";
import SSEProvider from "@/components/common/sse";
import { showErrorMessage } from "@/utils/string";
import NetworkStatusWarning from "@/components/common/network-error-modal";
import RotateWarning from "@/components/common/rotate-warning";
import classNames from "classnames";

export const App: FC = () => {
  const [canRunApp, setCanRunApp] = useState<boolean>(false);
  const { id: locale } = useLocale();
  const [tonConnectUI] = useTonConnectUI();
  const splashItems = useSplashScreen();
  const { setIsCheckingProof, connectWalletInfo, isSessionExpired } = useData();
  const { checkProof } = useApi();
  const { id, messages, direction } = useLocale();

  const lp = useLaunchParams();

  useEffect(() => {
    postEvent("web_app_expand");
    postEvent("web_app_setup_swipe_behavior", { allow_vertical_swipe: false });
    postEvent("web_app_setup_closing_behavior", { need_confirmation: true });
    // postEvent("web_app_request_full_screen" as any);
    const platformClassName = ["macos", "ios"].includes(lp.platform)
      ? "ios"
      : "base";
    document.body.classList.add(platformClassName);
  }, []);

  useEffect(() => {
    tonConnectUI.onStatusChange((wallet) => {
      const tonProof = wallet?.connectItems?.tonProof;

      // ton connect generates a proof based on the domain and checks with the proof from the server
      // if the proof is valid, then the wallet is connected
      // the provided domain which is localhost should match the expected domain from the server which it does not when developing locally
      // so we provide the domain manually in development mode
      const devProofDomain = {
        domain: {
          value: "newtma.predipie.com",
          lengthBytes: 21,
        },
      };

      if (tonProof && "proof" in tonProof) {
        const reqBody: CheckProofBodyType = {
          address: wallet.account.address,
          network: wallet.account.chain,
          proof: {
            ...tonProof.proof,
            state_init: wallet.account.walletStateInit,
            ...(import.meta.env.DEV && devProofDomain),
          },
          metaData: {},
        };
        setIsCheckingProof(true);
        checkProof(reqBody)
          .then(connectWalletInfo)
          .catch((e) => {
            toast.error(showErrorMessage(e.message), {
              position: "top-center",
              duration: 5000,
            });
            tonConnectUI.disconnect();
          })
          .finally(() => setIsCheckingProof(false));
      }
    });
  }, []);

  useEffect(() => {
    if (!splashItems.some((item) => !item)) {
      setTimeout(() => setCanRunApp(true), 1000 / splashItems.length);
    }
  }, [splashItems]);

  const appContent = () => {
    if (isSessionExpired) return <SessionExpired />;
    else if (canRunApp)
      return (
        <MainLayout>
          <SSEProvider>
            <Toaster />
            <NetworkStatusWarning />
            <Suspense fallback={<FixedSpinner />}>
              <Routes>
                {routes.map(({ sub, ...route }, i) => (
                  <Route key={`route-${i}`} {...route}>
                    {sub?.map((subRoute, subIndex) => (
                      <Route key={`sub-route-${subIndex}`} {...subRoute} />
                    ))}
                  </Route>
                ))}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </SSEProvider>
        </MainLayout>
      );
    return <SplashScreen />;
  };

  return (
    <IntlProvider messages={messages} locale={id}>
      <div
        className={classNames(direction)}
        dir={direction}
        lang={locale}
        id="language-wrapper"
      >
        <HashRouter>{appContent()}</HashRouter>
        <RotateWarning />
      </div>
    </IntlProvider>
  );
};
