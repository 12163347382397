import { matchPath } from "react-router";

const paths = {
  home: "/",
  quest: "/quest",
  shop: "/shop",
  wallet: {
    index: "/wallet",
    deposit: "/wallet/deposit",
    depositStep2: "/wallet/deposit/step2/:id",
    withdraw: "/wallet/withdraw",
  },
  predictPro: {
    index: "/predict-pro",
    matches: "/predict-pro/matches",
    timeIsUp: "/predict-pro/time-is-up",
    submit: "/predict-pro/submit",
    nickName: "/predict-pro/nick-name",
    reward: "/predict-pro/reward",
    submission: "/predict-pro/submission/:id",
  },
  friends: "/friends",
  dailyReward: "/daily-reward",
  journeys: {
    index: "/journeys",
    matches: "/journeys/matches",
    submit: "/journeys/submit",
    submission: "/journeys/submission/:id",
    timeIsUp: "/journeys/time-is-up",
    onboarding: "/journeys/onboarding",
  },
  replace: {
    select: "/replace/select/:id",
    predict: "/replace/predict",
  },
};

export default paths;

export const isValidInternalPath = (
  searchString: string,
  obj: any = paths
): boolean => {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      return !!matchPath(searchString, obj[key]);
    } else if (typeof obj[key] === "object") {
      return isValidInternalPath(searchString, obj[key]);
    }
  }
  return false;
};
