import { SVGProps, useRef } from "react";

const AddFriendIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M8 2.2002C5.38 2.2002 3.25 4.3302 3.25 6.9502C3.25 9.5202 5.26 11.6002 7.88 11.6902C7.96 11.6802 8.04 11.6802 8.1 11.6902C8.12 11.6902 8.13 11.6902 8.15 11.6902C8.16 11.6902 8.16 11.6902 8.17 11.6902C10.73 11.6002 12.74 9.5202 12.75 6.9502C12.75 4.3302 10.62 2.2002 8 2.2002Z"
        fill="currentColor"
      />
      <path
        d="M13.08 14.3501C10.29 12.4901 5.73996 12.4901 2.92996 14.3501C1.65996 15.2001 0.959961 16.3501 0.959961 17.5801C0.959961 18.8101 1.65996 19.9501 2.91996 20.7901C4.31996 21.7301 6.15996 22.2001 7.99996 22.2001C9.83996 22.2001 11.68 21.7301 13.08 20.7901C14.34 19.9401 15.04 18.8001 15.04 17.5601C15.03 16.3301 14.34 15.1901 13.08 14.3501Z"
        fill="currentColor"
      />
      <path
        d="M18.9899 7.5403C19.1499 9.4803 17.7699 11.1803 15.8599 11.4103C15.8499 11.4103 15.8499 11.4103 15.8399 11.4103H15.8099C15.7499 11.4103 15.6899 11.4103 15.6399 11.4303C14.6699 11.4803 13.7799 11.1703 13.1099 10.6003C14.1399 9.68029 14.7299 8.30029 14.6099 6.80029C14.5399 5.99029 14.2599 5.2503 13.8399 4.6203C14.2199 4.4303 14.6599 4.3103 15.1099 4.2703C17.0699 4.1003 18.8199 5.5603 18.9899 7.5403Z"
        fill="currentColor"
      />
      <path
        d="M20.9902 16.7906C20.9102 17.7606 20.2902 18.6006 19.2502 19.1706C18.2502 19.7206 16.9902 19.9806 15.7402 19.9506C16.4602 19.3006 16.8802 18.4906 16.9602 17.6306C17.0602 16.3906 16.4702 15.2006 15.2902 14.2506C14.6202 13.7206 13.8402 13.3006 12.9902 12.9906C15.2002 12.3506 17.9802 12.7806 19.6902 14.1606C20.6102 14.9006 21.0802 15.8306 20.9902 16.7906Z"
        fill="currentColor"
      />
      <path
        d="M24 22.2002H20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 24.2002V20.2002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddFriendIcon;
