import { FC } from "react";
import GemCard, { GemCardProps } from "..";
import { useIntl } from "react-intl";
import Image from "../../image";
import { useData } from "@/store/store";
import { AssetUnit } from "@/global/enum";

const GameGemCard: FC<Omit<GemCardProps, "icon" | "value">> = (props) => {
  const { formatNumber } = useIntl();
  const { balances } = useData();
  const balance = balances![AssetUnit.GEM];
  return (
    <GemCard
      icon={
        <Image src={balance?.asset.logo.path} className="h-7 w-7" alt="coin" />
      }
      value={formatNumber(balance?.amount)}
      {...props}
    />
  );
};

export default GameGemCard;
