import Page from "@/components/common/page";
import ShopContent from "@/components/pages/shop/content";
import { FC } from "react";

const ShopPage: FC = () => (
  <Page>
    <ShopContent />
  </Page>
);

export default ShopPage;
