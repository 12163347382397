import { FC, useEffect, useState } from "react";
import useApi from "@/helpers/api/hooks/use-api";
import { DollarBalanceLogType } from "@/helpers/api/types/api-response";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { CACHE_KEYS } from "@/constants";
import Spinner from "@/components/common/spinner";
import { FormattedMessage } from "react-intl";
import FetchError from "@/components/common/fetch-error";
import NotFound from "./not-found";
import TransactionCard from "./transaction-card";
import useBalance from "@/hooks/use-balance";
import Dropdown, { DropdownItem } from "@/components/common/dropdown";
import { TransactionType } from "@/global/enum";

type TransactionFilter = "deposit" | "withdraw" | "all";

const dropdownItems: DropdownItem<TransactionFilter>[] = [
  {
    value: "all",
    title: <FormattedMessage id="all" />,
  },
  {
    value: "deposit",
    title: <FormattedMessage id="wallet-type-deposit" />,
  },
  {
    value: "withdraw",
    title: <FormattedMessage id="wallet-type-withdraw" />,
  },
];

const DollarLog: FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<TransactionFilter>(
    dropdownItems[0].value
  );
  const { getDollarBalanceLog } = useApi();
  const { updateBalances } = useBalance();

  const { fetch, data, error } = useApiCache<DollarBalanceLogType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getDollarBalanceLog({ limit: 50 })
          .then((res) => {
            setCache(res.data);
            updateBalances();
            resolve(res.data);
          })
          .catch(reject)
      ),
    [CACHE_KEYS.WALLET_PAGE_DOLLAR_LOG]
  );

  useEffect(() => {
    fetch();
  }, []);

  const transactions = data?.filter(({ type, amount }) => {
    switch (selectedFilter) {
      case "all":
        return true;
      case "deposit":
        return type === TransactionType.Transaction && +amount > 0;
      case "withdraw":
        return type === TransactionType.Transaction && +amount < 0;
      default:
        return false;
    }
  });

  return (
    <div className="flex flex-col gap-4 grow mt-4">
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold animate-fade-in">
          <FormattedMessage id="transaction-history" />
        </p>
        <Dropdown<TransactionFilter>
          options={dropdownItems}
          onSelect={setSelectedFilter}
          value={selectedFilter}
          className="animate-grow-up"
          disabled={!data?.length}
        />
      </div>
      <div className="flex flex-col gap-2 grow">
        {error ? (
          <FetchError
            onRetry={fetch}
            message={error.message}
            className="grow"
          />
        ) : transactions ? (
          transactions.length === 0 ? (
            <NotFound />
          ) : (
            transactions.map(({ amount, createdAt, type, id }) => (
              <TransactionCard
                key={id}
                amount={amount}
                createdAt={createdAt}
                type={type}
              />
            ))
          )
        ) : (
          <div className="grow py-6 flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default DollarLog;
