import { BundleItemType } from "@/helpers/api/types/api-response";
import { AssetUnit, BundleCategoryColorEnum } from "@/global/enum";
import { FC } from "react";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "../cn";
import Image from "@/components/common/image";
import classNames from "classnames";
import NumberWithSuffix from "@/components/common/number-with-suffix";

interface PriceProps extends Pick<BundleItemType, "asset" | "price"> {
  color: BundleCategoryColorEnum;
}

const Price: FC<PriceProps> = ({ asset, price, color }) => {
  const { priceClassName } = BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];
  const formattedNumberProps =
    asset.unit === AssetUnit.USD
      ? ({ currency: "USD", style: "currency" } as const)
      : {};
  return (
    <div className="flex items-center justify-center gap-1">
      <Image src={asset.logo.path} alt={asset.title} className="h-6" />
      <div className={classNames(priceClassName, "text-lg font-bold")}>
        <NumberWithSuffix {...formattedNumberProps} value={+price} />
      </div>
    </div>
  );
};

export default Price;
