import { type SVGProps, useRef } from "react";

const RotateIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M3.16699 10.6665C3.16699 6.89526 3.16699 5.00965 4.33857 3.83808C5.51014 2.6665 7.39575 2.6665 11.167 2.6665H12.5003C16.2715 2.6665 18.1571 2.6665 19.3287 3.83808C20.5003 5.00965 20.5003 6.89526 20.5003 10.6665V21.3332C20.5003 25.1044 20.5003 26.99 19.3287 28.1616C18.1571 29.3332 16.2715 29.3332 12.5003 29.3332H11.167C7.39575 29.3332 5.51014 29.3332 4.33857 28.1616C3.16699 26.99 3.16699 25.1044 3.16699 21.3332V10.6665Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M23.833 13.3423C26.3126 13.3782 27.7183 13.5622 28.6614 14.5052C29.833 15.6768 29.833 17.5624 29.833 21.3336C29.833 25.1048 29.833 26.9906 28.6614 28.162C27.7183 29.1052 26.3126 29.2891 23.833 29.325"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.1667 6.6665H8.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.833 23.9998V18.6665"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.833 23.3335C13.833 24.438 12.9376 25.3335 11.833 25.3335C10.7284 25.3335 9.83301 24.438 9.83301 23.3335C9.83301 22.229 10.7284 21.3335 11.833 21.3335C12.9376 21.3335 13.833 22.229 13.833 23.3335Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M24.5492 4.72073L23.4106 2.69968C26.7955 2.69968 29.6054 5.6131 30.1475 9.43652"
        stroke="#FF81E3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RotateIcon;
