import { FC, createElement } from "react";
import { BundleDisplayProps } from "../types";
import threeInOneItem from "./three-in-one-item";
import GridItem from "./grid-item";

const List: FC<BundleDisplayProps> = ({ bundles, color }) => {
  return (
    <div className="flex gap-2 items-stretch overflow-x-auto px-4 pb-1">
      {bundles.map((d) =>
        createElement(d.bundleProducts.length > 1 ? GridItem : threeInOneItem, {
          key: d.id,
          isFullWidth: bundles.length === 1,
          color,
          data: d,
        })
      )}
    </div>
  );
};

export default List;
