import { FC } from "react";
import s from "./spinner.module.css";
import classNames from "classnames";

const Spinner: FC = () => (
  <span
    className={classNames(
      "text-white perspective-1000 animate-fade-in w-12 h-12 rounded-full",
      s.loader
    )}
  />
);

export default Spinner;
