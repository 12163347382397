import { FC } from "react";
import { BundleDisplayProps } from "../types";
import Item from "./item";

const List: FC<BundleDisplayProps> = ({ bundles, color }) => {
  return (
    <div className="grid grid-cols-3 gap-2 px-4">
      {bundles.map((d) => (
        <Item key={d.id} data={d} color={color} />
      ))}
    </div>
  );
};

export default List;
