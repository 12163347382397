import Image from "@/components/common/image";
import NumberWithSuffix from "@/components/common/number-with-suffix";
import { AssetUnit } from "@/global/enum";
import { usePersistData } from "@/store/persist-store";
import { useData } from "@/store/store";
import { FC } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

const isStage = import.meta.env.VITE_IS_STAGE === "true";

const Token: FC = () => {
  const { balances, tapData } = useData();
  const { setOnboarding } = usePersistData();
  const tppog = balances![AssetUnit.TPPOG]!;
  const dailyLimitTap = tapData!.dailyTppogLimit;
  return (
    <div
      className="animate-from-left flex flex-col justify-between p-2 gap-1 rounded-2xl bg-gradient-to-b from-yellow-400 to-yellow-600 box-shadow-0-4 shadow-yellow-950"
      onClick={() => {
        if (isStage) setOnboarding({ isGamePlayOnboardingDone: false });
      }}
    >
      <div className="rounded-lg bg-yellow-900 border border-yellow-950 box-shadow-inner-0-6 shadow-neutral-0/[0.15]">
        <div className="rounded-lg flex items-center justify-center px-1 gap-1">
          <div className="w-7 h-7 shrink-0 rounded-full border border-yellow-600">
            <Image
              src={tppog.asset.logo.path}
              className="w-full"
              alt={tppog.asset.title}
            />
          </div>
          <p className="font-bold text-shadow-0-1-yellow-950 text-stroke-sm-yellow-950 h-8 flex items-center justify-center text-base">
            <FormattedNumber value={tppog.amount} maximumFractionDigits={0} />
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 bg-yellow-950 rounded-lg font-bold grow">
        <div className="text-xxs">
          <FormattedMessage id="daily-limit" />
        </div>
        <div className="text-sm">
          <NumberWithSuffix
            value={dailyLimitTap - tapData!.dailyTppogRemain}
            maximumFractionDigits={1}
          />
          /
          <NumberWithSuffix value={dailyLimitTap} />
        </div>
      </div>
    </div>
  );
};

export default Token;
