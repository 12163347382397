import Page from "@/components/common/page";
import WithdrawContent from "@/components/pages/wallet/withdraw/content";
import { FC } from "react";

const WithdrawPage: FC = () => (
  <Page>
    <WithdrawContent />
  </Page>
);

export default WithdrawPage;
