import { IMAGES } from "@/constants";
import useApi from "@/helpers/api/hooks/use-api";
import { FC, Reducer, useEffect, useReducer } from "react";
import Button from "../button";
import { useData } from "@/store/store";
import { useNavigate } from "react-router-dom";
import { isValidInternalPath } from "@/navigation/paths";
import SplashScreenWrapper from "./wrapper";
import useSplashScreen from "./use-splash-screen";
import { ErrorAction, ErrorState } from "./type";
import { FormattedMessage } from "react-intl";
import Image from "../image";
import useAuth from "@/hooks/use-auth";
import useStartParams from "@/hooks/use-start-params";
import useBalance from "@/hooks/use-balance";
import useErrorState from "@/hooks/use-error-state";
import { showErrorMessage } from "@/utils/string";

const MIN_TIME_TO_SHOW = 2000; /** ms */

export const initialErrorState: ErrorState = {
  auth: null,
  balances: null,
  taps: null,
};

const SplashScreen: FC = () => {
  const [error, setError] = useReducer<Reducer<ErrorState, ErrorAction>>(
    (s, p) => ({ ...s, ...p }),
    initialErrorState
  );
  const { hasError, errorMessage } = useErrorState(error);
  const { login, getUserProfile, getTaps } = useApi();
  const navigate = useNavigate();
  const { updateBalances } = useBalance();
  const { setUser, setIsSplashScreenTimeoutReached, user, setTapData } =
    useData();
  const { internalLogin, getStoredTokens } = useAuth();
  const [{ startUri }] = useStartParams();
  const splashItems = useSplashScreen();

  const progress =
    (100 * splashItems.filter(Boolean).length) / splashItems.length;

  const getUserAuthToken = () =>
    login()
      .then((res) => {
        internalLogin({
          refreshToken: res.refreshToken,
          accessToken: res.token,
        });
        setUser(res.user);
      })
      .catch((err) => setError({ auth: err }));

  const fetchUserProfile = () =>
    getUserProfile()
      .then(setUser)
      .catch((err) => setError({ auth: err }));

  const fetchBalances = () =>
    updateBalances().catch((err) => setError({ balances: err }));

  const fetchTaps = () =>
    getTaps()
      .then(setTapData)
      .catch((err) => setError({ taps: err }));

  const initUser = () => {
    setError(initialErrorState);
    getStoredTokens()?.accessToken ? fetchUserProfile() : getUserAuthToken();
  };

  const onRetry = () => {
    if (error.auth) initUser();
    if (error.balances) fetchBalances();
    if (error.taps) fetchTaps();
  };

  useEffect(() => {
    if (user) {
      fetchBalances();
      fetchTaps();
    }
  }, [user]);

  useEffect(() => {
    initUser();
    const timeoutId = setTimeout(
      () => setIsSplashScreenTimeoutReached(true),
      MIN_TIME_TO_SHOW
    );
    if (startUri && isValidInternalPath(startUri))
      setTimeout(() => navigate(startUri), 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="h-stable-screen flex flex-col">
      <SplashScreenWrapper>
        <div className="h-full flex flex-col justify-center items-center max-w-2xl mx-auto pt-10">
          <div className="flex flex-col items-center justify-center grow">
            <div
              className="w-[16.125rem] h-[9.375rem] bg-contain bg-center bg-no-repeat px-5 pb-7 flex flex-col text-center items-center justify-center gap-1"
              style={{ backgroundImage: `url("${IMAGES.THINKING_CLOUD}")` }}
            >
              <div className="text-blue-700 font-bold text-2xl">
                <FormattedMessage id="welcome" />
              </div>
              <div className="text-blue-850 font-bold text-base">
                <FormattedMessage id="welcome-desc" />
              </div>
            </div>
            <Image
              className="h-[12.5rem]"
              alt="predi-boy"
              src={IMAGES.PREDI_BOY}
            />
          </div>
          <p className="pt-8 text-center text-2xl font-bold">
            <FormattedMessage
              id="splash-screen-desc"
              values={{
                br: <br />,
                price: (chunks) => (
                  <span className="text-yellow-600">{chunks}</span>
                ),
              }}
            />
          </p>
          <footer className="pt-8 w-full px-4 pb-4">
            {hasError ? (
              <div className="flex flex-col justify-end gap-1.5 h-full">
                <div className="text-center text-base animate-fade-in">
                  {showErrorMessage(errorMessage)}
                </div>
                <div className="animate-from-bottom w-full">
                  <Button color="secondary" fullWidth onClick={onRetry}>
                    <FormattedMessage id="retry" />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="px-5 text-base text-center animate-fade-in">
                <FormattedMessage id="loading" />
                <div className="mt-2 mb-8 border border-blue-800 bg-blue-900 h-6 p-1 rounded-full overflow-hidden">
                  <div
                    className="h-full rounded-full bg-blue-700 transition-[width] ease-linear"
                    style={{
                      width: `${progress}%`,
                      transitionDuration: `${1000 / splashItems.length}ms`,
                    }}
                  />
                </div>
              </div>
            )}
          </footer>
        </div>
      </SplashScreenWrapper>
    </div>
  );
};

export default SplashScreen;
