import { FC } from "react";
import { FormattedNumber } from "react-intl";

const FormattedBalance: FC<{
  balance: number;
  style: "JETTON" | "USD";
}> = ({ balance, style }) => {
  return (
    <FormattedNumber
      value={balance}
      style={style === "USD" ? "currency" : "decimal"}
      currency={style === "USD" ? "USD" : undefined}
      minimumFractionDigits={0}
      maximumFractionDigits={style === "USD" ? (balance < 1 ? 6 : 2) : 4}
    />
  );
};

export default FormattedBalance;
