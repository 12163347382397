import { type ComponentProps, createElement, type FC } from "react";
import type { DynamicComponentProps, TransitionStyles } from "@/global/types";
import { Transition } from "react-transition-group";

export interface FadeProps {
  /**
   * If `true`, the component will transition in.
   */
  in: boolean;
  /**
   * The duration for the transition, in milliseconds.
   */
  duration?: number;
  /**
   * The entering timeout for the transition, in milliseconds.
   */
  enteringTimeout?: number;
  /**
   * Additional transition styles
   */
  additionalTransitionStyles?: TransitionStyles;
  /**
   * If true set transition-property css property to true
   */
  transitionAll?: boolean;
  /**
   * These props are set for Transition component
   */
  transitionProps?: Partial<ComponentProps<typeof Transition>>;
}

const defaultDuration = 500;

/**
 * Default transition styles for making fade transition
 */
const transitionStyles: TransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
};

const Fade: FC<DynamicComponentProps<FadeProps>> = ({
  tagName,
  in: inProp,
  duration = defaultDuration,
  additionalTransitionStyles,
  transitionAll,
  style: defaultStyle,
  enteringTimeout = 0,
  transitionProps,
  ...restProps
}) => {
  return (
    /// See https://reactcommunity.org/react-transition-group/transition to learn how react-transition-group package works
    <Transition
      in={inProp}
      timeout={{ enter: enteringTimeout, exit: duration, appear: duration }}
      mountOnEnter
      unmountOnExit
      {...(transitionProps || {})}
    >
      {(state) =>
        createElement(tagName, {
          ...restProps,
          style: {
            /// Add external default styles
            ...defaultStyle,
            /// Add transition-deuration CSS property
            transitionDuration: `${duration}ms`,
            /// If transitionAll prop is true, transition-property CSS property set to all
            transitionProperty: transitionAll ? "all" : "opacity",
            /// Default opacity style
            opacity: 0,
            /// Add default transition styles
            ...transitionStyles[state],
            /// Add additional transition styles from external
            ...(additionalTransitionStyles?.[state] || {}),
          },
        })
      }
    </Transition>
  );
};

export default Fade;
