import { IMAGES } from "@/constants";
import { FC, HTMLAttributes } from "react";

interface SplashScreenWrapperProps extends HTMLAttributes<HTMLDivElement> {}

const SplashScreenWrapper: FC<SplashScreenWrapperProps> = ({ children }) => (
  <div
    className="grow bg-center bg-cover bg-no-repeat"
    style={{ backgroundImage: `url("${IMAGES.HOME_SCREEN_STADIUM_LARGE}")` }}
  >
    {children}
  </div>
);

export default SplashScreenWrapper;
