import { GemCardProps } from "@/components/common/balance-card";
import GamGemCard from "@/components/common/balance-card/game-gem-card";
import PrediGemCard from "@/components/common/balance-card/predi";
import classNames from "classnames";
import { FC } from "react";
import UsdGemCard from "../balance-card/usd";

interface GemsProps {
  cardProps?: Omit<GemCardProps, "value" | "icon">;
}

const Gems: FC<GemsProps> = ({ cardProps = {} }) => {
  const { className: cardClassName, ...restCardProps } = cardProps;
  const sharedCardProps = {
    className: cardClassName,
    ...restCardProps,
  };
  return (
    <div className="flex justify-center gap-x-2 mb-0.5 animate-from-top">
      <UsdGemCard {...sharedCardProps} />
      <GamGemCard {...sharedCardProps} />
      <PrediGemCard
        {...sharedCardProps}
        className={classNames("basis-full", sharedCardProps)}
      />
    </div>
  );
};

export default Gems;
