import { type SVGProps, useRef } from "react";

const CopyIcon2 = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M16 13.1V17.3C16 20.8 14.6 22.2 11.1 22.2H6.9C3.4 22.2 2 20.8 2 17.3V13.1C2 9.59995 3.4 8.19995 6.9 8.19995H11.1C14.6 8.19995 16 9.59995 16 13.1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 7.09995V11.3C22 14.8 20.6 16.2 17.1 16.2H16V13.1C16 9.59995 14.6 8.19995 11.1 8.19995H8V7.09995C8 3.59995 9.4 2.19995 12.9 2.19995H17.1C20.6 2.19995 22 3.59995 22 7.09995Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon2;
