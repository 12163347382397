import classNames from "classnames";
import { HTMLAttributes, ReactNode } from "react";

type TabValueType = string | number;

export interface TabItem<T = TabValueType> {
  value: T;
  title: string | ReactNode;
  content?: ReactNode;
  className?: string;
}

interface TabProps<T = TabValueType>
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  tabs?: TabItem<T>[];
  onValueChange?: (v: T) => void;
  value?: T;
  fixed?: boolean;
  activeClassName?: string;
  itemClassNames?: string;
  wrapperClassName?: string;
}

function Tab<T = TabValueType>({
  tabs = [],
  onValueChange = () => {},
  className,
  activeClassName,
  itemClassNames,
  value,
  fixed = false,
  wrapperClassName,
  ...restProps
}: TabProps<T>) {
  return (
    <>
      <div
        className={classNames(
          "w-full",
          wrapperClassName,
          fixed && "fixed top-0 start-0 end-0 z-[200]"
        )}
      >
        <div
          className={classNames("w-full bg-blue-850 rounded-full", className)}
          {...restProps}
        >
          <div className="max-w-2xl mx-auto flex items-center">
            {tabs.map(({ title, value: v, className: cn }) => (
              <div
                key={v as any}
                className={classNames(
                  "grow text-center text-sm text-blue-300 cursor-pointer py-4 transition-[background-color] animate-fade-in rounded-full ",
                  {
                    "bg-gradient-to-b from-blue-200 to-blue-400 text-blue-850 box-shadow-inner-0--4 shadow-blue-500":
                      value === v,
                  },
                  value === v && activeClassName,
                  itemClassNames,
                  cn
                )}
                onClick={() => onValueChange(v)}
                role="button"
              >
                {title}
              </div>
            ))}
          </div>
        </div>
      </div>
      {tabs.find(({ value: v }) => v === value)?.content}
    </>
  );
}

export default Tab;
