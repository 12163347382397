import { type SVGProps, useRef } from "react";

const ExportIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1.625rem"
      height="1.625rem"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="vuesax/linear/export">
        <g id="export">
          <g>
            <path
              id="Vector"
              d="M10.3119 6.68346L12.8796 4.11575L15.4473 6.68346"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.8796 14.3866V4.18594"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            id="Vector_3"
            d="M4.97595 12.2C4.97595 16.6333 7.98499 20.2241 13 20.2241C18.0151 20.2241 21.0241 16.6333 21.0241 12.2"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ExportIcon;
