import { create } from "zustand";
import { CacheState, createCacheState } from "./cache-store";
import { UserState, createUserState } from "./user";

interface StoreType extends CacheState, UserState {}

export const useData = create<StoreType>((...a) => ({
  ...createCacheState(...a),
  ...createUserState(...a),
}));
