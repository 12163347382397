import { PredictionState } from "@/global/types";
import { Dispatch, SetStateAction, lazy } from "react";

export interface MatchesContentRef {
  setPrediction: Dispatch<SetStateAction<PredictionState[]>>;
}

const MatchesContent = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "matches-content" */
      "@/components/pages/matches/content"
    )
);

export default MatchesContent;
