import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import paths from "@/navigation/paths";
import { ReactNode, createElement } from "react";
import { FormattedMessage } from "react-intl";

interface NavItemType {
  to: string;
  title: string | ReactNode;
  icon: JSX.Element;
  isFirstPage?: boolean;
}

const navList: NavItemType[] = [
  {
    icon: createElement(Image, {
      src: IMAGES.SHOP_ICON,
      alt: "shop",
    }),
    to: paths.shop,
    title: createElement(FormattedMessage, { id: "shop" }),
  },
  {
    icon: createElement(Image, {
      src: IMAGES.QUEST_ICON,
      alt: "quest",
    }),
    to: paths.quest,
    title: createElement(FormattedMessage, { id: "quest" }),
  },
  {
    icon: createElement(Image, {
      src: IMAGES.HOME_ICON,
      alt: "home",
    }),
    to: paths.home,
    title: createElement(FormattedMessage, { id: "home" }),
    isFirstPage: true,
  },
  {
    icon: createElement(Image, {
      src: IMAGES.PREDICT_PRO_ICON,
      alt: "predict-pro",
    }),
    to: paths.predictPro.index,
    title: createElement(FormattedMessage, { id: "predict-pro" }),
  },
  {
    icon: createElement(Image, {
      src: IMAGES.WALLET_ICON,
      alt: "wallet",
    }),
    to: paths.wallet.index,
    title: createElement(FormattedMessage, { id: "wallet" }),
  },
];

export default navList;
