import { IMAGES } from "@/constants";
import { FC, useMemo } from "react";
import LevelReward from "./reward";
import classNames from "classnames";
import { Column } from "./column";
import { FootSteps } from "./foot-steps";
import { getSortedSteps } from "./utils";
import Image from "@/components/common/image";
import { LevelResponseType } from "@/helpers/api/types/api-response";
import useCurrentStep from "./context/use-current-step";
import { CurrentStepStatus } from "@/global/enum";

interface LevelProps {
  level: LevelResponseType;
}

const Level: FC<LevelProps> = ({ level }) => {
  const { steps, bundle } = level;

  const { activeStepNumber, currentStep } = useCurrentStep();

  const { leftColumnSteps, rightColumnSteps, minStep, maxStep } = useMemo(
    () => getSortedSteps(steps),
    [steps]
  );

  const isGateClose = activeStepNumber < minStep;
  const isRewardClaimable =
    activeStepNumber === maxStep &&
    currentStep.currentStatus === CurrentStepStatus.WON;
  const isRewardClaimed = activeStepNumber > maxStep;

  return (
    <div>
      {bundle && (
        <LevelReward
          bundle={bundle}
          showReward
          isRewardClaimable={isRewardClaimable}
          isRewardClaimed={isRewardClaimed}
        />
      )}
      <div className="grid grid-cols-3">
        <Column steps={leftColumnSteps} />
        <FootSteps steps={steps} />
        <Column steps={rightColumnSteps} className="pb-28" />
      </div>
      <div className="px-8 mt-8 ">
        <div className="aspect-[278/311]">
          <Image
            className={classNames(
              {
                "opacity-50": isGateClose,
              },
              "h-full"
            )}
            src={IMAGES.GATE}
            alt="gate"
          />
        </div>
      </div>
    </div>
  );
};

export default Level;
