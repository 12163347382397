import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface ActionButtonProps {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({ icon, label, onClick }) => {
  return (
    <div
      className="bg-neutral-50 text-blue-750 flex justify-center items-center gap-2 text-lg font-bold rounded-full px-6 py-3 cursor-pointer transition-transform active:translate-y-0.5"
      onClick={onClick}
    >
      {icon}
      <FormattedMessage id={label} />
    </div>
  );
};

export default ActionButton;
