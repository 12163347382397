import classNames from "classnames";
import { FC, HTMLAttributes, ReactNode } from "react";

export interface UserMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  label?: string | ReactNode;
}

const UserMenuItem: FC<UserMenuItemProps> = ({
  label,
  className,
  children,
  ...restProps
}) => (
  <div className={classNames(className, "user-menu-item")} {...restProps}>
    {!!label && (
      <div className="bg-neutral-50 w-8 h-8 shrink-0 flex items-center justify-center text-blue-800 rounded font-bold">
        {label}
      </div>
    )}
    {children}
  </div>
);

export default UserMenuItem;
