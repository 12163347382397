import { FC, HTMLAttributes, useState } from "react";
import Image, { ImageProps } from "../image";
import { FormattedNumber } from "react-intl";
import classNames from "classnames";
import { PlusIcon } from "@/components/icons";
import Dialog from "../dialog";
import Purchase from "./purchase";

interface CardProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ImageProps, "src" | "alt"> {
  productId: string;
  inventory?: number;
  disabled?: boolean;
  purchaseIfThereIsNoInventory?: boolean;
  onPurchase?: () => void;
}

const Card: FC<CardProps> = ({
  className,
  src,
  alt,
  disabled = false,
  purchaseIfThereIsNoInventory = false,
  inventory,
  onClick = () => {},
  onPurchase = () => {},
  productId,
  children,
  ...restProps
}) => {
  const [openPurchaseModal, setOpenPurchaseModal] = useState<boolean>(false);
  const closePurchaseModal = () => setOpenPurchaseModal(false);
  return (
    <>
      {purchaseIfThereIsNoInventory && (
        <Dialog
          open={openPurchaseModal}
          onClose={closePurchaseModal}
          seedClassName="p-4"
        >
          <Purchase
            id={productId}
            onPurchase={() => {
              closePurchaseModal();
              onPurchase();
            }}
          />
        </Dialog>
      )}
      <div
        className={classNames(className, "shrink-0 relative")}
        {...restProps}
        onClick={(e) => {
          if (purchaseIfThereIsNoInventory && inventory === 0)
            setOpenPurchaseModal(true);
          else if (!disabled) onClick(e);
        }}
      >
        {children}
        <Image
          className={classNames("w-full", disabled && "grayscale")}
          src={src}
          alt={alt}
        />
        {typeof inventory === "number" && (
          <div
            className={classNames(
              "absolute -end-1 -bottom-0.5 z-1 w-5 h-5 box-shadow-inner-0--3 rounded-full bg-gradient-to-b text-[0.5rem] font-bold flex items-center justify-center border",
              !inventory
                ? "shadow-green-600 from-red-100 to-green-500 border-green-500"
                : "text-stroke-sm-red-900 shadow-red-850 from-red-500 to-red-700 border-red-900"
            )}
          >
            {!inventory ? (
              <PlusIcon width="0.5rem" height="0.5rem" />
            ) : (
              <FormattedNumber value={inventory} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Card;
