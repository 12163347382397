import Page from "@/components/common/page";
import JourneysContent from "@/components/pages/journeys/content";
import { FC } from "react";

const JourneysPage: FC = () => (
  <Page>
    <JourneysContent />
  </Page>
);

export default JourneysPage;
