import { useEffect, FC, useState, HTMLAttributes } from "react";
import { FormattedNumber } from "react-intl";

interface CountDownProps extends HTMLAttributes<HTMLDivElement> {
  expireAt: string | number;
  showHours?: boolean;
  onExpire?: () => void;
  onTimerChange?: (time: number) => void;
}

const calculateSeconds = (expireAt: string | number) =>
  (new Date(expireAt).getTime() - new Date().getTime()) / 1000;

const formattedNumberCommonProps = { minimumIntegerDigits: 2 };

const CountDown: FC<CountDownProps> = ({
  expireAt,
  showHours = false,
  onExpire = () => {},
  onTimerChange = () => {},
  ...restProps
}) => {
  const [time, setTime] = useState<number>(calculateSeconds(expireAt));

  const hours = Math.floor((time % (3600 * 24)) / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  useEffect(() => {
    const intervalKey = setInterval(() => {
      const remainingTime = calculateSeconds(expireAt);
      setTime(remainingTime);
      if (remainingTime <= 0) {
        onExpire();
        clearInterval(intervalKey);
      }
    }, 1000);
    return () => {
      clearInterval(intervalKey);
    };
  }, [expireAt]);

  useEffect(() => {
    onTimerChange(time);
  }, [time]);

  return (
    <div {...restProps}>
      {time <= 0 ? (
        <>{!!showHours && "--:"}--:--</>
      ) : (
        <>
          {!!showHours && (
            <>
              <FormattedNumber value={hours} {...formattedNumberCommonProps} />:
            </>
          )}
          <FormattedNumber value={minutes} {...formattedNumberCommonProps} />:
          <FormattedNumber value={seconds} {...formattedNumberCommonProps} />
        </>
      )}
    </div>
  );
};

export default CountDown;
