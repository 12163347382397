import { StateCreator } from "zustand";

interface TokensType {
  accessToken: string;
  refreshToken: string;
}

export interface OptionalRefreshTokensType
  extends Omit<TokensType, "refreshToken"> {
  refreshToken?: TokensType["refreshToken"];
}

export interface TokenState {
  tokens: Record<string, TokensType>;
  setTokens: (userId: string | number, l: OptionalRefreshTokensType) => void;
  removeTokens: (userId: string | number) => void;
  getTokens: (userId: string | number) => TokensType;
}

export const createTokenState: StateCreator<TokenState> = (set, get) => ({
  tokens: {},
  setTokens: (userId, tokens) =>
    set({
      tokens: {
        ...get().tokens,
        [userId]: {
          accessToken: tokens.accessToken,
          refreshToken: (tokens.refreshToken ||
            get().tokens[userId].refreshToken)!,
        },
      },
    }),
  removeTokens: (userId) => {
    const tokens = get().tokens;
    delete tokens[userId];
    set({ tokens });
  },
  getTokens: (userId) => get().tokens[userId],
});
