import { FC, useState } from "react";
import classNames from "classnames";
import { ItemProps } from "./type";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "../cn";
import Price from "../common/price";
import BundleImage from "../common/bundle-image";
import PurchaseDialog from "../common/purchase-dialog";

const GridItem: FC<ItemProps> = ({ data, color, isFullWidth = false }) => {
  const [openPurchaseModal, setOpenPurchaseModal] = useState<boolean>(false);

  const { asset, price } = data;

  const { sliderItemWrapperClassName } =
    BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];

  const { sliderItemPriceWrapperClassName } =
    BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];

  return (
    <>
      <PurchaseDialog
        open={openPurchaseModal}
        onClose={() => setOpenPurchaseModal(false)}
        data={data}
        color={color}
        view="slider-item"
        imageGridClassName="!gap-x-1.5 !gap-y-3"
        imageWrapperClassName="!w-12 !text-xs !px-1 !pb-0.5 [&>div]:!pt-1"
      />
      <div
        onClick={() => setOpenPurchaseModal(true)}
        className={classNames(
          isFullWidth ? "w-full" : "w-[90%]",
          "shrink-0 box-shadow-0-4 rounded-3xl bg-gradient-to-b p-4 pb-3 flex flex-col",
          sliderItemWrapperClassName
        )}
      >
        <div className="pt-2 pb-7 w-full grow">
          <BundleImage data={data} color={color} view="slider-item" />
        </div>
        <div
          className={classNames(
            sliderItemPriceWrapperClassName,
            "p-2 border rounded-lg flex items-center"
          )}
        >
          {/* <div></div> */}
          <div className="grow w-full">
            <Price asset={asset} price={price} color={color} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GridItem;
