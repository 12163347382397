import { FC } from "react";
import { CalendarIcon, ClockIcon } from "@/components/icons";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { TransactionStatus, TransactionType } from "@/global/enum";
import classNames from "classnames";
import { dayjs } from "@/utils/dayjs";

interface TransactionCardProps {
  status?: TransactionStatus;
  type: TransactionType;
  createdAt: string;
  amount: number | string;
}

const STATUS_CLASS_NAME: Record<TransactionStatus, string> = {
  [TransactionStatus.Completed]: "bg-green-300",
  [TransactionStatus.Pending]: "bg-blue-200",
  [TransactionStatus.Rejected]: "bg-red-300",
};

const TransactionCard: FC<TransactionCardProps> = ({
  type,
  status,
  createdAt,
  amount,
}) => {
  const transactionType =
    type === TransactionType.Transaction
      ? Number(amount) > 0
        ? "deposit"
        : "withdraw"
      : type;

  return (
    <div className="bg-blue-900 rounded-lg p-4 flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg text-blue-50 capitalize">
            <FormattedMessage id={`wallet-type-${transactionType}`} />
          </p>
          <div className="text-blue-50 flex gap-2 text-xs font-normal">
            <div className="flex items-center gap-0.5 ">
              <ClockIcon className="opacity-50" />
              {dayjs(createdAt).format("HH:mm")}
            </div>
            <div className="flex items-center gap-0.5">
              <CalendarIcon />
              <p className="flex gap-1">
                {dayjs(createdAt).format("DD MMMM YYYY")}
              </p>
            </div>
          </div>
        </div>
        <p
          className={classNames("font-bold text-lg text-green-300", {
            "text-red-300":
              transactionType === "withdraw" || Number(amount) < 0,
          })}
        >
          {transactionType === "withdraw" || Number(amount) < 0 ? "-" : "+"}
          <FormattedNumber
            style="currency"
            currency="USD"
            value={Math.abs(+amount)}
          />
        </p>
      </div>
      {status && (
        <div
          className={classNames(
            "px-4 py-2 rounded-full text-blue-950 text-xs font-normal self-start",
            STATUS_CLASS_NAME[status]
          )}
        >
          <FormattedMessage id={status} />
        </div>
      )}
    </div>
  );
};

export default TransactionCard;
