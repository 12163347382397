import { FC } from "react";

import Balance from "./balance";
import DollarLog from "./dollar-log";

const Transfer: FC = () => {
  return (
    <div className="flex-1 flex flex-col w-full max-w-2xl mx-auto pb-6">
      <Balance />
      <div className="px-4">
        <DollarLog />
      </div>
    </div>
  );
};

export default Transfer;
