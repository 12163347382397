import { FC, PropsWithChildren, ReactNode } from "react";

interface CardProps extends PropsWithChildren {
  title: string;
  value?: number;
  icon: ReactNode;
  subIcon?: ReactNode;
}

const Card: FC<CardProps> = ({ title, value, icon, subIcon, children }) => {
  return (
    <div className="p-2 flex flex-1 flex-col gap-2 bg-gradient-to-b from-blue-500 to-blue-750 rounded-xl box-shadow-0-2 shadow-blue-900">
      <div className="flex justify-center items-center gap-1">
        {icon}
        <p className="text-neutral-50 text-sm font-normal">{title}</p>
      </div>
      {children ? (
        children
      ) : (
        <div className="bg-blue-850 rounded-lg border border-blue-900 flex justify-between items-center ps-5">
          <div className="flex-1 text-center">
            <p className="font-bold text-base text-shadow-0-2-blue-900">
              {value}
            </p>
          </div>
          <div className="p-2 border-s border-blue-900">{subIcon}</div>
        </div>
      )}
    </div>
  );
};

export default Card;
