import { StepResponseType } from "@/helpers/api/types/api-response";
import { isEven } from "@/utils/number";

export const getSortedSteps = (steps: StepResponseType[]) => {
  const sortedSteps = steps.sort((a, b) => a.displayOrder - b.displayOrder);

  const leftColumnSteps = sortedSteps.filter((_, i) => isEven(i));
  const rightColumnSteps = sortedSteps.filter((_, i) => !isEven(i));
  const minStep = leftColumnSteps[0].displayOrder;
  const maxStep = sortedSteps[sortedSteps.length - 1].displayOrder;

  return {
    leftColumnSteps,
    rightColumnSteps,
    minStep,
    maxStep,
  };
};
