import classNames from "classnames";
import { FC, ReactNode, isValidElement } from "react";

interface HeaderProps {
  icon?: ReactNode;
  title: ReactNode | string;
  children?: ReactNode;
}

interface TitleSectionProps {
  title: ReactNode | string;
  className?: string;
  loading?: boolean;
}

export const TitleSection: FC<TitleSectionProps> = ({
  title,
  className,
  loading,
}) =>
  loading ? (
    <div className="h-6 w-32 skeleton rounded" />
  ) : (
    <div className="bg-blue-850 rounded text-center px-14 py-0.5">
      <p className={classNames(className, "text-blue-50 font-normal text-sm")}>
        {title}
      </p>
    </div>
  );

const Header: FC<HeaderProps> = ({ icon, title, children }) => {
  return (
    <header className="fixed top-0 start-0 w-full bg-blue-750 flex flex-col items-center z-50">
      <div className="flex justify-between items-center w-full max-w-2xl px-6 py-4 animate-grow-up">
        {icon && <div className="flex-none">{icon}</div>}
        <div className="flex-1 flex justify-center">
          {isValidElement(title) ? title : <TitleSection title={title} />}
        </div>
      </div>
      {children && children}
    </header>
  );
};

export default Header;
