import { StateCreator } from "zustand";
import { LocaleId } from "@/i18n/type";

export type LanguageState = {
  language?: LocaleId;
  setLanguage: (l: LocaleId) => void;
};

export const createLanguageState: StateCreator<LanguageState> = (set) => ({
  language: undefined,
  setLanguage: (language) =>
    set({
      language,
    }),
});
