import BorderGradient from "@/components/common/border-gradient";
import classNames from "classnames";
import { FC, HTMLAttributes, ReactNode } from "react";

interface DescriptionCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title: string | ReactNode;
  description: string | ReactNode;
}

const DescriptionCard: FC<DescriptionCardProps> = ({
  className,
  title,
  description,
  ...restProps
}) => (
  <div
    className={classNames(
      className,
      "bg-blue-900/[0.3] text-blue-50 text-sm text-center rounded-lg"
    )}
    {...restProps}
  >
    <BorderGradient
      className="bg-gradient-to-b from-blue-500 to-blue-750 rounded-lg box-shadow-inner-0-10 shadow-neutral-0/[0.08] p-2 text-xl font-bold text-stroke-md-blue-850 text-shadow-0-1-blue-900 relative overflow-hidden after:absolute after:inset-0 after:box-shadow-inner-0-1-md after:shadow-blue-800"
      outerClassName="p-[1px] rounded-lg bg-gradient-to-b from-blue-750 to-blue-700"
    >
      {title}
    </BorderGradient>
    <div className="px-2 py-3">{description}</div>
  </div>
);

export default DescriptionCard;
