import { isEven } from "@/utils/number";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import { StepResponseType } from "@/helpers/api/types/api-response";
import useCurrentStep from "./context/use-current-step";
import { CurrentStepStatus } from "@/global/enum";

interface FootStepsProps extends HTMLAttributes<HTMLDivElement> {
  steps: StepResponseType[];
}

export const FootSteps: FC<FootStepsProps> = ({
  className,
  steps,
  ...restProps
}) => {
  const { activeStepNumber, currentStep } = useCurrentStep();
  return (
    <div
      className={classNames(
        "flex flex-col-reverse justify-center items-center",
        className
      )}
      {...restProps}
    >
      {steps.map((step, index) => (
        <div
          key={step.id}
          className="flex items-center justify-center h-28 pb-12"
        >
          <Image
            src={IMAGES.FOOT_STEP}
            alt="foot-step"
            className={classNames(
              "opacity-10 h-20",
              isEven(index) ? "ltr:rotate-90" : "rtl:rotate-90",
              {
                "opacity-100":
                  step.displayOrder < activeStepNumber ||
                  (step.displayOrder === activeStepNumber &&
                    currentStep.currentStatus === CurrentStepStatus.WON),
              }
            )}
          />
        </div>
      ))}
    </div>
  );
};
