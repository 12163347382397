import Button from "@/components/common/button";
import Dialog, { DialogProps } from "@/components/common/dialog";
import Image from "@/components/common/image";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PurchaseResultModalData } from "./use-get-purchase-result-data";

interface PurchaseResultDialog extends Omit<DialogProps, "open"> {
  data?: PurchaseResultModalData;
}

const PurchaseResultDialog: FC<PurchaseResultDialog> = ({
  data,
  ...restProps
}) => {
  const { image, title, description, buttonText, onClick } = data || {};

  return (
    <Dialog
      {...restProps}
      open={!!data}
      seedClassName="flex flex-col items-center gap-4 px-4 pb-4"
    >
      <Image src={image} alt="filled-check" className="h-32" />
      <p>{title}</p>
      <p className="text-center">{description}</p>
      <Button
        color="secondary"
        fullWidth
        onClick={onClick ?? restProps.onClose}
      >
        <FormattedMessage id={buttonText ?? "ok"} />
      </Button>
    </Dialog>
  );
};

export default PurchaseResultDialog;
