import { StateCreator } from 'zustand';

export type CacheState = {
  cacheStore: Record<string, any>;
  setCache: (key: string, value: any) => void;
};

export const createCacheState: StateCreator<CacheState> = (set, get) => ({
  cacheStore: {},
  setCache: (key, value) => set({ cacheStore: { ...get().cacheStore, [key]: value } }),
});
