import { useLayoutEffect } from "react";

export const lockScroll = (el: HTMLElement): void =>
  el.classList.add("overflow-hidden");

export const unlockScroll = (el: HTMLElement): void =>
  el.classList.remove("overflow-hidden");

/**
 * Lock the browser scroll behavior
 */
const useLockBodyScroll = (): void => {
  useLayoutEffect(() => {
    // Get body
    const body = window.document.body;
    // Prevent scrolling on mount
    lockScroll(body);
    // Re-enable scrolling when component unmounts
    return () => {
      unlockScroll(body);
    };
  }, []);
};

export default useLockBodyScroll;
