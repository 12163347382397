import useApi from "@/helpers/api/hooks/use-api";
import { FC, useEffect, useState } from "react";
import FetchError from "../fetch-error";
import Spinner from "../spinner";
import Button from "../button";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { TapPurchaseDataType } from "@/helpers/api/types/api-response";
import { showErrorMessage } from "@/utils/string";
import useBalance from "@/hooks/use-balance";
import GiveawayContent from "../giveaway-content";

interface GiveawayProps {
  id?: string;
  onClose: () => void;
}

const Giveaway: FC<GiveawayProps> = ({ id, onClose }) => {
  const { getBundlePurchase, claimReward } = useApi();
  const { updateBalances } = useBalance();
  const [data, setData] = useState<TapPurchaseDataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const fetchBundlePurchase = () => {
    setData(undefined);
    setError(undefined);
    setLoading(false);
    getBundlePurchase(id!).then(setData).catch(setError);
  };

  const claim = () => {
    setLoading(true);
    claimReward(data!.purchase.id)
      .then(() => {
        updateBalances();
        onClose();
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) fetchBundlePurchase();
  }, [id]);

  if (error)
    return (
      <FetchError
        className="py-14"
        message={error.message}
        onRetry={fetchBundlePurchase}
      >
        <Button color="secondary" onClick={onClose} size="sm">
          <FormattedMessage id="close" />
        </Button>
      </FetchError>
    );
  if (!data)
    return (
      <div className="flex py-40 items-center justify-center">
        <Spinner />
      </div>
    );
  return (
    <GiveawayContent
      bundle={data.purchase.bundle}
      loading={loading}
      onClaim={claim}
    />
  );
};

export default Giveaway;
