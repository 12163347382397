import { ERROR_CODES, IMAGES } from "@/constants";
import { AssetUnit } from "@/global/enum";
import paths from "@/navigation/paths";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export interface PurchaseResultModalData {
  title: ReactNode;
  description: ReactNode;
  image: string;
  buttonText?: string;
  onClick?: () => void;
}

interface PurchaseResultData {
  insufficientAssetTitle: string;
  availableCapacityPerUser: number;
  assetTitle?: string;
}

const useGetPurchaseResultData = ({
  // availableCapacityPerUser,
  insufficientAssetTitle,
  assetTitle,
}: PurchaseResultData) => {
  const navigate = useNavigate();
  const getModalData = (error: any): PurchaseResultModalData | undefined => {
    const modalData: Partial<PurchaseResultModalData> = {};
    if (error?.errorCode === ERROR_CODES.INSUFFICIENT_BALANCE) {
      switch (insufficientAssetTitle.toLowerCase()) {
        case AssetUnit.USD:
          modalData.title = (
            <FormattedMessage
              id="insufficient-balance"
              values={{ bundle: <FormattedMessage id="dollar" /> }}
            />
          );
          modalData.description = (
            <FormattedMessage
              id="insufficient-balance-desc"
              values={{ bundle: <FormattedMessage id="dollar" /> }}
            />
          );
          modalData.image = IMAGES.INSUFFICIENT_DOLLAR;
          modalData.buttonText = "charge-wallet";
          modalData.onClick = () =>
            navigate(paths.wallet.index, { replace: true });
          break;
        case AssetUnit.GEM:
          modalData.title = (
            <FormattedMessage
              id="insufficient-balance"
              values={{ bundle: insufficientAssetTitle }}
            />
          );
          modalData.description = (
            <FormattedMessage
              id="insufficient-balance-desc"
              values={{ bundle: insufficientAssetTitle }}
            />
          );
          modalData.image = IMAGES.INSUFFICIENT_GEM;
          break;
        default:
          modalData.title = (
            <FormattedMessage
              id="insufficient-balance"
              values={{ bundle: insufficientAssetTitle }}
            />
          );
          modalData.description = (
            <FormattedMessage
              id="insufficient-balance-desc"
              values={{ bundle: insufficientAssetTitle }}
            />
          );
          modalData.image = IMAGES.INSUFFICIENT_TPPOG;
      }
    } else if (error?.errorCode === ERROR_CODES.MAX_BALANCE_REACHED) {
      modalData.title = (
        <FormattedMessage
          id="maximum-balance"
          values={{ bundle: assetTitle }}
        />
      );
      modalData.description = (
        <FormattedMessage
          id="maximum-balance-desc"
          values={{ bundle: assetTitle }}
        />
      );
      modalData.image = IMAGES.INSUFFICIENT_PREDI;
    }
    // else if (error?.errorCode === "") {
    //   modalData.title = <FormattedMessage id="bundle-limitation" />;
    //   modalData.description = (
    //     <FormattedMessage
    //       id="bundle-limitation-desc"
    //       values={{ amount: availableCapacityPerUser }}
    //     />
    //   );
    //   modalData.image = IMAGES.BUNDLE_LIMITATION;
    // }
    return Object.keys(modalData).length
      ? (modalData as PurchaseResultModalData)
      : undefined;
  };

  const successfulResult: PurchaseResultModalData = {
    title: <FormattedMessage id="successful-purchase" />,
    description: <FormattedMessage id="successful-purchase-desc" />,
    image: IMAGES.SUCCESSFUL_PURCHASE,
  };
  return {
    successfulResult,
    getModalData,
  };
};

export default useGetPurchaseResultData;
