import Page from "@/components/common/page";
import WalletContent from "@/components/pages/wallet/content";
import { FC } from "react";

const WalletPage: FC = () => (
  <Page>
    <WalletContent />
  </Page>
);

export default WalletPage;
