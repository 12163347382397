import classNames from "classnames";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

const HarmReductionContent: FC = () => (
  <div className="text-lg">
    {Array(3)
      .fill(null)
      .map((_, i) => (
        <p key={i} className={classNames(i && "pt-4")}>
          <FormattedMessage id={`harm-reduction-${i + 1}`} />
        </p>
      ))}
  </div>
);

export default HarmReductionContent;
