import { StateCreator } from "zustand";
import {
  AccountInfoType,
  BalanceResponseType,
  TapDataType,
  UserEntityType,
} from "@/helpers/api/types/api-response";
import { AssetUnit, WalletStatuses } from "@/global/enum";

export type BalanceMap = Record<AssetUnit, BalanceResponseType>;

export interface UserState {
  user?: UserEntityType;
  tapData?: TapDataType;
  setTapData: (d: Partial<TapDataType>) => void;
  increaseTapAmount: (count: number) => void;
  setUser: (d: Partial<UserEntityType>) => void;
  isSplashScreenTimeoutReached: boolean;
  setIsSplashScreenTimeoutReached: (v: boolean) => void;
  isCheckingProof: boolean;
  setIsCheckingProof: (v: boolean) => void;
  walletInfo?: AccountInfoType;
  walletStatus?: WalletStatuses;
  balances?: BalanceMap;
  isSessionExpired: boolean;
  setIsSessionExpired: (v: boolean) => void;
  setBalances: (b: BalanceMap) => void;
  setSpecificBalance: (
    asset: AssetUnit,
    d: Partial<BalanceResponseType>
  ) => void;
  changeSpecificBalanceAmount: (
    asset: AssetUnit,
    d: BalanceResponseType["amount"]
  ) => void;
  connectWalletInfo: (v: AccountInfoType) => void;
  disconnectWalletInfo: () => void;
}

export const createUserState: StateCreator<UserState> = (set, get) => ({
  user: undefined,
  tapData: undefined,
  balances: undefined,
  isSplashScreenTimeoutReached: false,
  isCheckingProof: false,
  walletInfo: undefined,
  walletStatus: undefined,
  isSessionExpired: false,
  setIsSessionExpired: (isSessionExpired) => set({ isSessionExpired }),
  increaseTapAmount: (count) => {
    const { tapData, changeSpecificBalanceAmount } = get();
    const { amount, dailyTppogRemain, power, remain } = tapData!;
    changeSpecificBalanceAmount(AssetUnit.TPPOG, count * power);
    set({
      tapData: {
        ...tapData!,
        amount: amount + count,
        dailyTppogRemain: dailyTppogRemain - count * power,
        remain: remain - count,
      },
    });
  },
  setTapData: (d) => set({ tapData: { ...get().tapData!, ...d } }),
  setUser: (d) =>
    set({
      user: { ...get().user!, ...d },
    }),
  setBalances: (balances) => set({ balances }),
  setSpecificBalance: (asset, d) => {
    const { balances } = get();
    balances![asset] = { ...balances![asset], ...d };
    set({ balances });
  },
  changeSpecificBalanceAmount: (asset, d) => {
    const { balances } = get();
    const { amount } = balances![asset];
    balances![asset] = { ...balances![asset], amount: amount + d };
    set({ balances });
  },
  connectWalletInfo: (walletInfo) =>
    set({ walletInfo, walletStatus: WalletStatuses.CONNECTED }),
  disconnectWalletInfo: () =>
    set({ walletInfo: undefined, walletStatus: WalletStatuses.DISCONNECTED }),
  setIsCheckingProof: (isCheckingProof) =>
    set({
      isCheckingProof,
    }),
  setIsSplashScreenTimeoutReached: (isSplashScreenTimeoutReached) =>
    set({
      isSplashScreenTimeoutReached,
    }),
});
