import { type SVGProps, useRef } from "react";

const FilledArrowRightIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M8.17999 6.7553V12.9853V18.5953C8.17999 19.5553 9.33999 20.0353 10.02 19.3553L15.2 14.1753C16.03 13.3453 16.03 11.9953 15.2 11.1653L13.23 9.1953L10.02 5.9853C9.33999 5.3153 8.17999 5.7953 8.17999 6.7553Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilledArrowRightIcon;
