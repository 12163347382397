import { type SVGProps, useRef } from "react";

const FilledTriangleIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M5.3166 1.32417C6.1036 0.097365 7.8964 0.0973632 8.6834 1.32417L13.6845 9.12011C14.5384 10.4512 13.5826 12.2 12.0011 12.2H1.9989C0.417433 12.2 -0.53841 10.4512 0.315501 9.12011L5.3166 1.32417Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilledTriangleIcon;
