import { type SVGProps, useRef } from "react";

const LampIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M12.0001 8.09033L10.9301 9.95033C10.6901 10.3603 10.8901 10.7003 11.3601 10.7003H12.6301C13.1101 10.7003 13.3001 11.0403 13.0601 11.4503L12.0001 13.3103"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30035 18.2404V17.0804C6.00035 15.6904 4.11035 12.9804 4.11035 10.1004C4.11035 5.15037 8.66035 1.27037 13.8004 2.39037C16.0604 2.89037 18.0404 4.39037 19.0704 6.46037C21.1604 10.6604 18.9604 15.1204 15.7304 17.0704V18.2304C15.7304 18.5204 15.8404 19.1904 14.7704 19.1904H9.26035C8.16035 19.2004 8.30035 18.7704 8.30035 18.2404Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 22.2004C10.79 21.5504 13.21 21.5504 15.5 22.2004"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LampIcon;
