import { lazy, type ComponentType } from "react";
import paths from "./paths";
import HomePage from "@/pages/home";
import QuestPage from "@/pages/quest";
import WalletPage from "@/pages/wallet";
import PredictProPage from "@/pages/predict-pro";
import ShopPage from "@/pages/shop";
import DepositPage from "@/pages/wallet/deposit";
import WithdrawPage from "@/pages/wallet/withdraw";
import JourneysPage from "@/pages/journeys";
import PredictProMatchesPage from "@/pages/predict-pro/matches";
import JourneysMatchesPage from "@/pages/journeys/matches";

interface Route {
  Component: ComponentType;
  path: string;
  title?: string;
  sub?: Route[];
}

const PredictProTimeIsUpPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "predict-pro-time-is-up" */
      "@/pages/predict-pro/time-is-up"
    )
);

const PredictProSubmitPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "predict-pro-submit" */
      "@/pages/predict-pro/submit"
    )
);

const JourneysSubmitPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "journeys-submit" */
      "@/pages/journeys/submit"
    )
);

const FriendsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "friends" */
      "@/pages/friends"
    )
);

const DailyRewardPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "daily-reward" */
      "@/pages/daily-reward"
    )
);

const JourneysSubmissionPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "levels-submission" */
      "@/pages/journeys/submission"
    )
);

const JourneysTimeIsUpPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "journeys-time-is-up" */
      "@/pages/journeys/time-is-up"
    )
);

const ReplacePredictPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "replace-predict" */
      "@/pages/replace/predict"
    )
);

const ReplaceSelectPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "replace-select" */
      "@/pages/replace/select"
    )
);

const JourneysOnboarding = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "journeys-onboarding" */
      "@/pages/journeys/journey-onboarding"
    )
);

const ProNickNamePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "pro-nick-name" */
      "@/pages/predict-pro/nick-name"
    )
);

const ProRewardPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "pro-reward" */
      "@/pages/predict-pro/reward"
    )
);

const ProSubmissionPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "pro-submission" */
      "@/pages/predict-pro/submission"
    )
);

const Step2DepositPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true , webpackChunkName: "deposit-step-2" */
      "@/pages/wallet/deposit/Step2"
    )
);

export const routes: Route[] = [
  {
    path: paths.predictPro.timeIsUp,
    Component: PredictProTimeIsUpPage,
    title: "Matches",
  },
  {
    path: paths.predictPro.submit,
    Component: PredictProSubmitPage,
    title: "Matches",
  },
  {
    path: paths.predictPro.matches,
    Component: PredictProMatchesPage,
    title: "Matches",
  },
  { path: paths.home, Component: HomePage, title: "Home" },
  { path: paths.quest, Component: QuestPage, title: "Quest" },
  { path: paths.shop, Component: ShopPage, title: "Shop" },
  { Component: DepositPage, path: paths.wallet.deposit, title: "Deposit" },
  {
    Component: Step2DepositPage,
    path: paths.wallet.depositStep2,
    title: "Deposit Step 2",
  },
  { Component: WithdrawPage, path: paths.wallet.withdraw, title: "Withdraw" },
  { path: paths.friends, Component: FriendsPage, title: "Friends" },
  {
    path: paths.dailyReward,
    Component: DailyRewardPage,
    title: "Daily Reward",
  },
  {
    path: paths.wallet.index,
    Component: WalletPage,
    title: "Wallet",
  },
  {
    path: paths.predictPro.index,
    Component: PredictProPage,
    title: "Predict pro",
  },
  {
    path: paths.journeys.timeIsUp,
    Component: JourneysTimeIsUpPage,
    title: "Matches",
  },
  {
    path: paths.journeys.index,
    Component: JourneysPage,
    title: "Levels",
  },
  {
    path: paths.journeys.onboarding,
    Component: JourneysOnboarding,
    title: "Journeys Onboarding",
  },
  {
    path: paths.journeys.matches,
    Component: JourneysMatchesPage,
    title: "Matches",
  },
  {
    path: paths.journeys.submit,
    Component: JourneysSubmitPage,
    title: "Matches",
  },
  {
    path: paths.journeys.submission,
    Component: JourneysSubmissionPage,
    title: "Matches",
  },
  {
    path: paths.replace.predict,
    Component: ReplacePredictPage,
    title: "Matches",
  },
  {
    path: paths.replace.select,
    Component: ReplaceSelectPage,
    title: "Matches",
  },
  {
    path: paths.predictPro.nickName,
    Component: ProNickNamePage,
    title: "Matches",
  },
  {
    path: paths.predictPro.reward,
    Component: ProRewardPage,
    title: "Matches",
  },
  {
    path: paths.predictPro.submission,
    Component: ProSubmissionPage,
    title: "Matches",
  },
];
