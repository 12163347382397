import classNames from "classnames";
import { FC, ImgHTMLAttributes } from "react";
import s from "./image.module.css";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const Image: FC<ImageProps> = ({ className, ...restProps }) => (
  <img
    className={classNames(className, s.image, "pointer-events-none")}
    {...restProps}
  />
);

export default Image;
