import { FC, ReactNode } from "react";
import Dialog, { DialogProps } from "../dialog";
import Image from "../image";
import { IMAGES } from "@/constants";
import classNames from "classnames";

interface PrediBoyDialogProps extends DialogProps {
  description: string | ReactNode;
  cloudClassName?: string;
  wrapperClassName?: string;
}

const PrediBoyDialog: FC<PrediBoyDialogProps> = ({
  description,
  cloudClassName,
  wrapperClassName,
  ...restProps
}) => {
  return (
    <>
      {restProps.open && (
        <div
          className={classNames(
            "z-[2200] fixed bottom-20 -end-16",
            wrapperClassName
          )}
        >
          <Image
            src={IMAGES.PREDI_BOY}
            alt="predi-boy"
            className="w-36 predi-boy-dialog-image"
          />
          <div
            className={classNames(
              "absolute bottom-full translate-y-4 end-2/3 pt-4 pb-6 bg-cover bg-center bg-no-repeat px-4 flex items-center justify-center text-sm text-blue-900 font-bold text-center predi-boy-dialog-cloud",
              cloudClassName
            )}
          >
            <Image
              src={IMAGES.THINKING_CLOUD}
              alt="cloud"
              className="absolute top-0 left-0 h-full w-full"
            />
            <div className="z-10">{description}</div>
          </div>
        </div>
      )}
      <Dialog {...restProps} customChildren={<></>} />
    </>
  );
};

export default PrediBoyDialog;
