import { FC, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import UserMenuItem, { UserMenuItemProps } from "./item";
import {
  ChangeLanguageIcon,
  ChevronRightIcon,
  DocumentIcon,
  LampIcon,
  PolicyIcon,
  SocialMediaIcon,
  SupportIcon,
} from "@/components/icons";
import { openTelegramLink, openLink } from "@telegram-apps/sdk-react";
import { WEBSITE_URL } from "@/constants";
import ChangeLanguage from "./change-language";
import HarmReductionContent from "./harm-reduction";
import locales from "@/i18n";

interface ItemType extends UserMenuItemProps {
  id: TABS;
}

enum TABS {
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
  SUPPORT = "SUPPORT",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  HARM_REDUCTION = "HARM_REDUCTION",
  BLOG = "BLOG",
  PRIVACY_POLICY = "PRIVACY_POLICY",
}

const tabs: Partial<
  Record<TABS, { header: string | ReactNode; content: string | ReactNode }>
> = {
  [TABS.CHANGE_LANGUAGE]: {
    header: <FormattedMessage id="change-language" />,
    content: <ChangeLanguage />,
  },
  [TABS.HARM_REDUCTION]: {
    header: <FormattedMessage id="harm-reduction" />,
    content: <HarmReductionContent />,
  },
};

const UserMenu: FC = () => {
  const [tab, setTab] = useState<TABS>();
  const currentTab = tab ? tabs[tab] : undefined;

  const list: (ItemType | undefined)[] = [
    locales.length > 1
      ? {
          children: <FormattedMessage id="change-language" />,
          label: <ChangeLanguageIcon />,
          onClick: () => setTab(TABS.CHANGE_LANGUAGE),
          id: TABS.CHANGE_LANGUAGE,
        }
      : undefined,
    {
      children: <FormattedMessage id="support" />,
      label: <SupportIcon />,
      onClick: () => openTelegramLink("https://t.me/Predipie_Support"),
      id: TABS.SUPPORT,
    },
    {
      children: <FormattedMessage id="social-media" />,
      label: <SocialMediaIcon />,
      onClick: () => openLink("https://linktr.ee/predipie"),
      id: TABS.SOCIAL_MEDIA,
    },
    {
      children: <FormattedMessage id="harm-reduction" />,
      label: <LampIcon />,
      onClick: () => setTab(TABS.HARM_REDUCTION),
      id: TABS.HARM_REDUCTION,
    },
    {
      children: <FormattedMessage id="blog" />,
      label: <DocumentIcon />,
      onClick: () => openLink(`${WEBSITE_URL}/blogs/en`),
      id: TABS.BLOG,
    },
    {
      children: <FormattedMessage id="privacy-policy" />,
      label: <PolicyIcon />,
      onClick: () => openLink(`${WEBSITE_URL}/privacy-policy`),
      id: TABS.PRIVACY_POLICY,
    },
  ];

  return (
    <>
      <div className="text-center mb-6 text-2xl font-bold text-stroke-lg-blue-900">
        {currentTab ? (
          <div className="flex items-center gap-2">
            <div
              onClick={() => setTab(undefined)}
              className="bg-blue-600 border border-blue-400 rounded flex items-center"
            >
              <ChevronRightIcon
                width="1rem"
                height="1rem"
                className="ltr:rotate-180"
              />
            </div>
            <div className="grow">{currentTab.header}</div>
          </div>
        ) : (
          <FormattedMessage id="menu" />
        )}
      </div>
      {currentTab?.content || (
        <div className="flex flex-col gap-3">
          {(list.filter(Boolean) as ItemType[]).map(({ id, ...props }) => (
            <UserMenuItem key={id} {...props} />
          ))}
        </div>
      )}
    </>
  );
};

export default UserMenu;
