import Image from "@/components/common/image";
import { BundleItemType } from "@/helpers/api/types/api-response";
import { FC, HTMLAttributes } from "react";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "../cn";
import { BundleCategoryColorEnum } from "@/global/enum";
import classNames from "classnames";
import { FormattedNumber } from "react-intl";
import NumberWithSuffix from "@/components/common/number-with-suffix";

export interface BundleImageProps extends HTMLAttributes<HTMLDivElement> {
  data: BundleItemType;
  color: BundleCategoryColorEnum;
  view?: "three-in-one" | "slider-item" | "normal";
  imageClassName?: string;
  gridClassName?: string;
}

const BundleImage: FC<BundleImageProps> = ({
  data,
  color,
  className,
  view = "normal",
  imageClassName,
  gridClassName,
  ...restProps
}) => {
  const { title, logo, bundleProducts } = data;
  const { titleBg, sliderBundleClassName } =
    BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];
  if (view === "slider-item")
    return (
      <div
        className={classNames(
          gridClassName,
          "flex flex-wrap gap-x-2 gap-y-6 items-center justify-center"
        )}
      >
        {bundleProducts?.map(({ id, amount, product }) => (
          <div
            key={id}
            className={classNames(
              className,
              sliderBundleClassName,
              "px-1.5 pb-2 box-shadow-0-3 rounded-2xl text-sm font-bold w-[5.5rem] shrink-0"
            )}
          >
            <Image
              src={product?.logo.path}
              alt={title}
              className={classNames(imageClassName, "w-full -mt-3 aspect-[76/98] object-contain")}
            />
            <div className="text-center pt-2 leading-3">
              x <FormattedNumber value={amount} />
            </div>
          </div>
        ))}
      </div>
    );
  if (view === "three-in-one" && bundleProducts?.[0]?.product?.logo?.path)
    return (
      <div
        className={classNames(
          className,
          "relative flex justify-center w-full pb-3"
        )}
      >
        <Image
          src={bundleProducts[0].product.logo.path}
          alt={title}
          className={classNames("w-[4.75rem] z-10 aspect-[76/98]", imageClassName)}
        />
        <Image
          src={bundleProducts[0].product.logo.path}
          alt={title}
          className={classNames(
            "w-[4.75rem] absolute top-0 start-1/2 rotate-[20deg] translate-y-[5%] translate-x-[10%]",
            imageClassName
          )}
        />
        <Image
          src={bundleProducts[0].product.logo.path}
          alt={title}
          className={classNames(
            "w-[4.75rem] absolute top-0 end-1/2 rotate-[-20deg] translate-y-[5%] -translate-x-[10%]",
            imageClassName
          )}
        />
      </div>
    );
  return (
    <div className={classNames(className, "relative")} {...restProps}>
      <div
        className="absolute start-0 end-0 h-7 top-1.5 flex items-center justify-center text-xs font-bold bg-center bg-no-repeat bg-contain"
        style={{ backgroundImage: `url("${titleBg}")` }}
      >
        <NumberWithSuffix value={bundleProducts?.[0]?.amount} />{" "}
        {bundleProducts?.[0]?.asset?.title}
      </div>
      <Image
        src={logo.path}
        alt={title}
        className={classNames("w-full rounded-lg aspect-[98/118]", imageClassName)}
      />
    </div>
  );
};

export default BundleImage;
