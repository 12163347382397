import { type SVGProps, useRef } from "react";

const SocialMediaIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M18.0001 7.3602C17.9401 7.3502 17.8701 7.3502 17.8101 7.3602C16.4301 7.3102 15.3301 6.1802 15.3301 4.7802C15.3301 3.3502 16.4801 2.2002 17.9101 2.2002C19.3401 2.2002 20.4901 3.3602 20.4901 4.7802C20.4801 6.1802 19.3801 7.3102 18.0001 7.3602Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9704 14.6404C18.3404 14.8704 19.8504 14.6304 20.9104 13.9204C22.3204 12.9804 22.3204 11.4404 20.9104 10.5004C19.8404 9.79036 18.3104 9.55035 16.9404 9.79035"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.97047 7.3602C6.03047 7.3502 6.10047 7.3502 6.16047 7.3602C7.54047 7.3102 8.64047 6.1802 8.64047 4.7802C8.64047 3.3502 7.49047 2.2002 6.06047 2.2002C4.63047 2.2002 3.48047 3.3602 3.48047 4.7802C3.49047 6.1802 4.59047 7.3102 5.97047 7.3602Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00043 14.6404C5.63043 14.8704 4.12043 14.6304 3.06043 13.9204C1.65043 12.9804 1.65043 11.4404 3.06043 10.5004C4.13043 9.79036 5.66043 9.55035 7.03043 9.79035"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 14.8304C11.9401 14.8204 11.8701 14.8204 11.8101 14.8304C10.4301 14.7804 9.33008 13.6504 9.33008 12.2504C9.33008 10.8204 10.4801 9.67041 11.9101 9.67041C13.3401 9.67041 14.4901 10.8304 14.4901 12.2504C14.4801 13.6504 13.3801 14.7904 12.0001 14.8304Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08973 17.9805C7.67973 18.9205 7.67973 20.4605 9.08973 21.4005C10.6897 22.4705 13.3097 22.4705 14.9097 21.4005C16.3197 20.4605 16.3197 18.9205 14.9097 17.9805C13.3197 16.9205 10.6897 16.9205 9.08973 17.9805Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SocialMediaIcon;
