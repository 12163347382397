import classNames from "classnames";
import { HTMLAttributes, forwardRef } from "react";

export interface BorderGradientProps extends HTMLAttributes<HTMLDivElement> {
  outerClassName?: string;
}

const BorderGradient = forwardRef<HTMLDivElement, BorderGradientProps>(
  ({ outerClassName, ...restProps }, ref) => (
    <div
      className={classNames(
        "p-0.5 bg-gradient-to-b from-blue-200 to-blue-400",
        outerClassName
      )}
      ref={ref}
    >
      <div {...restProps} />
    </div>
  )
);

BorderGradient.displayName = "BorderGradient";

export default BorderGradient;
