import { type SVGProps, useRef } from "react";

const PlusIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 9 9"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M1.17163 4.08008H7.85838"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M4.43701 7.46777V0.781026"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PlusIcon;
