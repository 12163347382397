import { FC, useEffect, useRef, useState } from "react";
import {
  ERROR_CODES,
  ERROR_STATUS,
  IMAGES,
  MAX_JOURNEY_ONBOARDING_COUNT,
} from "@/constants";
import Profile from "./profile";
import Token from "./token";
import TapPower from "./tap-power";
import Button from "@/components/common/button";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import paths from "@/navigation/paths";
import Gems from "@/components/common/gems";
import TapArea from "@/components/common/tap-area";
import { useData } from "@/store/store";
import useThrottle from "@/hooks/use-throttle";
import useApi, { TapBodyType } from "@/helpers/api/hooks/use-api";
import classNames from "classnames";
import Image from "@/components/common/image";
import { usePersistData } from "@/store/persist-store";
import { AssetUnit, TapOnboardingState } from "@/global/enum";
import TapOnboarding from "./tap-onboarding";
import useBalance from "@/hooks/use-balance";
import toast from "react-hot-toast";
import { showErrorMessage } from "@/utils/string";
import { TapDataType } from "@/helpers/api/types/api-response";
import FetchError from "@/components/common/fetch-error";

// const ROTATION_COEFFICIENT = 2;

const HomeContent: FC = () => {
  const navigate = useNavigate();
  const { updateBalances } = useBalance();
  const {
    increaseTapAmount,
    tapData,
    setTapData,
    changeSpecificBalanceAmount,
  } = useData();
  const { onboardTap } = usePersistData();
  const { tap, getTaps } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  // const [rotation, setRotation] = useState<{ x: number; y: number }>({
  //   x: 0,
  //   y: 0,
  // });
  const [animation, setAnimation] = useState<boolean>(false);
  const lastTapData = useRef<TapDataType>(tapData!);
  const animationTimeout = useRef<NodeJS.Timeout>();
  const throttledTapAmount = useThrottle<number>(tapData!.amount, 2000);
  const tapAmount = useRef<number>(tapData!.amount);
  const tapped = useRef<boolean>(false);
  const tapRemain = useRef<number>(tapData!.remain);
  const previousTapController = useRef<AbortController>();
  const cachedTapCount = useRef<number>(0);
  const cachedLastTapCount = useRef<number>(0);
  const previousGetTapController = useRef<AbortController>();
  const { journeyOnboardingCount } = usePersistData();
  tapAmount.current = tapData!.amount;
  tapRemain.current = tapData!.remain;
  const disableTap =
    tapData!.remain <= 0 || tapData!.dailyTppogRemain <= 0 || loading || error;
  const decreaseTppog = () => {
    changeSpecificBalanceAmount(
      AssetUnit.TPPOG,
      cachedLastTapCount.current - cachedTapCount.current
    );
    cachedTapCount.current = cachedLastTapCount.current;
  };
  const makeTap = (body: TapBodyType) => {
    previousTapController.current?.abort();
    const controller = new AbortController();
    previousTapController.current = controller;
    const { signal } = controller;
    const lastTapCount = cachedTapCount.current;
    tap(body, { signal })
      .then((res) => {
        const { power, dailyTppogRemain, token } = res;
        setTapData({ power, dailyTppogRemain, token });
        lastTapData.current = res;
        cachedLastTapCount.current = lastTapCount;
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          if (
            err.errorCode === ERROR_CODES.TOKEN_DEPRECATED ||
            err.statusCode === ERROR_STATUS.BAD_REQUEST
          ) {
            refetchTaps();
          } else {
            toast.error(showErrorMessage(err.message), {
              position: "top-center",
              duration: 5000,
            });
            setTapData(lastTapData.current);
            decreaseTppog();
          }
          tapped.current = false;
        }
      });
  };
  const fetchTaps = () => {
    const controller = new AbortController();
    previousGetTapController.current = controller;
    const { signal } = controller;
    return getTaps({ signal })
      .then((res) => {
        setTapData(res);
        lastTapData.current = res;
      })
      .finally(() => updateBalances());
  };
  const refetchTaps = () => {
    setLoading(true);
    return getTaps()
      .then((res) => {
        setTapData(res);
        lastTapData.current = res;
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
        decreaseTppog();
      });
  };
  useEffect(() => {
    if (tapped.current) {
      makeTap({ amount: throttledTapAmount, token: lastTapData.current.token });
    }
  }, [throttledTapAmount]);
  useEffect(() => {
    fetchTaps();
    const intervalId = setInterval(() => {
      const nextValue =
        tapRemain.current + tapData!.tapLimit / (tapData!.tapPeriod / 1000);
      setTapData({
        remain: nextValue > tapData!.tapLimit ? tapData!.tapLimit : nextValue,
      });
    }, 1000);
    return () => {
      clearInterval(intervalId);
      if (tapAmount.current !== lastTapData.current.amount) {
        makeTap({
          amount: tapAmount.current,
          token: lastTapData.current.token,
        });
      }
    };
  }, []);
  return (
    <div
      className="flex flex-col p-4 pb-6 h-full bg-center bg-cover bg-no-repeat flex-1"
      style={{ backgroundImage: `url("${IMAGES.HOME_SCREEN_STADIUM}")` }}
    >
      <div className="max-w-2xl mx-auto w-full">
        <div className="pb-2">
          <Gems />
        </div>
        <Profile />
        <div className="grid grid-cols-2 gap-x-2 pb-8">
          <Token />
          <TapPower />
        </div>
      </div>
      <TapArea
        className="grow flex flex-col justify-end"
        powerClassName="text-green-300 font-bold text-stroke-md-green-900"
        disabled={disableTap}
        onChangeCount={(
          tapCount
          // { x, y }
        ) => {
          tapped.current = true;
          previousGetTapController.current?.abort();
          increaseTapAmount(tapCount);
          cachedTapCount.current += tapCount;
          // setRotation({
          //   x: y * -1 * ROTATION_COEFFICIENT,
          //   y: x * ROTATION_COEFFICIENT,
          // });
          setAnimation(true);
          clearTimeout(animationTimeout.current);
          animationTimeout.current = setTimeout(
            // () => setRotation({ x: 0, y: 0 }),
            () => setAnimation(false),
            75
          );
        }}
      >
        {error ? (
          <FetchError
            message={error.message}
            onRetry={refetchTaps}
            className="py-20"
          />
        ) : (
          <>
            <div className="grow relative">
              {onboardTap !== TapOnboardingState.Complete && <TapOnboarding />}
            </div>
            <div
              className={classNames(
                "max-w-[16.25rem] w-[80%] transition-transform duration-75 mx-auto flex flex-col",
                animation && "scale-105"
                // "transform-style-3d"
              )}
              // style={{
              //   transform: `rotateX(${rotation.x}deg)rotateY(${rotation.y}deg)`,
              // }}
            >
              <Image
                className={classNames(
                  "w-full object-contain",
                  disableTap && "opacity-50"
                )}
                src={IMAGES.PREDI_BOY}
                alt="predi-boy"
              />
            </div>
          </>
        )}
      </TapArea>
      <div className="w-full max-w-2xl px-10 mx-auto pt-4 animate-from-bottom">
        <Button
          color="secondary"
          fullWidth
          onClick={() => {
            journeyOnboardingCount < MAX_JOURNEY_ONBOARDING_COUNT
              ? navigate(paths.journeys.onboarding)
              : navigate(paths.journeys.index);
          }}
        >
          <FormattedMessage id="predict" />
        </Button>
      </div>
    </div>
  );
};

export default HomeContent;
