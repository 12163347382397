import { FC } from "react";
import { formatAddress } from "@/utils/string";
import classNames from "classnames";
import useWallet from "@/hooks/use-wallet";
import copyToClipBoard from "@/utils/copy";
import { FormattedMessage } from "react-intl";
import { CopyIcon2, DisconnectIcon, TonIcon } from "@/components/icons";
import Button from "@/components/common/button";
import { useTonAddress, useTonWallet } from "@tonconnect/ui-react";

const Wallet: FC = () => {
  const address = useTonAddress();
  const wallet = useTonWallet();
  const { loading, disconnect } = useWallet();
  return (
    <div className="flex flex-col animate-from-left">
      <span className="text-green-300 font-bold text-sm">
        <FormattedMessage id="wallet-connected" />
      </span>
      <div className="flex items-stretch gap-2">
        <div
          className={classNames(
            "p-1 flex grow bg-neutral-50 text-blue-850 items-center gap-2 rounded-lg"
          )}
          role="button"
        >
          {(wallet as any)?.imageUrl ? (
            <img
              src={(wallet as any)?.imageUrl}
              className="w-12 rounded-lg animate-fade-in shrink-0"
              alt="icon"
            />
          ) : (
            <TonIcon className="w-12 rounded-lg animate-fade-in shrink-0" />
          )}
          <div className="grow">
            <p className="flex items-center text-base font-semibold">
              {formatAddress(address)}
            </p>
            {!!(wallet as any).name && (
              <p className="text-sm font-semibold">{(wallet as any).name}</p>
            )}
          </div>
          <div className="animate-grow-up">
            <div
              className="pr-3"
              onClick={() => !loading && copyToClipBoard(address)}
            >
              <CopyIcon2 />
            </div>
          </div>
        </div>
        <Button
          onClick={disconnect}
          disabled={loading}
          color="white"
          className="text-red-700"
        >
          <DisconnectIcon />
        </Button>
      </div>
    </div>
  );
};

export default Wallet;
