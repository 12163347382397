import { FC } from "react";
import Spinner from ".";

const FixedSpinner: FC = () => (
  <div className="flex items-center justify-center fixed inset-0">
    <Spinner />
  </div>
);

export default FixedSpinner;
