import { FC, HTMLAttributes } from "react";
import classNames from "classnames";
import Step from "./step";
import { StepResponseType } from "@/helpers/api/types/api-response";

interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
  steps: StepResponseType[];
}

export const Column: FC<ColumnProps> = ({ steps, className, ...restProps }) => {
  return (
    <div
      className={classNames(
        "flex flex-col-reverse gap-28 items-center",
        className
      )}
      {...restProps}
    >
      {steps.map((step) => (
        <Step key={step.id} step={step} />
      ))}
    </div>
  );
};
