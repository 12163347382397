import { StepResponseType } from "@/helpers/api/types/api-response";
import useCurrentStep from "./context/use-current-step";
import { InCompleteCircleIcon, CompleteCircleIcon } from "@/components/icons";
import { CurrentStepStatus } from "@/global/enum";
import { createElement } from "react";
import { IMAGES } from "@/constants";
import classNames from "classnames";

interface UIStepType {
  borderIconComponent: any;
  borderClassName: string;
  tooltipBg: string;
  tooltipTitle: string;
  tooltipIcon?: string;
}

const useStepUI = (step: StepResponseType) => {
  const { activeStepNumber, currentStep } = useCurrentStep();

  const isPassed = activeStepNumber > step.displayOrder;
  const isLocked = activeStepNumber < step.displayOrder;
  const isCurrent = activeStepNumber === step.displayOrder;

  const getStepUI = (): UIStepType => {
    const stepUI: Partial<UIStepType> = {};
    if (
      isPassed ||
      (isCurrent && currentStep.currentStatus === CurrentStepStatus.WON)
    ) {
      stepUI.borderIconComponent = InCompleteCircleIcon;
      stepUI.borderClassName = "text-green-400";
      stepUI.tooltipBg = IMAGES.PASSED_TOOLTIP;
      stepUI.tooltipTitle = "passed";
      stepUI.tooltipIcon = IMAGES.FILLED_CHECK;
    } else if (isLocked) {
      stepUI.borderIconComponent = CompleteCircleIcon;
      stepUI.borderClassName = "text-blue-850 opacity-60";
      stepUI.tooltipBg = IMAGES.LOCK_TOOLTIP;
      stepUI.tooltipTitle = "lock";
      stepUI.tooltipIcon = IMAGES.LOCK_ICON;
    } else {
      stepUI.borderIconComponent = [
        CurrentStepStatus.START,
        CurrentStepStatus.WAITING,
        CurrentStepStatus.SUBMITTED,
      ].includes(currentStep.currentStatus)
        ? CompleteCircleIcon
        : InCompleteCircleIcon;

      switch (currentStep.currentStatus) {
        case CurrentStepStatus.START:
        case CurrentStepStatus.WAITING:
          stepUI.borderClassName = "text-blue-750 opacity-40";
          stepUI.tooltipBg = IMAGES.START_TOOLTIP;
          stepUI.tooltipTitle = "start";
          stepUI.tooltipIcon = IMAGES.FILLED_START;
          break;
        case CurrentStepStatus.SUBMITTED:
          stepUI.borderClassName = "text-blue-750 opacity-100";
          stepUI.tooltipBg = IMAGES.NOT_STARTED_TOOLTIP;
          stepUI.tooltipTitle = "not-started";
          break;
        case CurrentStepStatus.FAILED:
          stepUI.borderClassName = "text-red-700";
          stepUI.tooltipBg = IMAGES.FAILED_TOOLTIP;
          stepUI.tooltipTitle = "failed";
          stepUI.tooltipIcon = IMAGES.FILLED_FAILED;
          break;
        case CurrentStepStatus.LIVE:
          stepUI.borderClassName = "text-pink-500";
          stepUI.tooltipBg = IMAGES.LIVE_TOOLTIP;
          stepUI.tooltipTitle = "live";
          break;
        default:
          stepUI.borderClassName = "text-blue-200";
          stepUI.tooltipBg = IMAGES.PENDING_TOOLTIP;
          stepUI.tooltipTitle = "pending";
      }
    }

    return stepUI as UIStepType;
  };

  const {
    borderClassName,
    borderIconComponent,
    tooltipBg,
    tooltipTitle,
    tooltipIcon,
  } = getStepUI();

  return {
    border: createElement(borderIconComponent, {
      className: classNames(
        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
        borderClassName
      ),
    }),
    isCurrentStep: activeStepNumber === step.displayOrder,
    tooltipBg,
    tooltipTitle,
    tooltipIcon,
    isPassed,
    isLocked,
  };
};

export default useStepUI;
