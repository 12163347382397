export const makeUnique = <T = any>(arr: T[]): T[] => Array.from(new Set(arr));

export const calculateAverage = (numbers: number[]): number =>
  numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;

export const chunkArray = <T>(arr: T[], size: number): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};

export const makeUniqueById = <T extends { id: string }>(items: T[]): T[] => {
  const map = new Map<string, T>();

  items.forEach((item) => {
    if (map.has(item.id)) return;
    map.set(item.id, item);
  });

  return Array.from(map.values());
};
