import Page from "@/components/common/page";
import HomeContent from "@/components/pages/home/content";
import { FC } from "react";

const HomePage: FC = () => (
  <Page back={false}>
    <HomeContent />
  </Page>
);

export default HomePage;
