import { type SVGProps, useRef } from "react";

const FilledCrownIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <path
        d="M1.33337 10.3933V4.00662C1.33337 3.11995 1.84671 2.90662 2.47337 3.53328L4.20004 5.25995C4.46004 5.51995 4.88671 5.51995 5.14004 5.25995L7.52671 2.86662C7.78671 2.60662 8.21337 2.60662 8.46671 2.86662L10.86 5.25995C11.12 5.51995 11.5467 5.51995 11.8 5.25995L13.5267 3.53328C14.1534 2.90662 14.6667 3.11995 14.6667 4.00662V10.3999C14.6667 12.3999 13.3334 13.7333 11.3334 13.7333H4.66671C2.82671 13.7266 1.33337 12.2333 1.33337 10.3933Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilledCrownIcon;
