import { type SVGProps, useRef } from "react";

const ExclamationIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.25rem"
      height="1rem"
      viewBox="0 0 4 16"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M2.00775 2.11194V9.22305"
        stroke="currentColor"
        strokeWidth="2.84444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.99994 13.4898H2.01271"
        stroke="currentColor"
        strokeWidth="3.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExclamationIcon;
