import { IMAGES } from "@/constants";
import useApi from "@/helpers/api/hooks/use-api";
import { FC, useEffect, useState } from "react";
import FetchError from "../fetch-error";
import Spinner from "../spinner";
import { BundleItemType } from "@/helpers/api/types/api-response";
import Image from "../image";
import { FormattedMessage, useIntl } from "react-intl";
import Price from "@/components/pages/shop/tabs/shop/common/price";
import { BundleCategoryColorEnum } from "@/global/enum";
import Button from "../button";
import toast from "react-hot-toast";
import { showErrorMessage } from "@/utils/string";
import useBalance from "@/hooks/use-balance";

interface PurchaseProps {
  id: string;
  onPurchase?: () => void;
}

const Purchase: FC<PurchaseProps> = ({ id, onPurchase = () => {} }) => {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<BundleItemType>();
  const { getBundleByProductId, purchaseBundles } = useApi();
  const { updateBalances } = useBalance();
  const { formatMessage } = useIntl();
  const fetchBundle = () => {
    setError(null);
    getBundleByProductId(id).then(setData).catch(setError);
  };
  useEffect(() => {
    fetchBundle();
  }, []);

  const purchase = () => {
    setLoading(true);
    purchaseBundles(data!.id)
      .then(() => {
        toast.success(formatMessage({ id: "action-finished-successfully" }), {
          position: "top-center",
          duration: 5000,
        });
        onPurchase();
        updateBalances();
      })
      .catch((err) => {
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        });
      })
      .finally(() => setLoading(false));
  };

  if (error)
    return (
      <FetchError
        className="py-20"
        message={error.message}
        onRetry={fetchBundle}
      />
    );
  if (!data)
    return (
      <div className="flex py-40 items-center justify-center">
        <Spinner />
      </div>
    );
  const { logo, title, asset, price } = data;
  return (
    <>
      <div
        className="h-40 bg-center bg-contain bg-no-repeat flex items-center justify-center"
        style={{ backgroundImage: `url("${IMAGES.UNION}")` }}
      >
        <Image src={logo.path} alt={title} className="w-24" />
      </div>
      <div className="mb-6 mt-2 text-sm">
        <FormattedMessage
          id="purchase-description"
          values={{
            value: <span className="text-base font-bold">{title}</span>,
          }}
        />
      </div>
      <div className="bg-blue-800 rounded-lg p-2 flex items-center justify-between mb-6">
        <div className="text-sm text-shadow-0-2-blue-900 text-stroke-md-blue-900 font-bold">
          {title}
        </div>
        <Price
          asset={asset}
          price={price}
          color={BundleCategoryColorEnum.DarkBlue}
        />
      </div>
      <Button color="secondary" fullWidth onClick={purchase} disabled={loading}>
        <FormattedMessage id="purchase" />
      </Button>
    </>
  );
};

export default Purchase;
