import { FC } from "react";
import GemCard, { GemCardProps } from "..";
import { useIntl } from "react-intl";
import Image from "../../image";
import { useData } from "@/store/store";
import { AssetUnit } from "@/global/enum";
import { hasDecimalPart } from "@/utils/number";

const UsdGemCard: FC<Omit<GemCardProps, "icon" | "value">> = (props) => {
  const { formatNumber } = useIntl();
  const { balances } = useData();
  const balance = balances![AssetUnit.USD];
  return (
    <GemCard
      icon={
        <Image src={balance.asset.logo.path} className="h-7 w-7" alt="coin" />
      }
      value={formatNumber(balance.amount, {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: hasDecimalPart(balance.amount) ? undefined : 0,
      })}
      {...props}
    />
  );
};

export default UsdGemCard;
