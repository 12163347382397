import { UserEntityType } from "@/helpers/api/types/api-response";
import { useLaunchParams } from "@telegram-apps/sdk-react";

interface StartParams {
  refCode?: UserEntityType["refCode"];
  startUri?: string;
}

const safeParse = (startParam: string): StartParams => {
  try {
    return JSON.parse(atob(startParam));
  } catch (error) {
    return {};
  }
};

const useStartParams = (): [StartParams, (s: StartParams) => string] => {
  const { initData } = useLaunchParams();
  return [
    safeParse(initData?.startParam || ""),
    (params: StartParams) => btoa(JSON.stringify(params)),
  ];
};

export default useStartParams;
