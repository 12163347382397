import { FC } from "react";
import Image from "../image";
import { IMAGES } from "@/constants";
import Button from "../button";

const UnsupportedEnvironment: FC = () => {
  const openInTelegram = () => {
    const url = import.meta.env.VITE_TELEGRAM_URL;
    window.open(url, "_blank");
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center max-w-xl px-10 mx-auto w-full">
      <Image
        src={IMAGES.TELEGRAM_ERROR}
        alt="Unsupported Enviroment"
        className="h-[6.75rem]"
      />
      <h1 className="text-stroke-md-blue-900 text-xl font-bold mt-6 px-4 text-center text-white">
        Looks Like You’re Outside Telegram!
      </h1>
      <p className="mt-1 text-neutral-50">
        It seems you’re trying to open PrediPie outside Telegram. Please open it
        in Telegram app to get started!
      </p>

      <Button
        color="tertiary"
        className="mt-10"
        fullWidth
        onClick={openInTelegram}
      >
        Open in Telegram
      </Button>
    </div>
  );
};

export default UnsupportedEnvironment;
