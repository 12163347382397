import { BundleItemType } from "@/helpers/api/types/api-response";
import { FC, useState } from "react";
import { BundleDisplayProps } from "../types";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "../cn";
import classNames from "classnames";
import Price from "../common/price";
import BundleImage from "../common/bundle-image";
import PurchaseDialog from "../common/purchase-dialog";

interface ItemProps extends Pick<BundleDisplayProps, "color"> {
  data: BundleItemType;
}

const Item: FC<ItemProps> = ({ data, color }) => {
  const [openPurchaseModal, setOpenPurchaseModal] = useState<boolean>(false);

  const { asset, price } = data;
  const { gridItemsClassName } = BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];

  return (
    <>
      <PurchaseDialog
        open={openPurchaseModal}
        onClose={() => setOpenPurchaseModal(false)}
        imageWrapperClassName="w-24"
        data={data}
        color={color}
      />
      <div
        className={classNames(
          gridItemsClassName,
          "rounded-2xl p-2 pb-1.5 bg-gradient-to-b box-shadow-0-3"
        )}
        onClick={() => setOpenPurchaseModal(true)}
      >
        <BundleImage data={data} color={color} />
        <div className="mt-1">
          <Price asset={asset} price={price} color={color} />
        </div>
      </div>
    </>
  );
};

export default Item;
