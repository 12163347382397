import { StateCreator } from "zustand";
import { TapOnboardingState } from "@/global/enum";

export type OnboardingState = {
  onboardTap: TapOnboardingState;
  journeyOnboardingCount: number;
  isGamePlayOnboardingDone: boolean;
  setOnboarding: (d: Partial<Omit<OnboardingState, "setOnboarding">>) => void;
};

export const createOnboardingState: StateCreator<OnboardingState> = (
  set,
  get
) => ({
  onboardTap: TapOnboardingState.Step1,
  journeyOnboardingCount: 0,
  isGamePlayOnboardingDone: false,
  setOnboarding: (d) => set({ ...get(), ...d }),
});
