import { type SVGProps, useRef } from "react";

const ClockIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="vuesax/linear/clock">
        <g id="clock">
          <path
            id="Vector"
            d="M14.6667 8.19999C14.6667 11.88 11.68 14.8667 8.00004 14.8667C4.32004 14.8667 1.33337 11.88 1.33337 8.19999C1.33337 4.51999 4.32004 1.53333 8.00004 1.53333C11.68 1.53333 14.6667 4.51999 14.6667 8.19999Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M10.4733 10.32L8.40663 9.08666C8.04663 8.87333 7.7533 8.36 7.7533 7.94V5.20667"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ClockIcon;
