import { type SVGProps, useRef } from "react";

const PolicyIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M20.9099 11.32C20.9099 16.21 17.3599 20.79 12.5099 22.13C12.1799 22.22 11.8198 22.22 11.4898 22.13C6.63984 20.79 3.08984 16.21 3.08984 11.32V6.92999C3.08984 6.10999 3.70986 5.17999 4.47986 4.86999L10.0498 2.59002C11.2998 2.08002 12.7098 2.08002 13.9598 2.59002L19.5298 4.86999C20.2898 5.17999 20.9199 6.10999 20.9199 6.92999L20.9099 11.32Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.7002C13.1046 12.7002 14 11.8048 14 10.7002C14 9.59563 13.1046 8.7002 12 8.7002C10.8954 8.7002 10 9.59563 10 10.7002C10 11.8048 10.8954 12.7002 12 12.7002Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.7002V15.7002"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PolicyIcon;
