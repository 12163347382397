import Dialog, { DialogProps } from "@/components/common/dialog";
import { BundleCategoryColorEnum } from "@/global/enum";
import { FC, useState } from "react";
import BundleImage, { BundleImageProps } from "./bundle-image";
import { FormattedMessage } from "react-intl";
import Price from "./price";
import Button from "@/components/common/button";
import classNames from "classnames";
import { BundleItemType } from "@/helpers/api/types/api-response";
import { IMAGES } from "@/constants";
import useApi from "@/helpers/api/hooks/use-api";
import useBalance from "@/hooks/use-balance";
import PurchaseResultDialog from "./purchase-result";
import toast from "react-hot-toast";
import useGetPurchaseResultData, {
  PurchaseResultModalData,
} from "./purchase-result/use-get-purchase-result-data";
import { showErrorMessage } from "@/utils/string";

interface PurchaseDialog
  extends Omit<DialogProps, "color">,
    Pick<BundleImageProps, "view"> {
  imageClassName?: string;
  imageWrapperClassName?: string;
  imageGridClassName?: string;
  color: BundleCategoryColorEnum;
  data: BundleItemType;
}

const PurchaseDialog: FC<PurchaseDialog> = ({
  seedClassName,
  color,
  data,
  imageClassName,
  imageWrapperClassName,
  imageGridClassName,
  view,
  ...restProps
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { purchaseBundles } = useApi();
  const { title, asset, price, id, availableCapacityPerUser, bundleProducts } =
    data;
  const { updateBalances } = useBalance();
  const [resultModal, setResultModal] = useState<PurchaseResultModalData>();

  const { getModalData, successfulResult } = useGetPurchaseResultData({
    availableCapacityPerUser,
    insufficientAssetTitle: asset.title,
    assetTitle: bundleProducts?.[0].asset?.title,
  });

  const purchase = () => {
    setLoading(true);
    purchaseBundles(id)
      .then(() => {
        updateBalances();
        setResultModal(successfulResult);
      })
      .catch((err) => {
        const modalData = getModalData(err);
        if (modalData) {
          setResultModal(modalData);
        } else {
          toast.error(showErrorMessage(err.message), {
            position: "top-center",
            duration: 5000,
          });
        }
      })
      .finally(() => {
        restProps.onClose();
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog seedClassName={classNames(seedClassName, "p-4")} {...restProps}>
        <div
          className="h-40 bg-center bg-contain bg-no-repeat flex items-center justify-center"
          style={{ backgroundImage: `url("${IMAGES.UNION}")` }}
        >
          <BundleImage
            gridClassName={imageGridClassName}
            data={data}
            color={color}
            className={classNames("z-10", imageWrapperClassName)}
            imageClassName={imageClassName}
            view={view}
          />
        </div>
        <div className="mb-6 mt-2 text-sm">
          <FormattedMessage
            id="purchase-description"
            values={{
              value: <span className="text-base font-bold">{title}</span>,
            }}
          />
        </div>
        <div className="bg-blue-800 rounded-lg p-2 flex items-center justify-between mb-6">
          <div className="text-sm text-shadow-0-2-blue-900 text-stroke-md-blue-900 font-bold">
            {title}
          </div>
          <Price
            asset={asset}
            price={price}
            color={BundleCategoryColorEnum.DarkBlue}
          />
        </div>
        <Button
          color="secondary"
          fullWidth
          onClick={purchase}
          disabled={loading}
        >
          <FormattedMessage id="purchase" />
        </Button>
      </Dialog>

      <PurchaseResultDialog
        data={resultModal}
        onClose={() => setResultModal(undefined)}
      />
    </>
  );
};

export default PurchaseDialog;
