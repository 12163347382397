import { type SVGProps, useRef } from "react";

const ArrowDownIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="vuesax/linear/arrow-down">
        <g id="arrow-down">
          <path
            id="Vector"
            d="M13.78 6.4668L9.4333 10.8135C8.91997 11.3268 8.07997 11.3268 7.56664 10.8135L3.21997 6.4668"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
