import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Pinked } from "@/components/pages/journeys/onboarding";
import { RotateIcon } from "@/components/icons";
import classNames from "classnames";
import UAParser from "ua-parser-js";

const parser = new UAParser(navigator.userAgent);

const isMobile = parser.getDevice().type === "mobile";

const RotateWarning: FC = () => {
  return (
    <div
      className={classNames(
        "fixed inset-0 z-[100000] hidden items-center justify-center transition-opacity duration-300 bg-landscape-radical",
        isMobile && "mobile-landscape"
      )}
    >
      <div className="bg-blue-900 border border-blue-200 p-4 rounded-2xl max-w-2xl mx-auto w-64 flex flex-col gap-3 items-center">
        <h1 className="text-white font-bold text-center">
          <FormattedMessage
            id="rotate-your-phone"
            values={{
              pinked: (chunks) => <Pinked>{chunks}</Pinked>,
            }}
          />
        </h1>
        <p className="text-sm">
          <FormattedMessage
            id="rotate-for-better-experience"
            values={{
              pinked: (chunks) => <Pinked>{chunks}</Pinked>,
            }}
          />
        </p>
        <RotateIcon />
      </div>
    </div>
  );
};

export default RotateWarning;
