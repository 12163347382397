import { IMAGES } from "@/constants";
import { BundleCategoryColorEnum } from "@/global/enum";

interface BundleCategoryClassNames {
  titleClassNames: string;
  gridItemsClassName: string;
  titleBg: string;
  priceClassName: string;
  threeInOneWrapperClassName: string;
  sliderItemWrapperClassName: string;
  sliderItemPriceWrapperClassName: string;
  threeInOneBg: string;
  sliderBundleClassName: string;
}

export const BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP: Record<
  BundleCategoryColorEnum,
  BundleCategoryClassNames
> = {
  [BundleCategoryColorEnum.Green]: {
    titleClassNames:
      "text-shadow-0-1-green-950 text-stroke-md-green-900 bg-green-500 border-green-700 shadow-green-700",
    gridItemsClassName: "from-green-500 to-green-700 shadow-green-900",
    titleBg: IMAGES.SHOP_GREEN_UNION,
    priceClassName: "text-shadow-0-1-green-900 text-stroke-md-green-900",
    threeInOneWrapperClassName: "",
    threeInOneBg: "",
    sliderItemWrapperClassName: "",
    sliderItemPriceWrapperClassName: "",
    sliderBundleClassName: "",
  },
  [BundleCategoryColorEnum.LightBlue]: {
    titleClassNames:
      "text-shadow-0-1-blue-900 text-stroke-md-blue-950 bg-blue-500 border-blue-600 shadow-blue-750",
    gridItemsClassName: "from-blue-300 to-blue-400 shadow-blue-750",
    titleBg: IMAGES.SHOP_SKY_BLUE_UNION,
    priceClassName: "text-shadow-0-1-blue-900 text-stroke-md-blue-850",
    threeInOneWrapperClassName: "",
    threeInOneBg: "",
    sliderItemWrapperClassName: "",
    sliderItemPriceWrapperClassName: "",
    sliderBundleClassName: "",
  },
  [BundleCategoryColorEnum.DarkBlue]: {
    titleClassNames:
      "text-shadow-0-1-blue-900 text-stroke-md-blue-950 bg-blue-700 border-blue-750 shadow-blue-850",
    gridItemsClassName: "",
    titleBg: "",
    priceClassName: "text-shadow-0-1-blue-900 text-stroke-md-blue-900",
    threeInOneWrapperClassName: "",
    threeInOneBg: "",
    sliderItemWrapperClassName: "from-blue-400 to-blue-600 shadow-blue-800",
    sliderItemPriceWrapperClassName: "bg-blue-700 border-blue-750",
    sliderBundleClassName: "bg-blue-100 shadow-blue-700 text-blue-850",
  },
  [BundleCategoryColorEnum.Purple]: {
    titleClassNames:
      "text-shadow-0-1-purple-950 text-stroke-md-purple-950 bg-purple-400 border-purple-800 shadow-purple-900",
    gridItemsClassName: "",
    titleBg: "",
    priceClassName: "text-shadow-0-1-purple-950 text-stroke-md-purple-950",
    threeInOneWrapperClassName: "bg-purple-400 shadow-purple-700",
    threeInOneBg: IMAGES.SHOP_3_IN_1_PURPLE_BG,
    sliderItemWrapperClassName: "",
    sliderItemPriceWrapperClassName: "",
    sliderBundleClassName: "",
  },
  [BundleCategoryColorEnum.Pink]: {
    titleClassNames:
      "text-shadow-0-1-pink-950 text-stroke-md-pink-950 bg-pink-400 border-pink-700 shadow-pink-800",
    gridItemsClassName: "",
    titleBg: "",
    priceClassName: "text-shadow-0-1-pink-950 text-stroke-md-pink-900",
    threeInOneWrapperClassName: "",
    threeInOneBg: "",
    sliderItemWrapperClassName: "",
    sliderItemPriceWrapperClassName: "bg-pink-700 border-pink-900",
    sliderBundleClassName: "bg-pink-50 shadow-pink-600 text-pink-800",
  },
  [BundleCategoryColorEnum.Yellow]: {
    titleClassNames:
      "text-shadow-0-1-blue-900 text-stroke-md-pink-950 bg-yellow-500 border-yellow-700 shadow-yellow-800",
    gridItemsClassName: "",
    titleBg: "",
    priceClassName: "",
    threeInOneWrapperClassName: "",
    threeInOneBg: "",
    sliderItemWrapperClassName: "",
    sliderItemPriceWrapperClassName: "bg-yellow-700 border-yellow-900",
    // TODO: temporary
    sliderBundleClassName: "bg-yellow-100 shadow-pink-600 text-yellow-900",
  },
};
