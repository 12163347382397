import { SVGProps, useRef } from "react";

const DangerIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="2.8rem"
      height="3.12rem"
      viewBox="0 0 45 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g filter="url(#filter0_d_2340_68532)">
        <path
          d="M4.63216 37.0711L4.63927 37.0549L4.64585 37.0384L4.69793 36.9082L4.70241 36.897L4.70665 36.8857C4.72851 36.8274 4.74894 36.7687 4.76433 36.7245C4.76672 36.7176 4.769 36.7111 4.77113 36.7049C4.78893 36.6539 4.8015 36.6188 4.81398 36.5871C4.8362 36.5307 4.8544 36.4952 4.87719 36.461L4.89677 36.4316L4.91445 36.4011L19.7813 10.703L19.7983 10.6737L19.8134 10.6435C19.9814 10.3074 20.187 10.0634 20.414 9.89697L20.6306 9.73812L20.6578 9.68362C21.6309 8.91007 22.9855 8.82861 24.0701 9.44245C24.6534 9.78598 25.1008 10.2714 25.3303 10.7833L25.3533 10.8348L25.3815 10.8837L40.1097 36.4368C40.3887 36.9597 40.5165 37.4783 40.5165 37.9797C40.5165 39.6833 39.1393 41.0605 37.4357 41.0605H7.59781C5.88523 41.0605 4.49099 39.6743 4.49099 37.9797C4.49099 37.579 4.5382 37.2859 4.63216 37.0711Z"
          fill="url(#paint0_linear_2340_68532)"
          stroke="#CE2223"
          strokeWidth="2.22222"
        />
        <path
          d="M22.4904 27.0308C21.1959 27.0308 20.1341 25.9693 20.1341 24.6485V17.2801C20.1341 15.9852 21.17 14.8978 22.4904 14.8978C23.837 14.8978 24.8728 15.9853 24.8728 17.2801V24.6485C24.8728 25.9431 23.7851 27.0308 22.4904 27.0308Z"
          fill="#FAF8FF"
          stroke="#CE2223"
          strokeWidth="0.0260366"
        />
        <path
          d="M22.4907 35.1278C20.9618 35.1278 19.6917 33.858 19.6917 32.2768C19.6917 30.7219 20.9616 29.4779 22.4907 29.4779C24.0458 29.4779 25.3156 30.7219 25.3156 32.2768C25.3156 33.8579 24.0456 35.1278 22.4907 35.1278Z"
          fill="#FAF8FF"
          stroke="#CE2223"
          strokeWidth="0.0260366"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2340_68532"
          x="3.37988"
          y="7.9248"
          width="38.248"
          height="36.4688"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.22222" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.394712 0 0 0 0 0.0751834 0 0 0 0 0.0769199 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2340_68532"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2340_68532"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2340_68532"
          x1="22.5038"
          y1="7.9248"
          x2="22.5038"
          y2="42.1716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F87E7F" />
          <stop offset="1" stopColor="#F65354" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DangerIcon;
