import { useEffect, useState } from "react";
import OfflineAlertModal from "./offline-alert-modal";

const NetworkStatusWarning = () => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(
    navigator.onLine ?? true
  );

  useEffect(() => {
    const goOnline = () => setOnlineStatus(true);
    const goOffline = () => setOnlineStatus(false);

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return (
    <OfflineAlertModal
      showModal={!onlineStatus}
      closeModal={() => setOnlineStatus(true)}
    />
  );
};

export default NetworkStatusWarning;
