import { InventoryStandIcon } from "@/components/icons";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

interface StandProps extends HTMLAttributes<HTMLDivElement> {}

const Stand: FC<StandProps> = ({ className, ...restProps }) => (
  <div className="relative">
    <div className="absolute bottom-0 left-0 right-0">
      <InventoryStandIcon className="w-full" />
    </div>
    <div className={classNames("relative z-10", className)} {...restProps} />
  </div>
);

export default Stand;
