import Button from "@/components/common/button";
import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import paths from "@/navigation/paths";
import { usePersistData } from "@/store/persist-store";
import { FC, PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useNavigate } from "react-router-dom";

export const Pinked: FC<PropsWithChildren> = ({ children }) => (
  <span className="text-pink-500">{children}</span>
);

const JourneyOnboarding: FC = () => {
  const { setOnboarding, journeyOnboardingCount } = usePersistData();
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();

  const steps = useMemo<ReactNode[]>(() => {
    const prize = (
      <FormattedNumber
        style="currency"
        currency="USD"
        maximumFractionDigits={0}
        value={10000}
      />
    );
    return [
      <>
        <div className="grow flex flex-col justify-center items-center">
          <div
            className="w-64 h-28 bg-contain bg-center bg-no-repeat px-6 pb-4 text-center flex items-center gap-1 mx-auto"
            style={{ backgroundImage: `url("${IMAGES.THINKING_CLOUD}")` }}
          >
            <div className="text-blue-850 font-bold text-base leading-5">
              <FormattedMessage
                id="think-about-prize"
                values={{
                  prize: <Pinked>{prize}</Pinked>,
                }}
              />
            </div>
          </div>
          <Image src={IMAGES.PREDI_BOY_WIN} className="w-full max-w-[20rem]" />
        </div>
        <Button color="secondary" onClick={() => setStep(1)} fullWidth>
          <FormattedMessage id="yes" />
        </Button>
      </>,
      <>
        <div className="grow flex flex-col justify-center items-center">
          <div
            className="w-64 h-28 pt-5 px-14 pb-6 bg-contain bg-center bg-no-repeat text-center flex items-center gap-1 ml-auto"
            style={{ backgroundImage: `url("${IMAGES.REVERSE_CLOUD}")` }}
          >
            <div className="text-blue-850 font-bold text-base leading-5">
              <FormattedMessage
                id="pass-all-steps"
                values={{
                  prize: <Pinked>{prize}</Pinked>,
                  steps: <Pinked>{25}</Pinked>,
                }}
              />
            </div>
          </div>
          <Image
            src={IMAGES.PREDI_BOY_WITH_TEN_THOUSAND}
            className="w-full mb-4 max-w-[20rem]"
          />
          <Image src={IMAGES.PASSED_LEVELS} className="px-10 max-w-[20rem]" />
        </div>
        <Button
          color="secondary"
          onClick={() => {
            setOnboarding({
              journeyOnboardingCount: journeyOnboardingCount + 1,
            });
            navigate(paths.journeys.index, { replace: true });
          }}
          fullWidth
        >
          <FormattedMessage id="start" />
        </Button>
      </>,
    ];
  }, []);

  return (
    <div className="flex-1 flex flex-col p-8 max-w-2xl mx-auto w-full gap-8">
      {steps[step]}
    </div>
  );
};

export default JourneyOnboarding;
