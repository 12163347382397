import { FC, useEffect, useState } from "react";
import QuestItem from "./item";
import { CACHE_KEYS, IMAGES } from "@/constants";
import {
  UserAdQuestResponseType,
  UserQuestEntityType,
} from "@/helpers/api/types/api-response";
import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import usePageVisibility from "@/hooks/use-page-visibility";
import { FormattedMessage } from "react-intl";
import Image from "@/components/common/image";
import Gems from "@/components/common/gems";
import paths from "@/navigation/paths";
import { useNavigate } from "react-router-dom";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import useApi from "@/helpers/api/hooks/use-api";
import SpecialQuest from "./special-quest";
import AdItem from "./ad-item";

const QuestContent: FC = () => {
  const isVisible = usePageVisibility();
  const navigate = useNavigate();
  const { getUserQuests, getUserAds } = useApi();
  const [isQuestLoading, setIsQuestLoading] = useState<boolean>(false);

  const {
    fetch: fetchQuests,
    data: questsData,
    error: questsError,
  } = useApiCache<UserQuestEntityType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getUserQuests()
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch((err) => {
            reject(err);
          })
      ),
    [CACHE_KEYS.USER_TASKS]
  );

  const {
    fetch: fetchAd,
    data: adData,
    error: adError,
    changeCache,
    hash,
  } = useApiCache<UserAdQuestResponseType>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getUserAds()
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch((err) => {
            reject(err);
          })
      ),
    [CACHE_KEYS.AD_TASK]
  );

  useEffect(() => {
    if (isVisible) {
      fetchAd();
      fetchQuests();
    }
  }, [isVisible]);

  return (
    <div className="max-w-lg w-full p-4 pb-6 mx-auto flex-1 flex flex-col">
      <Gems
        cardProps={{
          badgeClassName: "bg-blue-850 border border-blue-shade-900",
        }}
      />
      <div className="flex flex-col justify-center items-center mb-4 mt-3.5">
        <div
          className="w-[15rem] h-[7.5rem] bg-contain bg-center bg-no-repeat px-8 flex flex-col text-center items-center justify-center gap-1 mx-auto"
          style={{ backgroundImage: `url("${IMAGES.THINKING_CLOUD}")` }}
        >
          <div className="text-blue-850 font-bold text-base h-md:text-sm leading-5">
            <FormattedMessage id="quest-intro" />
          </div>
        </div>
        <Image
          src={IMAGES.BOX_PREDI}
          alt="predi intro about quests"
          className="w-[12.5rem] h-[9.375rem]"
        />
      </div>
      <div className="text-neutral-50 text-xl font-bold text-center">
        <FormattedMessage id="earn-by-quest" />
      </div>
      <div className="flex flex-col gap-2 my-4">
        <SpecialQuest
          actionButtonTitleId="claim"
          appearance="orange"
          descriptionId="claim-daily-reward"
          mainIcon={IMAGES.STACK_OF_COINS}
          titleId="daily-reward"
          onActionButtonClick={() => navigate(paths.dailyReward)}
        />
        <SpecialQuest
          actionButtonTitleId="invite"
          appearance="purple"
          descriptionId="invite-earn"
          mainIcon={IMAGES.GOLDEN_BALL}
          titleId="invite-your-friends"
          descriptionIcon={IMAGES.AIRDROP_ICON}
          onActionButtonClick={() => navigate(paths.friends)}
        />
      </div>
      {adError ? (
        <FetchError
          onRetry={fetchAd}
          message={adError.message}
          className="grow"
        />
      ) : adData ? (
        <div>
          <p className="text-lg text-stroke-md-neutral-950 font-bold text-neutral-50 animate-from-left px-3 mb-2 leading-4">
            <FormattedMessage id="farm-gem" />
          </p>
          <AdItem
            bundle={adData.bundle}
            maxCount={adData.maxCount}
            count={adData.count}
            purchase={adData.purchase}
            setLastWatchedRewardPurchase={(purchase) =>
              changeCache(hash, {
                ...adData,
                purchase,
                count: purchase ? adData.count + 1 : adData.count,
              })
            }
          />
        </div>
      ) : (
        <div className="grow flex justify-center items-center py-10">
          <Spinner />
        </div>
      )}
      {questsError ? (
        <FetchError
          onRetry={fetchQuests}
          message={questsError.message}
          className="grow"
        />
      ) : questsData ? (
        <div className="flex flex-col justify-start gap-6 mt-6 w-full grow">
          {questsData.map((d) => (
            <div className="flex flex-col" key={d.category.id}>
              <p className="text-lg text-stroke-md-neutral-950 font-bold text-neutral-50 animate-from-left px-3 mb-2 leading-4">
                {d.category.title}
              </p>
              {d.quests.map((questData, index) => (
                <QuestItem
                  isFirst={index === 0}
                  purchase={questData.purchase}
                  isLast={index === d.quests.length - 1}
                  categoryId={d.category.id}
                  key={questData.id}
                  status={questData.status}
                  {...questData.quest}
                  id={questData.id}
                  isQuestLoading={isQuestLoading}
                  setIsQuestLoading={setIsQuestLoading}
                />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="grow flex justify-center items-center py-10">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default QuestContent;
