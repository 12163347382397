import { useEffect, useState } from "react";

export interface IntersectionObserverOptions extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export const useIntersectionObserver = (
  elementRef: React.RefObject<Element>,
  options: IntersectionObserverOptions = {}
) => {
  const {
    threshold = 0.1,
    root = null,
    rootMargin = "0px",
    freezeOnceVisible = false,
  } = options;
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    if (!node) return;

    const observer = new IntersectionObserver(updateEntry, {
      threshold,
      root,
      rootMargin,
    });
    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin]);

  useEffect(() => {
    if (entry?.isIntersecting && freezeOnceVisible && elementRef.current) {
      const observer = new IntersectionObserver(updateEntry, {
        threshold,
        root,
        rootMargin,
      });
      observer.unobserve(elementRef.current);
    }
  }, [entry, freezeOnceVisible, elementRef, threshold, root, rootMargin]);

  return entry;
};
