import { useCallback, useEffect } from 'react';

/**
 * Run a function on a specific key down
 * @param targetKey Keyboard key
 * @param fb The function that runs on target key down
 */
const useKeyDown = (targetKey: KeyboardEvent['key'], fb: () => void): void => {
  /// Run fallback function on target key down
  const downHandler = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === targetKey) {
        fb();
      }
    },
    [fb, targetKey]
  );

  /// Add and remove event listener
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};

export default useKeyDown;
