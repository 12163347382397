import { useState } from "react";
import { useData } from "@/store/store";
import { createHash } from "@/utils/create-hash";
import { useLaunchParams } from "@telegram-apps/sdk-react";

interface CallBackOptions<T, O> {
  callOptions?: { signal?: AbortSignal | null };
  additionalOptions?: O;
  setCache: (v: T) => void;
  cachedData?: T;
}

interface CacheOptions<T> {
  resolveCachedDataIfExist?: boolean;
  initialValue?: T;
}

export const useCreateHash = (d: any[]) => {
  const { initData } = useLaunchParams();
  return createHash(
    [...d, initData?.user?.id]
      .map((d) => (typeof d === "object" ? JSON.stringify(d) : d))
      .join("")
  );
};

export const useChangeCache = <T = any>(d: any[]) => {
  const { cacheStore, setCache } = useData();
  const hash = useCreateHash(d);
  const cachedData: T | undefined = cacheStore[hash];
  return { cachedData, changeCache: (d: T) => setCache(hash, d) };
};

const useApiCache = <T = any, O = object>(
  callBack: (options: CallBackOptions<T, O>) => Promise<T>,
  d: any[],
  cacheOptions?: CacheOptions<T>
) => {
  const [error, setError] = useState<any>();
  const { cacheStore, setCache } = useData();
  const hash = useCreateHash(d);
  const cachedData: T | undefined =
    cacheStore[hash] || cacheOptions?.initialValue;
  const [isLoading, setIsLoading] = useState<boolean>(!cachedData);
  const fetch = (
    options?: Omit<CallBackOptions<T, O>, "setCache" | "cachedData">
  ) =>
    new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        setError(null);
        if (cacheOptions?.resolveCachedDataIfExist && cachedData) {
          resolve(cachedData);
          return cachedData;
        }
        const res = await callBack({
          ...(options || {}),
          setCache: (v) => setCache(hash, v),
          cachedData,
        });
        resolve(res);
        return res;
      } catch (err) {
        setError(err);
        reject(err);
      } finally {
        setIsLoading(false);
      }
    });
  const changeCache = setCache;
  return { data: cachedData, error, fetch, changeCache, hash, isLoading };
};

export default useApiCache;
