import { useNavigate } from "react-router-dom";
import { backButton } from "@telegram-apps/sdk-react";
import { FC, PropsWithChildren, useEffect } from "react";

interface PageProps {
  back?: boolean;
}

const Page: FC<PropsWithChildren<PageProps>> = ({ back = true, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (back) {
      backButton.show();
      return backButton.onClick(() => {
        navigate(-1);
      });
    }
    backButton.hide();
  }, [back]);

  return children;
};

export default Page;
