import { type SVGProps, useRef } from "react";

const InventoryStandIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 366 26"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        d="M15.3092 1.952L2.27509 14.952C-1.51233 18.7295 1.16295 25.2002 6.51217 25.2002H359.488C364.837 25.2002 367.512 18.7295 363.725 14.952L350.691 1.952C349.566 0.830179 348.042 0.200195 346.454 0.200195H19.5463C17.9577 0.200195 16.434 0.83018 15.3092 1.952Z"
        fill="url(#paint0_radial_2_77684)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2_77684"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(183 12.7002) rotate(90) scale(12.5 191)"
        >
          <stop stopColor="#002B75" />
          <stop offset="1" stopColor="#001E48" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default InventoryStandIcon;
