import classNames from "classnames";
import { FC, HTMLAttributes, ReactNode } from "react";

export interface GemCardProps extends HTMLAttributes<HTMLDivElement> {
  badgeClassName?: string;
  icon: ReactNode;
  value: string | ReactNode;
}

const GemCard: FC<GemCardProps> = ({
  icon,
  value,
  className,
  badgeClassName,
  ...restProps
}) => {
  return (
    <div className={classNames("relative", className)} {...restProps}>
      <div
        className={classNames(
          "flex items-center justify-center bg-blue-750 border border-solid rounded-lg border-blue-850 py-0.5",
          badgeClassName
        )}
      >
        <div className="ps-8 pe-4 text-xs font-bold text-shadow-0-1-neutral-1000 text-stroke-sm-neutral-950">
          {value}
        </div>
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 -start-1">{icon}</div>
    </div>
  );
};

export default GemCard;
