import locales from "@/i18n";
import { FC, useContext } from "react";
import UserMenuItem from "./item";
import { usePersistData } from "@/store/persist-store";
import { UserMenuContext } from "./context";

const ChangeLanguage: FC = () => {
  const { setLanguage } = usePersistData();
  const { closeModal } = useContext(UserMenuContext);
  return (
    <div className="flex flex-col gap-3">
      {locales.map(({ id, title }) => (
        <UserMenuItem
          key={id}
          onClick={() => {
            setLanguage(id);
            closeModal();
          }}
          label={<div className="capitalize text-xl">{id}</div>}
        >
          {title}
        </UserMenuItem>
      ))}
    </div>
  );
};

export default ChangeLanguage;
