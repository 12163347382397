import Image from "@/components/common/image";
import { FilledCrownIcon } from "@/components/icons";
import { ProSubmissionStatus } from "@/global/enum";
import { ProSubmissionItemType } from "@/helpers/api/types/api-response";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

interface SubmissionProps
  extends Omit<ProSubmissionItemType, "status" | "id">,
    HTMLAttributes<HTMLDivElement> {
  status: ProSubmissionStatus;
}

interface ProSubmissionStatusClassNames {
  submissionClassName: string;
}

const SUBMISSION_CLASS_NAMES: Record<
  ProSubmissionStatus,
  ProSubmissionStatusClassNames
> = {
  [ProSubmissionStatus.NOT_STARTED]: {
    submissionClassName: "bg-blue-750",
  },
  [ProSubmissionStatus.PEND]: {
    submissionClassName: "bg-pink-700",
  },
  [ProSubmissionStatus.LIVE]: {
    submissionClassName: "bg-blue-600",
  },
  [ProSubmissionStatus.FINISHED]: {
    submissionClassName: "bg-green-500",
  },
};

const Submission: FC<SubmissionProps> = ({
  proCount,
  proRetryCount,
  className,
  status,
  items,
  purchase,
  children,
  ...rest
}) => {
  const points = items.filter(({ isCorrect }) => !!isCorrect).length;

  const { submissionClassName } = SUBMISSION_CLASS_NAMES[status];

  const cardReward = purchase?.bundle?.bundleProducts?.length;

  return (
    <div
      className={classNames(
        "bg-blue-850 rounded-t-lg rounded-b-xl flex flex-col  gap-2",
        className
      )}
      {...rest}
    >
      <p
        className={classNames(
          submissionClassName,
          "text-sm rounded-t-lg text-center py-1.5 font-bold border-b-2 border-neutral-1000/70"
        )}
      >
        {status === ProSubmissionStatus.LIVE && (
          <p className="w-2 h-2 bg-pink-300 rounded-full border border-pink-500 me-1 inline-block animate-pulse" />
        )}
        # <FormattedNumber value={proCount} />{" "}
        {!!proRetryCount && <span>- {proRetryCount}</span>}
      </p>
      <div>
        {status === ProSubmissionStatus.PEND ? (
          <p className="text-center pb-4 text-pink-200 text-sm font-bold">
            <FormattedMessage id="tap-to-check" />
          </p>
        ) : (
          <>
            {
              <div className="flex items-center justify-center gap-1 mb-1 font-bold text-blue-50">
                {purchase && (
                  <Image
                    className={classNames("w-4", {
                      "w-5": cardReward! > 1,
                    })}
                    src={
                      cardReward! > 1
                        ? purchase?.bundle.bundleProducts?.[0].product?.logo
                            .path
                        : purchase?.bundle.bundleProducts?.[0].asset?.logo.path
                    }
                    alt="reward"
                  />
                )}
                {cardReward! > 1 ? (
                  <div className="bg-blue-900 p-1 rounded font-bold text-xxs text-center">
                    <FormattedMessage
                      id="plus-more-count"
                      values={{ count: cardReward! - 1 }}
                    />
                  </div>
                ) : purchase?.bundle ? (
                  <FormattedNumber
                    value={purchase?.bundle.bundleProducts?.[0].amount}
                  />
                ) : (
                  status === ProSubmissionStatus.FINISHED && (
                    <FormattedMessage id="no-reward" />
                  )
                )}
              </div>
            }
            <div className="flex items-center justify-center gap-1 mb-4">
              <FilledCrownIcon className="text-neutral-200 mb-1" />
              <p className="text-blue-50 text-xxs">
                <FormattedMessage
                  id="points-quantity"
                  values={{
                    count: (
                      <span className="text-sm me-0.5">
                        {status === ProSubmissionStatus.NOT_STARTED &&
                        !points ? (
                          "?"
                        ) : (
                          <FormattedNumber value={points} />
                        )}
                      </span>
                    ),
                  }}
                />
              </p>
            </div>
          </>
        )}
      </div>
      {children && children}
    </div>
  );
};

export default Submission;
