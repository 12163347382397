import MaskedImage from "@/components/common/masked-image";
import {
  FC,
  useEffect,
  useMemo,
  useRef,
  // useState
} from "react";
import PredictProDescription from "@/components/pages/predict-pro/description";
import { CACHE_KEYS, ERROR_CODES, IMAGES } from "@/constants";
// import Tab, { TabItem } from "@/components/common/tab";
import SubmissionTab from "@/components/pages/predict-pro/tabs/submission/content";
// import LeaderboardTab from "@/components/pages/predict-pro/tabs/leader-board/content";
import Gems from "@/components/common/gems";
import Button from "@/components/common/button";
import { FormattedMessage } from "react-intl";
import useApi from "@/helpers/api/hooks/use-api";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import {
  GroupedSubmissionData,
  groupByUniqueStatus,
} from "@/utils/group-by-status";
import FetchError from "@/components/common/fetch-error";
import { ProSubmissionItemType } from "@/helpers/api/types/api-response";
import InfiniteScroll from "@/components/common/infinite-scroll";
import { makeUniqueById } from "@/utils/array";
import Spinner from "@/components/common/spinner";
import { ProSubmissionsType } from "@/global/cached";
import toast from "react-hot-toast";
import { showErrorMessage } from "@/utils/string";
import { useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";
import { useData } from "@/store/store";
import ClaimRewards from "@/components/pages/predict-pro/claim-rewards";
import StepCircle from "@/components/pages/journeys/step-circle";
import { InCompleteCircleIcon } from "@/components/icons";
import Image from "@/components/common/image";

// type TabValues = "submission" | "leaderboard" | "history";

const limit = 50;

const PredictProContent: FC = () => {
  const navigate = useNavigate();
  const { user } = useData();

  const { getProSubmissions } = useApi();

  const { fetch, error, data } = useApiCache<
    ProSubmissionsType,
    { forcePageOne?: boolean }
  >(
    ({ setCache, cachedData, additionalOptions }) =>
      new Promise<ProSubmissionsType>((resolve, reject) =>
        getProSubmissions({
          limit,
          filters: JSON.stringify({ type: "pro" }),
          page: additionalOptions?.forcePageOne ? 1 : page.current,
        })
          .then((res) => {
            const items = makeUniqueById<ProSubmissionItemType>(
              additionalOptions?.forcePageOne
                ? [...res.data, ...(cachedData?.items || [])]
                : [...(cachedData?.items || []), ...res.data]
            );
            const resolvedData = {
              items,
              hasMore: additionalOptions?.forcePageOne
                ? cachedData!.hasMore
                : res.hasNextPage,
              journeyStepNumber: undefined,
            };
            setCache(resolvedData);
            resolve(resolvedData);
            return res;
          })
          .catch((err) => {
            if (
              err.errorCode ===
                ERROR_CODES.PREDICT_PRO_STEP_CONDITION_NOT_MATCHED &&
              err.errors?.stepDisplayOrder
            ) {
              setCache({
                ...cachedData!,
                journeyStepNumber: +err.errors.stepDisplayOrder,
              });
            } else {
              reject(err);
            }
          })
      ),

    [CACHE_KEYS.PRO_SUBMISSIONS],
    { initialValue: { hasMore: true } }
  );

  const page = useRef<number>(Math.ceil(data?.items?.length || 0) + 1);

  const groupedData = useMemo<GroupedSubmissionData[]>(
    () => groupByUniqueStatus(data?.items || []),
    [data?.items]
  );

  // const tabs: TabItem<TabValues>[] = [
  //   {
  //     value: "submission",
  //     title: <FormattedMessage id="submission" />,
  //     content: (
  //       <InfiniteScroll
  //         hasMore={data?.hasMore || false}
  //         loadFunction={() =>
  //           new Promise((resolve) => {
  //             fetch()
  //               .then(() => {
  //                 page.current++;
  //                 resolve();
  //               })
  //               .catch((err) => {
  //                 toast.error(showErrorMessage(err.message), {
  //                   position: "top-center",
  //                   duration: 5000,
  //                 });
  //               });
  //           })
  //         }
  //       >
  //         <SubmissionTab data={groupedData} />
  //       </InfiniteScroll>
  //     ),
  //   },
  //   {
  //     value: "leaderboard",
  //     title: <FormattedMessage id="leaderboard" />,
  //     content: <LeaderboardTab />,
  //   },
  //   // {
  //   //   value: "history",
  //   //   title: <FormattedMessage id="history" />,
  //   //   content: <LeaderboardTab />,
  //   // },
  // ];

  // const [tab, setTab] = useState<TabValues>(tabs[0].value);

  const firstTimeUser = data?.items?.length === 0;

  useEffect(() => {
    fetch({ additionalOptions: { forcePageOne: !!data?.items } });
  }, []);

  return (
    <div className="flex-1 flex flex-col items-center">
      {error ? (
        <FetchError className="grow" onRetry={fetch} message={error.message} />
      ) : !data?.items && !data?.journeyStepNumber ? (
        <div className="flex grow items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <MaskedImage
            className="h-[13.125rem] bg-blue-850 w-full"
            src={IMAGES.PREDICT_PRO_BANNER}
            alt="predict-pro"
          >
            <Gems
              cardProps={{
                badgeClassName: "bg-blue-850 border border-blue-shade-900",
              }}
            />
          </MaskedImage>
          <PredictProDescription
            firstTimeUser={firstTimeUser || !!data?.journeyStepNumber}
          />
          {!data?.journeyStepNumber ? (
            <div className="pb-6 w-full">
              <div className="max-w-2xl mx-auto w-full px-4">
                <Button
                  color="primary"
                  fullWidth
                  className="mb-5"
                  onClick={() =>
                    navigate(
                      user!.nickname
                        ? paths.predictPro.reward
                        : paths.predictPro.nickName
                    )
                  }
                >
                  <FormattedMessage id="enter-predict-pro" />
                </Button>
                <ClaimRewards />
              </div>
              {!firstTimeUser && (
                // <Tab
                //   tabs={tabs}
                //   value={tab}
                //   onValueChange={setTab}
                //   wrapperClassName="px-4 my-4 max-w-2xl mx-auto w-full"
                // />
                <InfiniteScroll
                  hasMore={data?.hasMore || false}
                  loadFunction={() =>
                    new Promise((resolve) => {
                      fetch()
                        .then(() => {
                          page.current++;
                          resolve();
                        })
                        .catch((err) => {
                          toast.error(showErrorMessage(err.message), {
                            position: "top-center",
                            duration: 5000,
                          });
                        });
                    })
                  }
                >
                  <SubmissionTab data={groupedData} />
                </InfiniteScroll>
              )}
            </div>
          ) : (
            <div className="px-6">
              <div className="flex items-center justify-center gap-4 mt-2 animate-fade-in">
                <div className="relative">
                  <InCompleteCircleIcon
                    className="text-green-400 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="3.75rem"
                    height="3.75rem"
                  />
                  <div
                    className="bg-contain bg-center bg-no-repeat w-10 h-4 flex justify-center items-center gap-0.5 pb-0.5 absolute -top-6 left-1/2 -translate-x-1/2"
                    style={{
                      backgroundImage: `url(${IMAGES.PASSED_TOOLTIP})`,
                    }}
                  >
                    <p className="text-[0.375rem] font-bold">
                      <FormattedMessage id="passed" />
                    </p>
                    <Image
                      src={IMAGES.FILLED_CHECK}
                      alt="icon"
                      className="h-2"
                    />
                  </div>
                  <StepCircle
                    showSmallBall
                    stepNumber={data.journeyStepNumber}
                    className="!w-[3.25rem] !h-12  box-shadow-inner-0--3"
                    stepTextClassName="text-xxs leading-3"
                    stepNumberClassName="text-base !text-stroke-sm-blue-850 !text-shadow-0-1-blue-850 leading-4"
                    smallBallClassName="w-5 h-5 !start-9 !bottom-2"
                  />
                </div>
                <FormattedMessage
                  id="pass-step-to-unlock"
                  values={{ stepNumber: data.journeyStepNumber }}
                />
              </div>
              <div className="animate-from-bottom">
                <Button
                  fullWidth
                  className="mt-4"
                  onClick={() => navigate(paths.journeys.index)}
                >
                  <FormattedMessage id="go-to-journey" />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PredictProContent;
