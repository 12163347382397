import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createLanguageState, LanguageState } from "./language";
import { createTokenState, TokenState } from "./token";
import { OnboardingState, createOnboardingState } from "./onboarding";
import { AdState, createAdState } from "./ad";

interface PersistStoreStoreType
  extends LanguageState,
    TokenState,
    AdState,
    OnboardingState {}

export const usePersistData = create(
  persist<PersistStoreStoreType>(
    (...a) => ({
      ...createLanguageState(...a),
      ...createTokenState(...a),
      ...createOnboardingState(...a),
      ...createAdState(...a),
    }),
    {
      name: "STORAGE",
    }
  )
);
