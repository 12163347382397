export const price = (p: number | string) => Math.floor(+p).toLocaleString();

export const formatNumberWithSuffix = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return num.toString();
  }
};

export const isEven = (number: number): boolean => number % 2 === 0;

export const hasDecimalPart = (number: number): boolean =>
  number - Math.floor(number) !== 0;
