import { FC } from "react";
import Dialog from "../dialog";
import { DangerIcon } from "@/components/icons";
import Button from "../button";
import { FormattedMessage } from "react-intl";

interface OfflineAlertModalProps {
  showModal: boolean;
  closeModal: () => void;
}
const OfflineAlertModal: FC<OfflineAlertModalProps> = ({
  showModal,
  closeModal,
}) => {
  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      seedClassName="p-4"
      withoutCloseIcon
    >
      <div className="flex flex-col justify-center items-center gap-4">
        <DangerIcon />
        <p className="text-xl font-bold text-stroke-sm-blue-900 text-shadow-0-3-blue-900">
          <FormattedMessage id="connection-lost" />
        </p>
        <p className="text-sm ps-2">
          <FormattedMessage id="connection-lost-desc" />
        </p>
        <Button
          color="tertiary"
          fullWidth
          onClick={() => window.location.reload()}
        >
          <FormattedMessage id="try-again" />
        </Button>
      </div>
    </Dialog>
  );
};

export default OfflineAlertModal;
