import { FC, useEffect, useRef, useState } from "react";
import Submission from "@/components/pages/predict-pro/tabs/submission/submission";
import {
  // AssetUnit,
  EventNames,
  ProSubmissionStatus,
} from "@/global/enum";
import Button from "@/components/common/button";
import { FormattedMessage, useIntl } from "react-intl";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import useApi from "@/helpers/api/hooks/use-api";
import {
  CACHE_KEYS,
  //  IMAGES
} from "@/constants";
import { ProSubmissionItemType } from "@/helpers/api/types/api-response";
import useBalance from "@/hooks/use-balance";
import toast from "react-hot-toast";
import { showErrorMessage } from "@/utils/string";
import useSSEEvents from "@/hooks/use-sse-events";
// import BundleAmount from "@/components/common/dollar-amount";
// import classNames from "classnames";
// import s from "./claim-rewards.module.css";
// import Image from "@/components/common/image";
// import dayjs from "dayjs";

// const RANK_PLACE_IMAGE = [
//   {
//     image: IMAGES.FIRST_PLACE,
//     textClassName: "from-yellow-400 to-yellow-600 text-yellow-950",
//     text: "first-place",
//   },
//   {
//     image: IMAGES.SECOND_PLACE,
//     textClassName: "from-[#E0E0F7] to-[#B2B8E6] text-blue-900",
//     text: "second-place",
//   },
//   {
//     image: IMAGES.THIRD_PLACE,
//     textClassName: "from-[#EFA463] to-[#E77818] text-yellow-950",
//     text: "third-place",
//   },
// ];

// TODO: REWRITE IT !!!

const ClaimRewards: FC = () => {
  const {
    getProSubmissionsRewards,
    claimReward,
    // getLeaderBoardRewards
  } = useApi();
  const { updateBalances } = useBalance();
  const [loading, setLoading] = useState<boolean>(false);
  const [events] = useSSEEvents();
  const proSubmissionsRewardsChangeCount =
    events[EventNames.SubmissionProFirstUnclaimed];
  // const leaderBoardRewardsChangeCount =
  //   events[EventNames.LeaderBoardFirstUnclaimed];
  const { formatMessage } = useIntl();

  const previousControllerSubmissionRef = useRef<AbortController>();
  // const previousControllerLeaderBoardRef = useRef<AbortController>();

  const {
    data: submissionRewards,
    fetch: fetchSubmissionRewards,
    error: submissionError,
  } = useApiCache<ProSubmissionItemType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) => {
        previousControllerSubmissionRef.current?.abort();
        const controller = new AbortController();
        previousControllerSubmissionRef.current = controller;
        const { signal } = controller;
        return getProSubmissionsRewards({ signal })
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch(reject);
      }),
    [CACHE_KEYS.PRO_SUBMISSIONS_REWARDS]
  );

  // const {
  //   data: leaderBoardRewards,
  //   fetch: fetchLeaderBoardRewards,
  //   error: leaderBoardError,
  // } = useApiCache(
  //   ({ setCache }) =>
  //     new Promise((resolve, reject) => {
  //       previousControllerLeaderBoardRef.current?.abort();
  //       const controller = new AbortController();
  //       previousControllerLeaderBoardRef.current = controller;
  //       const { signal } = controller;
  //       return getLeaderBoardRewards({ signal })
  //         .then((res) => {
  //           setCache(res);
  //           resolve(res);
  //           return res;
  //         })
  //         .catch(reject);
  //     }),
  //   [CACHE_KEYS.LEADER_BOARD_REWARDS]
  // );

  const claim = (id: string, successCallBack: () => void) => {
    setLoading(true);
    claimReward(id)
      .then(() => {
        toast.success(formatMessage({ id: "reward-claimed-successfully" }), {
          position: "top-center",
          duration: 5000,
        });
        updateBalances();
        successCallBack();
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error(showErrorMessage(err.message), {
            position: "top-center",
            duration: 5000,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSubmissionRewards();
  }, [proSubmissionsRewardsChangeCount]);

  // useEffect(() => {
  //   fetchLeaderBoardRewards();
  // }, [leaderBoardRewardsChangeCount]);

  const showRewards =
    submissionRewards && !submissionError && submissionRewards?.length !== 0;
  //    ||
  // (leaderBoardRewards &&
  //   !leaderBoardError &&
  //   leaderBoardRewards.length !== 0);

  return (
    <>
      {showRewards && (
        <>
          <div className="py-2 px-2 bg-blue-900 box-shadow-0-4 shadow-blue-950 rounded-2xl my-4">
            <p className="text-center text-pink-300 text-lg font-bold">
              <FormattedMessage id="claim-rewards" />
            </p>
            {/* 
            {leaderBoardRewards && (
              <>
                <p className="text-center my-4">
                  <FormattedMessage id="leaderboard" />
                </p>
                <div className="rounded-xl border border-yellow-400 bg-blue-850 p-1.5 pb-2.5">
                  <div
                    className="h-32 bg-cover bg-center bg-no-repeat flex flex-col text-center items-center mx-auto rounded-xl"
                    style={{
                      backgroundImage: `url("${IMAGES.LEADER_BOARD_REWARDS_BACKGROUND}")`,
                    }}
                  >
                    <div className="bg-blue-700 rounded-b-lg flex justify-center items-center text-xs px-8 py-1">
                      {dayjs(leaderBoardRewards.round?.date).format(
                        "MM/DD/YYYY"
                      )}
                    </div>
                    <span className="font-bold text-lg text-stroke-md-blue-950">
                      <FormattedMessage
                        id="you-won-your-place-reward"
                        values={{
                          place: (
                            <span
                              className={classNames(
                                "rounded-sm bg-gradient-to-b px-3 text-yellow-950 text-sm font-normal py-0.5 text-stroke-md-yellow-950",
                                RANK_PLACE_IMAGE[leaderBoardRewards.rank - 1]
                                  .textClassName
                              )}
                            >
                              <FormattedMessage
                                id={
                                  RANK_PLACE_IMAGE[leaderBoardRewards.rank - 1]
                                    .text
                                }
                              />
                            </span>
                          ),
                        }}
                      />
                    </span>
                    <p
                      className={classNames(
                        "font-bold text-2xl text-stroke-md-yellow-950 bg-gradient-to-b from-yellow-400 to-yellow-600 fill-transparent bg-clip-text",
                        s.amount
                      )}
                    >
                      <BundleAmount
                        rewardAmount={
                          leaderBoardRewards.purchases?.[0]?.amount *
                          leaderBoardRewards.purchases?.[0]?.bundle
                            ?.bundleProducts?.[0]?.amount
                        }
                        asset={leaderBoardRewards.purchases?.[0].asset}
                      />
                      {leaderBoardRewards.purchases?.[0]?.asset?.unit !==
                        AssetUnit.USD && (
                        <span
                          className={classNames(
                            "ms-1.5",
                            RANK_PLACE_IMAGE[leaderBoardRewards.rank - 1]
                              ?.textClassName || ""
                          )}
                        >
                          {leaderBoardRewards.purchases?.[0]?.asset?.title}
                        </span>
                      )}
                    </p>
                    <Image
                      src={RANK_PLACE_IMAGE[leaderBoardRewards.rank - 1].image}
                      className="w-20"
                    />
                  </div>
                  <Button
                    color="primary"
                    className="mt-2"
                    disabled={loading}
                    fullWidth
                    onClick={() =>
                      claim(
                        leaderBoardRewards.purchases?.[0]?.id,
                        fetchLeaderBoardRewards
                      )
                    }
                  >
                    <FormattedMessage id="claim" />
                  </Button>
                </div>
              </>
            )}
            */}

            {!!submissionRewards?.length && (
              <>
                <p className="text-center my-4">
                  <FormattedMessage id="submission" />
                </p>
                <div className="grid grid-cols-3 gap-1">
                  {submissionRewards?.map((submissionProps, index) => (
                    <Submission
                      key={index}
                      {...submissionProps}
                      status={ProSubmissionStatus.FINISHED}
                    >
                      <Button
                        color="primary"
                        className="mx-2 !py-2 -mt-4"
                        disabled={loading}
                        onClick={() =>
                          claim(
                            submissionProps?.purchase!.id,
                            fetchSubmissionRewards
                          )
                        }
                      >
                        <FormattedMessage id="claim" />
                      </Button>
                    </Submission>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ClaimRewards;
