import { type SVGProps, useRef } from "react";

const TonIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <g clipPath="url(#clip0_2295_30200)">
        <path
          d="M16.5 32.2C25.3366 32.2 32.5 25.0365 32.5 16.2C32.5 7.3634 25.3366 0.199951 16.5 0.199951C7.66344 0.199951 0.5 7.3634 0.5 16.2C0.5 25.0365 7.66344 32.2 16.5 32.2Z"
          fill="#0098EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0481 10.774L21.022 10.7739C21.3395 10.7739 21.6567 10.8206 21.9883 10.9751C22.3857 11.1604 22.5965 11.4524 22.7441 11.6683C22.7556 11.6851 22.7663 11.7024 22.7763 11.7202C22.9499 12.0292 23.0394 12.3628 23.0394 12.7218C23.0394 13.0628 22.9582 13.4343 22.7763 13.7582C22.7746 13.7613 22.7728 13.7643 22.771 13.7674L17.1014 23.5066C16.9764 23.7214 16.7463 23.8531 16.4978 23.8522C16.2493 23.8514 16.0201 23.7179 15.8966 23.5023L10.3311 13.7839C10.3295 13.7813 10.3279 13.7787 10.3263 13.776C10.1989 13.5661 10.0019 13.2415 9.96749 12.8227C9.93583 12.4375 10.0224 12.0515 10.2159 11.7169C10.4095 11.3822 10.7009 11.1147 11.0513 10.9509C11.4271 10.7753 11.8079 10.774 12.0481 10.774ZM15.8046 12.1652H12.0481C11.8013 12.1652 11.7065 12.1804 11.6403 12.2114C11.5487 12.2542 11.4718 12.3245 11.4203 12.4134C11.3689 12.5024 11.3457 12.6055 11.3541 12.7087C11.359 12.7678 11.3831 12.8355 11.5253 13.07C11.5283 13.075 11.5312 13.0799 11.5341 13.0849L15.8046 20.542V12.1652ZM17.1959 12.1652V20.5788L21.5653 13.0731C21.6146 12.9835 21.6481 12.854 21.6481 12.7218C21.6481 12.6145 21.6258 12.5214 21.5761 12.4254C21.5239 12.3503 21.4922 12.3106 21.4656 12.2834C21.4428 12.2602 21.4253 12.2477 21.4005 12.2362C21.2971 12.188 21.1914 12.1652 21.022 12.1652H17.1959Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2295_30200">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5 0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TonIcon;
