import FetchError from "@/components/common/fetch-error";
import Spinner from "@/components/common/spinner";
import { CACHE_KEYS } from "@/constants";
import { BundleCategoryItemType } from "@/helpers/api/types/api-response";
import useApi from "@/helpers/api/hooks/use-api";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { FC, useEffect } from "react";
import BundleCategory from "./bundle-category";

const ShopTab: FC = () => {
  const { getBundleCategory } = useApi();

  const { fetch, error, data } = useApiCache<BundleCategoryItemType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getBundleCategory({
          filters: JSON.stringify({ usagePlace: "shop" }),
          limit: 50,
        })
          .then((res) => {
            const sortedData = res.sort(
              (a, b) => a.displayOrder - b.displayOrder
            );
            resolve(sortedData);
            setCache(sortedData);
            return res;
          })
          .catch((err) => {
            reject(err);
          })
      ),
    [CACHE_KEYS.BUNDLE_CATEGORY]
  );

  useEffect(() => {
    fetch();
  }, []);

  if (error)
    return (
      <FetchError className="grow" onRetry={fetch} message={error.message} />
    );
  else if (data)
    return (
      <div className="grow max-w-2xl w-full mx-auto">
        {data.map((d) => (
          <BundleCategory key={d.id} data={d} />
        ))}
      </div>
    );
  return (
    <div className="grow flex items-center justify-center">
      <Spinner />
    </div>
  );
};

export default ShopTab;
