import { SVGProps, useRef } from "react";

const CupIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <path
        d="M8.60059 11.2V12.6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.2666 14.8667H11.9333V14.2C11.9333 13.4667 11.3333 12.8667 10.5999 12.8667H6.59993C5.8666 12.8667 5.2666 13.4667 5.2666 14.2V14.8667V14.8667Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M4.60059 14.8667H12.6006"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49967 10.8665C5.91967 10.8665 3.83301 8.77987 3.83301 6.19987V4.19987C3.83301 2.72654 5.02634 1.5332 6.49967 1.5332H10.4997C11.973 1.5332 13.1663 2.72654 13.1663 4.19987V6.19987C13.1663 8.77987 11.0797 10.8665 8.49967 10.8665Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.14702 7.96676C3.64702 7.80676 3.20702 7.51343 2.86035 7.16676C2.26035 6.5001 1.86035 5.7001 1.86035 4.76676C1.86035 3.83343 2.59368 3.1001 3.52702 3.1001H3.96035C3.82702 3.40676 3.76035 3.74676 3.76035 4.1001V6.1001C3.76035 6.76676 3.90035 7.39343 4.14702 7.96676Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8535 7.96676C13.3535 7.80676 13.7935 7.51343 14.1402 7.16676C14.7402 6.5001 15.1402 5.7001 15.1402 4.76676C15.1402 3.83343 14.4068 3.1001 13.4735 3.1001H13.0402C13.1735 3.40676 13.2402 3.74676 13.2402 4.1001V6.1001C13.2402 6.76676 13.1002 7.39343 12.8535 7.96676Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CupIcon;
