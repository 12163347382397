import { FC, useState } from "react";
import classNames from "classnames";
import { ItemProps } from "./type";
import { BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP } from "../cn";
import Price from "../common/price";
import BundleImage from "../common/bundle-image";
import { FormattedNumber } from "react-intl";
import PurchaseDialog from "../common/purchase-dialog";

const threeInOneItem: FC<ItemProps> = ({
  data,
  color,
  isFullWidth = false,
}) => {
  const [openPurchaseModal, setOpenPurchaseModal] = useState<boolean>(false);

  const { asset, price, bundleProducts } = data;

  const { threeInOneWrapperClassName, threeInOneBg } =
    BUNDLE_CATEGORY_COLOR_CLASSNAMES_MAP[color];

  return (
    <>
      <PurchaseDialog
        open={openPurchaseModal}
        onClose={() => setOpenPurchaseModal(false)}
        data={data}
        color={color}
        view="three-in-one"
        imageClassName="!w-12"
      />
      <div
        className={classNames(
          isFullWidth ? "w-full" : "w-[90%]",
          "shrink-0 box-shadow-0-4 flex flex-col rounded-3xl overflow-hidden",
          threeInOneWrapperClassName
        )}
        onClick={() => setOpenPurchaseModal(true)}
      >
        <div
          className="h-40 bg-cover bg-no-repeat bg-center flex flex-col items-center justify-center gap-1"
          style={{ backgroundImage: `url("${threeInOneBg}")` }}
        >
          <BundleImage data={data} color={color} view="three-in-one" />
          <div className="px-2 py-1 bg-blue rounded bg-blue-750 text-sm leading-4">
            x <FormattedNumber value={bundleProducts[0].amount} />{" "}
            {bundleProducts[0]?.product?.title}
          </div>
        </div>
        <div className="p-2 pb-1 w-full flex items-center justify-center">
          <Price asset={asset} price={price} color={color} />
        </div>
      </div>
    </>
  );
};

export default threeInOneItem;
