import type { ReactNode, ReactPortal } from "react";
import { createPortal } from "react-dom";

const Portal: (args: {
  /**
   * The content of the Portal
   */
  children: ReactNode;
  /**
   * The container that Portal content load on it
   */
  container?: Element;
}) => ReactPortal = ({ children, container }) =>
  createPortal(children, container || document.body);

export default Portal;
