import { useCall } from "./use-call";
import {
  EachFriendsResponse,
  QuestType,
  TonPayloadType,
  AccountInfoType,
  AuthenticationType,
  UserEntityType,
  BundleCategoryItemType,
  InventoryEntityType,
  SubmissionResponseType,
  BalanceResponseType,
  JourneyResponseType,
  DepositRequestBody,
  AddressesResponseType,
  JourneyCurrentStep,
  UserQuestEntityType,
  ReplaceableSubmissionsType,
  BundleItemType,
  TapDataType,
  TapPurchaseDataType,
  DollarBalanceLogResponseType,
  JourneysSubmissionResponseType,
  ProSubmissionResponseType,
  ProSubmissionItemType,
  ProStatsType,
  LeaderBoardResponseType,
  UserDailyRewardResponseType,
  UserAdQuestResponseType,
  VerifyWatchAdResponse,
  TonBalancesResponse,
} from "@/helpers/api/types/api-response";
import { urlWithParams } from "@/utils/url";
import {
  Account,
  ConnectedWallet,
  TonProofItemReplySuccess,
} from "@tonconnect/ui-react";
import { useLaunchParams } from "@telegram-apps/sdk-react";
import { BundleModelTypes } from "@/global/enum";
import { WithdrawFormValues } from "@/components/pages/wallet/withdraw/content";
import endpoints from "../endpoints";

export interface CheckQuestBodyType {
  id: string;
  actionField?: string;
  extraData?: any;
  actionUrl?: string;
}

export interface CheckProofMetaData
  extends Partial<Pick<ConnectedWallet, "imageUrl" | "name">> {}

export interface CheckProofBodyType {
  address: Account["address"];
  network: Account["chain"];
  proof: TonProofItemReplySuccess["proof"] & {
    state_init: Account["walletStateInit"];
  };
  metaData: CheckProofMetaData;
}

export interface BundleParamsType {
  limit?: number;
  page?: number;
  "filters.modelType"?: BundleModelTypes;
  filters?: any;
}

export interface BundleCategoryParamsType {
  filters?: any;
  limit?: number;
}

export interface ProSubmissionParamsType {
  filters?: any;
  limit?: number;
  page: number;
}

export interface DollarBalanceLogParamsType {
  limit?: number;
}

export interface SubmissionPredictBodyType {
  prediction?: string;
  product?: { id: string };
}

export interface ReplaceSubmissionBodyType {
  match: { id: string };
  prediction: boolean[];
}

export interface TapBodyType {
  amount: number;
  token: string;
}

export interface PostNicknameBodyType {
  nickname: string;
}

const useApi = () => {
  const { initDataRaw } = useLaunchParams();
  const call = useCall();
  const generateTonPayload = () =>
    call.post<TonPayloadType>(endpoints.ton.generatePayload);
  const checkProof = (body: CheckProofBodyType) =>
    call.post<AccountInfoType>(endpoints.ton.checkProof, { body });
  const disconnectTonWallet = () => call.delete(endpoints.ton.disconnect);
  const getAccountInfo = () =>
    call.get<AccountInfoType>(endpoints.ton.getAccountInfo);
  const login = () =>
    call.post<AuthenticationType>(endpoints.auth.login, {
      body: { initData: initDataRaw },
    });
  const getUserProfile = () => call.get<UserEntityType>(endpoints.auth.me);
  const getUserFriends = () =>
    call.get<EachFriendsResponse[]>(endpoints.user.friends);
  const getBundleCategory = (params?: BundleCategoryParamsType) =>
    call.get<BundleCategoryItemType[]>(endpoints.bundle.bundleCategory, {
      params,
    });
  const purchaseBundles = (id: string) =>
    call.post(urlWithParams(endpoints.bundle.purchase, { id }));
  const getJourneys = () =>
    call.get<JourneyResponseType>(endpoints.journey.index);
  const createSubmissionStep = () => call.post(endpoints.submissions.step);
  const paySubmissionStep = () => call.post(endpoints.submissions.pay);
  const getJourneyMatches = () =>
    call.get<JourneysSubmissionResponseType>(endpoints.submissions.step);
  const submitProMatches = () =>
    call.post<ProSubmissionResponseType>(endpoints.pro.index);
  const getJourneyCurrentStep = () =>
    call.get<JourneyCurrentStep>(endpoints.submissions.currentStep);
  const getUserInventory = () =>
    call.get<InventoryEntityType[]>(endpoints.inventories.index);
  const predict = (id: string, body: SubmissionPredictBodyType) =>
    call.post(urlWithParams(endpoints.submissions.predict, { id }), { body });
  const submitPredict = () => call.post(endpoints.submissions.submit);
  const submitPro = () => call.post(endpoints.pro.submit);
  const getBalances = () =>
    call.get<BalanceResponseType[]>(endpoints.balances.index);
  const withdraw = (body: WithdrawFormValues) =>
    call.post(endpoints.transactions.withdraw, { body });
  const deposit = (body: DepositRequestBody) =>
    call.post(endpoints.transactions.deposit, { body });
  const getAssetBalance = (id: string) =>
    call.get<string>(urlWithParams(endpoints.balances.assetBalance, { id }));
  const getWalletAddresses = () =>
    call.get<AddressesResponseType[]>(endpoints.networkAsset.index);
  const getUserQuests = () =>
    call.get<UserQuestEntityType[]>(endpoints.quests.userQuests);
  const startQuest = (id: string) =>
    call.get(urlWithParams(endpoints.quests.startQuest, { id }));
  const checkQuest = (body: CheckQuestBodyType) => {
    return call.post<Pick<QuestType, "actionUrl" | "purchase">>(
      urlWithParams(endpoints.quests.checkQuest, { id: body.id }),
      {
        body,
      }
    );
  };
  const getSubmissionByStepId = (id: string) =>
    call.get<JourneysSubmissionResponseType>(
      urlWithParams(endpoints.submissions.stepId, { id })
    );
  const getProSubmissionById = (id: string) =>
    call.get<ProSubmissionResponseType>(
      urlWithParams(endpoints.submissions.submissionId, { id })
    );
  const claimReward = (id: string) =>
    call.post(urlWithParams(endpoints.purchase.claimReward, { id }));
  const getReplaceableSubmission = (id: string) =>
    call.get<ReplaceableSubmissionsType[]>(
      urlWithParams(endpoints.submissions.replaceSubmission, { id })
    );
  const replaceSubmission = (id: string, body: ReplaceSubmissionBodyType) =>
    call.post(urlWithParams(endpoints.submissions.replaceSubmission, { id }), {
      body,
    });
  const getBundleByProductId = (id: string) =>
    call.post<BundleItemType>(
      urlWithParams(endpoints.bundle.purchaseProduct, { id })
    );
  const useDataCard = (
    id: string,
    body: Required<Pick<SubmissionPredictBodyType, "product">>
  ) =>
    call.post<SubmissionResponseType>(
      urlWithParams(endpoints.submissions.product, { id }),
      { body }
    );
  const getTaps = (reqInit?: { signal?: AbortSignal }) =>
    call.get<TapDataType>(endpoints.taps.index, { ...reqInit });
  const tap = (body: TapBodyType, reqInit?: { signal?: AbortSignal }) =>
    call.post<TapDataType>(endpoints.taps.index, { body, ...reqInit });
  const getBundlePurchase = (id: string) =>
    call.get<TapPurchaseDataType>(
      urlWithParams(endpoints.taps.purchases, { id })
    );
  const getDollarBalanceLog = (params: DollarBalanceLogParamsType) =>
    call.get<DollarBalanceLogResponseType>(
      endpoints.transactions.dollarBalance,
      { params }
    );

  const getProSubmissions = (params?: ProSubmissionParamsType) =>
    call.get<{ data: ProSubmissionItemType[]; hasNextPage: boolean }>(
      endpoints.submissions.index,
      {
        params,
      }
    );
  const checkNickname = (nickname: string) =>
    call.get<"true" | "false">(
      urlWithParams(endpoints.user.nickname, { nickname })
    );
  const postNickname = (body: PostNicknameBodyType) =>
    call.post<UserEntityType>(endpoints.user.postNickname, { body });

  const retryProSubmission = () => call.post(endpoints.pro.retry);

  const getUserStats = () => call.get<ProStatsType>(endpoints.pro.stats);

  const getLeaderBoard = (reqInit?: { signal?: AbortSignal }) =>
    call.get<LeaderBoardResponseType>(endpoints.pro.leaderBoard, {
      ...reqInit,
    });

  const getProSubmissionsRewards = (reqInit?: { signal?: AbortSignal }) =>
    call.get<ProSubmissionItemType[]>(endpoints.pro.submissionRewards, {
      ...reqInit,
    });

  const getLeaderBoardRewards = (reqInit?: { signal?: AbortSignal }) =>
    call.get<ProSubmissionItemType[]>(endpoints.pro.leaderBoardRewards, {
      ...reqInit,
    });

  const getDailyRewards = () =>
    call.get<UserDailyRewardResponseType[]>(endpoints.quests.dailyRewards);

  const claimDailyReward = () => call.post(endpoints.quests.dailyRewards);

  const getUserAds = () =>
    call.get<UserAdQuestResponseType>(endpoints.quests.userAds);

  const verifyWatchedAd = (body: { blockId: number }) =>
    call.post<VerifyWatchAdResponse>(endpoints.quests.verifyWatched, { body });

  const getTonBalances = (reqInit?: { signal?: AbortSignal }) =>
    call.get<TonBalancesResponse[]>(endpoints.ton.getBalances, { ...reqInit });

  return {
    getUserFriends,
    generateTonPayload,
    checkProof,
    disconnectTonWallet,
    getAccountInfo,
    getUserProfile,
    login,
    getBundleCategory,
    purchaseBundles,
    getJourneys,
    getJourneyMatches,
    getUserInventory,
    predict,
    submitPredict,
    getBalances,
    createSubmissionStep,
    withdraw,
    deposit,
    getAssetBalance,
    getWalletAddresses,
    getJourneyCurrentStep,
    getUserQuests,
    startQuest,
    checkQuest,
    getSubmissionByStepId,
    claimReward,
    getReplaceableSubmission,
    paySubmissionStep,
    replaceSubmission,
    getBundleByProductId,
    useDataCard,
    getTaps,
    tap,
    getBundlePurchase,
    getDollarBalanceLog,
    getProSubmissions,
    submitProMatches,
    submitPro,
    checkNickname,
    postNickname,
    retryProSubmission,
    getUserStats,
    getProSubmissionById,
    getLeaderBoard,
    getProSubmissionsRewards,
    getLeaderBoardRewards,
    getDailyRewards,
    claimDailyReward,
    getUserAds,
    verifyWatchedAd,
    getTonBalances,
  };
};

export default useApi;
