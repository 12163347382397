import { useData } from "@/store/store";
import useApi from "../helpers/api/hooks/use-api";
import { groupBy } from "@/utils/object";
import { BalanceMap } from "@/store/user";
import { AssetUnit } from "@/global/enum";

const useBalance = () => {
  const { getBalances } = useApi();
  const { setBalances, balances } = useData();

  const minWithdrawableBalance = +(
    balances?.[AssetUnit.USD]?.asset.minWithdrawableBalance || 0
  );

  const updateBalances = () =>
    new Promise<BalanceMap>((resolve, reject) =>
      getBalances()
        .then((res) => {
          const data = Object.fromEntries(
            Object.entries(groupBy(res, "asset.unit")).map(([key, value]) => [
              key,
              value[0],
            ])
          ) as any;
          setBalances(data);
          resolve(data);
          return data;
        })
        .catch(reject)
    );
  return { updateBalances, minWithdrawableBalance };
};

export default useBalance;
