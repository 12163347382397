import { type SVGProps, useRef } from "react";

const ConnectIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <g clipPath="url(#clip0_2298_2094)">
        <path
          d="M20.5111 2.83698L17.4685 5.87958C16.5609 5.27952 15.472 5.01588 14.3905 5.13434C13.3089 5.25279 12.3029 5.74589 11.5467 6.52818L7.40734 10.6676C6.62933 11.4244 6.13986 12.4291 6.02338 13.5082C5.9069 14.5874 6.1707 15.6733 6.76929 16.5788L3.63704 19.711L4.69167 20.7656L7.81864 17.6387C8.72371 18.2423 9.81214 18.5085 10.8936 18.391C11.9751 18.2734 12.9809 17.7794 13.7351 16.9954L17.8745 12.8559C18.6585 12.1018 19.1525 11.096 19.2701 10.0145C19.3877 8.93298 19.1214 7.84455 18.5178 6.93948L21.5657 3.89161L20.5111 2.83698ZM14.2255 14.3957L12.6805 15.9407C12.1198 16.5413 11.3443 16.8956 10.5232 16.9263C9.70215 16.9569 8.90234 16.6614 8.2985 16.1042C7.74133 15.5003 7.44579 14.7005 7.47641 13.8795C7.50704 13.0584 7.86136 12.2829 8.46196 11.7222L10.0123 10.1719L14.2255 14.3957ZM16.8199 11.8013L15.2379 13.3833L11.0194 9.16474L12.6014 7.5828C13.162 6.9822 13.9376 6.62788 14.7586 6.59725C15.5797 6.56662 16.3795 6.86217 16.9834 7.41934C17.5405 8.02318 17.8361 8.82299 17.8054 9.64404C17.7748 10.4651 17.4205 11.2407 16.8199 11.8013Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2298_2094">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectIcon;
