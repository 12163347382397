// TODO: Refactor this component to use the existing button component.
// The existing button component is very limited in terms of styling.

import classNames from "classnames";
import { ComponentProps, FC } from "react";

export type QuestActionButtonProps = ComponentProps<"button"> & {
  appearance: "primary" | "secondary";
};

// This should be refactored to use the existing button component later.
const QuestActionButton: FC<QuestActionButtonProps> = ({
  children,
  appearance,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "rounded-lg border min-w-20 py-1 font-bold text-sm box-shadow-inner-0-1-md text-neutral-50 disabled:opacity-50",
        appearance === "primary"
          ? "bg-blue-750 border-blue-800 text-stroke-md-blue-900 text-shadow-0--1-blue-900 shadow-blue-850"
          : "bg-yellow-600 border-yellow-700 text-stroke-md-yellow-950 text-shadow-0--1-yellow-950 shadow-yellow-900"
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default QuestActionButton;
