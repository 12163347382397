import { useData } from "@/store/store";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ActionButton from "./action-button";
import { ExportIcon, ImportIcon } from "@/components/icons";
import paths from "@/navigation/paths";
import { AssetUnit } from "@/global/enum";
import { FC } from "react";

const Balance: FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { balances } = useData();
  return (
    <div className="mb-4 flex flex-col justify-center items-center gap-6 pt-10 pb-6 rounded-b-[2.5rem] bg-gradient-to-tr from-blue-900 to-blue-700 border border-blue-300 border-t-0">
      <div className="flex flex-col gap-4 text-blue-100 font-bold mt-11 items-center">
        <p className="text-2xl text-center animate-fade-in text-shadow-0-1-blue-900">
          <FormattedMessage id="your-balance" />
        </p>
        <p className="text-5xl text-shadow-0-1-blue-900 text-stroke-lg-blue-900 animate-from-left">
          <FormattedNumber
            style="currency"
            currency="USD"
            value={balances![AssetUnit.USD].amount}
          />
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2 animate-fade-in w-full px-6">
        <ActionButton
          icon={<ImportIcon />}
          label={formatMessage({ id: "wallet-type-deposit" })}
          onClick={() => navigate(paths.wallet.deposit)}
        />
        <ActionButton
          icon={<ExportIcon />}
          label={formatMessage({ id: "wallet-type-withdraw" })}
          onClick={() => navigate(paths.wallet.withdraw)}
        />
      </div>
    </div>
  );
};

export default Balance;
