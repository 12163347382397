import Image from "@/components/common/image";
import {
  CrownIcon,
  CupIcon,
  FilledTriangleIcon,
  FlashIcon,
} from "@/components/icons";
import { CACHE_KEYS } from "@/constants";
import { FC, Fragment, useEffect } from "react";
import Card from "@/components/pages/predict-pro/tabs/leader-board/card";
import { useIntl } from "react-intl";
import useApi from "@/helpers/api/hooks/use-api";
import { ProStatsType } from "@/helpers/api/types/api-response";
import Spinner from "@/components/common/spinner";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import FetchError from "@/components/common/fetch-error";
import BundleAmount from "@/components/common/dollar-amount";
import { useData } from "@/store/store";

const LeaderBoardReward: FC = () => {
  const { formatMessage } = useIntl();
  const { getUserStats } = useApi();
  const { balances } = useData();

  const { fetch, data, error } = useApiCache<ProStatsType>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getUserStats()
          .then((res) => {
            setCache(res);
            resolve(res);
          })
          .catch(reject)
      ),
    [CACHE_KEYS.PRO_STATS]
  );

  useEffect(() => {
    fetch();
  }, []);

  if (error)
    return (
      <FetchError className="py-20" message={error.message} onRetry={fetch} />
    );

  if (!data)
    return (
      <div className="flex items-center justify-center mt-10">
        <Spinner />
      </div>
    );

  const statCards = [
    {
      title: formatMessage({ id: "highest-point" }),
      value: data?.highestPoint || 0,
      icon: <FlashIcon className="rotate-90 text-neutral-50" />,
      subIcon: <FilledTriangleIcon className="text-green-300" />,
    },
    {
      title: formatMessage({ id: "daily-average-point" }),
      value: data?.avgPoint || 0,
      icon: <CrownIcon className="text-neutral-50" />,
      subIcon: <div className="bg-green-300 rounded-full w-3.5 h-3.5" />,
    },
  ];

  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className="flex gap-3">
        {statCards.map((card) => (
          <Card key={card.title} {...card} />
        ))}
      </div>
      {data.totalAssets.length > 0 && (
        <Card
          title={formatMessage({ id: "total-reward" })}
          icon={<CupIcon className="text-neutral-50" />}
        >
          <div className="bg-blue-850 rounded-lg border border-blue-900 flex justify-between items-center">
            {data.totalAssets?.map(({ asset, totalAmount }, index) => {
              const selectedAsset = Object.values(balances!).find(
                ({ asset: { id } }) => id === asset.id
              );
              if (!selectedAsset) return <Fragment key={asset.id} />;
              return (
                <div
                  key={asset.id}
                  className={`flex-1 text-center py-1 ${
                    index === 0 ? "border-none" : "border-s border-blue-900"
                  }`}
                >
                  <div className="flex justify-center items-center gap-0.5">
                    <Image
                      src={selectedAsset.asset.logo.path}
                      className="w-[1.125rem] mb-0.5"
                      alt={selectedAsset.asset.title}
                    />
                    <p className="font-bold text-base text-shadow-0-2-blue-900">
                      <BundleAmount
                        rewardAmount={totalAmount}
                        asset={selectedAsset.asset}
                        withSuffix
                      />
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      )}
    </div>
  );
};

export default LeaderBoardReward;
