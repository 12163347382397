import * as messages from "./locales";
import { Locale } from "./type";

const locales: Locale[] = [
  {
    id: "en",
    messages: messages.ENGLISH,
    title: "English",
    direction: "ltr",
    default: true,
  },
  // { id: "tr", messages: messages.TURKISH, title: "Türkçe", direction: "ltr" },
  // { id: "ar", messages: messages.ARABIC, title: "العربیه", direction: "rtl" },
  // { id: "fa", messages: messages.FARSI, title: "فارسی", direction: "rtl" },
  // { id: "ru", messages: messages.RUSSIAN, title: "Русский", direction: "ltr" },
  // { id: "cn", messages: messages.CHINESE, title: "中国人", direction: "ltr" },
  // { id: "de", messages: messages.DEUTSCHE, title: "Deutsch", direction: "ltr" },
  // { id: "ja", messages: messages.JAPANESE, title: "日本語", direction: "ltr" },
  // {
  //   id: "pr",
  //   messages: messages.PORTUGUESE,
  //   title: "Português",
  //   direction: "ltr",
  // },
];

export default locales;
