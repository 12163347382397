import {
  useIsConnectionRestored,
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
  useTonAddress,
} from "@tonconnect/ui-react";
import useApi from "../helpers/api/hooks/use-api";
import { useData } from "@/store/store";
import toast from "react-hot-toast";
import { ERROR_STATUS } from "@/constants";
import { useState } from "react";
import { showErrorMessage } from "@/utils/string";

const useWallet = () => {
  const connectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const address = useTonAddress();
  const [tonUi] = useTonConnectUI();
  const [error, setError] = useState<any>();
  const [fetchingPayload, setFetchingPayload] = useState<boolean>(false);
  const [disconnecting, setDisconnecting] = useState<boolean>(false);
  const { disconnectTonWallet, getAccountInfo, generateTonPayload } = useApi();
  const { state, open } = useTonConnectModal();
  const {
    disconnectWalletInfo,
    isCheckingProof,
    walletInfo,
    connectWalletInfo,
    walletStatus,
  } = useData();
  const disconnect = () => {
    setDisconnecting(true);
    return disconnectTonWallet()
      .then(() => {
        setTimeout(() => {
          disconnectLocally();
        }, 250);
      })
      .catch((e) =>
        toast.error(showErrorMessage(e.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setDisconnecting(false));
  };

  const loading =
    state.status === "opened" ||
    !connectionRestored ||
    isCheckingProof ||
    fetchingPayload ||
    disconnecting;

  const isWalletConfirmed =
    !!walletInfo && !!wallet && !!address && !isCheckingProof;

  const disconnectLocally = () => {
    tonUi.disconnect();
    disconnectWalletInfo();
  };

  const fetchWalletInfo = () => {
    setError(null);
    return getAccountInfo()
      .then((res) => {
        if (res.address === wallet?.account.address) {
          connectWalletInfo(res);
        } else {
          disconnectLocally();
        }
      })
      .catch((err) => {
        if (err.statusCode === ERROR_STATUS.NOT_FOUND) {
          disconnectLocally();
        } else {
          setError(err);
        }
      });
  };

  const fetchGenerateTonPayload = () => {
    setFetchingPayload(true);
    tonUi.setConnectRequestParameters({
      state: "loading",
    });
    return generateTonPayload()
      .then((res) => {
        tonUi.setConnectRequestParameters({
          state: "ready",
          value: { tonProof: res.payload },
        });
        open();
      })
      .catch((e) => {
        toast.error(showErrorMessage(e.message), {
          position: "top-center",
          duration: 5000,
        });
        tonUi.setConnectRequestParameters(null);
      })
      .finally(() => setFetchingPayload(false));
  };

  return {
    loading,
    fetchWalletInfo,
    disconnect,
    error,
    isWalletConfirmed,
    fetchGenerateTonPayload,
    walletStatus,
  };
};

export default useWallet;
