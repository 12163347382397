import Page from "@/components/common/page";
import DepositContent from "@/components/pages/wallet/deposit/content";
import { FC } from "react";

const DepositPage: FC = () => (
  <Page>
    <DepositContent />
  </Page>
);

export default DepositPage;
