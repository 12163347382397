import { cloneElement, FC, PropsWithChildren } from "react";
import classNames from "classnames";
import navList from "./nav-list";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "@/navigation/paths";

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const hasBottomNavigationPaths = navList.some(({ to }) => to === pathname);
  const isInFirstPageCurrently = navList.find(
    ({ to }) => to === pathname
  )?.isFirstPage;
  return (
    <>
      <main
        className={classNames(
          "h-stable-screen relative flex flex-col bg-fixed layout",
          hasBottomNavigationPaths && "layout-with-bottom-navigation",
          pathname === paths.journeys.index ? "levels-bg" : "app-bg"
        )}
      >
        {children}
      </main>
      {hasBottomNavigationPaths && (
        <footer className="fixed bottom-0 start-0 end-0 z-[1000] bg-blue-950 shadow-box shadow-[#DAE6FF0D] home-bar-spacing">
          <div className="max-w-2xl mx-auto px-1 grid grid-cols-5 text-white justify-items-center">
            {navList.map(({ to, icon, title, isFirstPage }) => {
              const isActive = pathname === to;
              const enhancedIcon = cloneElement(icon, {
                className: classNames(
                  "object-contain transition-size",
                  icon.props.className,
                  isActive ? "h-20 w-20" : "h-16 w-16"
                ),
              });
              return (
                <div
                  key={to}
                  onClick={() => {
                    if (to === pathname) return;
                    if (isFirstPage) return navigate(-1);
                    navigate(to, { replace: !isInFirstPageCurrently });
                  }}
                  className={classNames(
                    isActive && "pb-2 before:opacity-100",
                    "navigation-item"
                  )}
                >
                  <div
                    className={classNames(
                      "transition-spacing z-10",
                      isActive && "-mt-6"
                    )}
                  >
                    {enhancedIcon}
                  </div>
                  <p className="font-bold text-xs z-10">{title}</p>
                </div>
              );
            })}
          </div>
        </footer>
      )}
    </>
  );
};

export default MainLayout;
