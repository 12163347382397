import { SVGProps, useRef } from "react";

const CrownIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <path
        d="M1.33301 10.3933V4.00663C1.33301 3.11996 1.84634 2.90663 2.47301 3.5333L4.19967 5.25996C4.45967 5.51996 4.88634 5.51996 5.13967 5.25996L7.52634 2.86663C7.78634 2.60663 8.21301 2.60663 8.46634 2.86663L10.8597 5.25996C11.1197 5.51996 11.5463 5.51996 11.7997 5.25996L13.5263 3.5333C14.153 2.90663 14.6663 3.11996 14.6663 4.00663V10.4C14.6663 12.4 13.333 13.7333 11.333 13.7333H4.66634C2.82634 13.7266 1.33301 12.2333 1.33301 10.3933Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrownIcon;
