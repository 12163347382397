import Image from "@/components/common/image";
import { FlashIcon } from "@/components/icons";
import { IMAGES } from "@/constants";
import { useData } from "@/store/store";
import classNames from "classnames";
import { FC } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

const TapPower: FC = () => {
  const { tapData } = useData();
  const percentage = Math.max(0, (tapData!.remain / tapData!.tapLimit) * 100);
  const getProgressClassName = () => {
    if (percentage < 30) return "from-red-500 to-red-700";
    if (percentage < 70) return "from-yellow-400 to-yellow-600";
    return "from-green-500 to-green-700";
  };
  return (
    <div className="animate-from-right flex-1 flex justify-between flex-col gap-1 p-2 bg-gradient-to-b from-blue-650 to-blue-800 rounded-2xl box-shadow-0-4 shadow-blue-850">
      <div className="flex justify-between">
        <div className="flex gap-1 items-center">
          <Image src={IMAGES.TAP_IMAGE} className="w-8 h-8" alt="tap power" />
          <p className="font-bold text-shadow-0-1-blue-950 text-stroke-md-blue-900 text-lg">
            <FormattedMessage id="power" />
          </p>
        </div>
        <div className="bg-blue-850 rounded-lg border border-blue-900 font-bold text-sm text-shadow-0-1-blue-950 box-shadow-inner-0-1-md shadow-blue-950 flex items-center justify-center h-8 w-8 leading-3">
          <FormattedNumber value={tapData!.power} />
        </div>
      </div>
      <div className="flex items-center gap-1 py-1 ps-2.5 pe-1.5 bg-blue-850 rounded-lg border border-blue-900 box-shadow-inner-0-1-md shadow-blue-950">
        <FlashIcon className="shrink-0" />
        <p className="font-bold text-shadow-0-1-blue-950 text-stroke-md-blue-900 text-base w-11 shrink-0">
          <FormattedNumber value={percentage} maximumFractionDigits={0} />%
        </p>
        <div className="bg-neutral-0 p-0.5 rounded-md w-full relative ms-1">
          <div
            className={classNames(
              "flex justify-center items-center bg-gradient-to-b rounded h-4",
              getProgressClassName()
            )}
            style={{ width: `${percentage}%` }}
          />
          <div className="w-1 h-2.5 bg-neutral-0 rounded-e-lg absolute top-1/2 -translate-y-1/2 -end-1 border" />
        </div>
      </div>
    </div>
  );
};

export default TapPower;
