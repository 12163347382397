import { ArrowDownIcon } from "@/components/icons";
import classNames from "classnames";
import { useState, useRef, useEffect, ReactNode, HTMLAttributes } from "react";

type DropdownValueType = string | number;

export interface DropdownItem<T = DropdownValueType> {
  value: T;
  title: string | ReactNode;
  className?: string;
}

interface DropdownProps<T = DropdownValueType>
  extends Omit<HTMLAttributes<HTMLButtonElement>, "children" | "onSelect"> {
  options?: DropdownItem<T>[];
  onSelect?: (v: T) => void;
  value?: T;
  selectedClassName?: string;
  disabled?: boolean;
}

function Dropdown<T = DropdownValueType>({
  options = [],
  onSelect = () => {},
  disabled = false,
  selectedClassName = "bg-neutral-0/[0.15]",
  value: selectedOption,
  className,
  ...restProps
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        className={classNames(
          disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
          "flex w-28 justify-center gap-2 items-center rounded-full py-2 z-50 text-sm font-normal bg-gradient-to-b from-blue-200 to-blue-400 box-shadow-inner-0--2 shadow-neutral-1000/25 focus:outline-none text-blue-850",
          className
        )}
        {...restProps}
      >
        {options.find(({ value }) => selectedOption === value)?.title}
        <ArrowDownIcon
          className={classNames(
            isOpen && "rotate-180",
            "transition-all delay-100 ease-in-out"
          )}
        />
      </button>
      {isOpen && (
        <ul
          role="listbox"
          className="absolute w-full rounded-2xl bg-blue-850 focus:outline-none overflow-hidden animate-from-right"
        >
          {options.map(({ title, value, className }) => {
            const isSelected = selectedOption === value;
            return (
              <li
                key={`${value}`}
                onClick={() => {
                  onSelect(value);
                  setIsOpen(false);
                }}
                className={classNames(
                  isSelected && selectedClassName,
                  className,
                  "cursor-pointer select-none py-3 text-sm text-blue-300 text-center font-normal hover:bg-neutral-0/10"
                )}
                role="option"
                aria-selected={isSelected}
              >
                {title}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
