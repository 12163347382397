import { type SVGProps, useRef } from "react";

const CalendarIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g id="vuesax/linear/calendar-2" opacity="0.5">
        <g id="vuesax/linear/calendar-2_2">
          <g id="calendar-2">
            <path
              id="Vector"
              d="M5.33337 1.53333V3.53333"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M10.6666 1.53333V3.53333"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M2.33337 6.26001H13.6667"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_4"
              d="M14 5.86666V11.5333C14 13.5333 13 14.8667 10.6667 14.8667H5.33333C3 14.8667 2 13.5333 2 11.5333V5.86666C2 3.86666 3 2.53333 5.33333 2.53333H10.6667C13 2.53333 14 3.86666 14 5.86666Z"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_5"
              d="M7.99703 9.33333H8.00302"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_6"
              d="M5.5295 9.33333H5.53549"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_7"
              d="M5.5295 11.3333H5.53549"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CalendarIcon;
