import Button from "@/components/common/button";
import Card from "@/components/common/card";
import Dialog from "@/components/common/dialog";
import Image from "@/components/common/image";
import { IMAGES } from "@/constants";
import { InventoryEntityType } from "@/helpers/api/types/api-response";
import classNames from "classnames";
import { FC, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ShopContext } from "../../context";

interface InventoryItemProps {
  data: InventoryEntityType;
  cardClassName?: string;
}

const InventoryItem: FC<InventoryItemProps> = ({ data, cardClassName }) => {
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const { amount, product } = data;
  const { goToShop } = useContext(ShopContext);
  const isDataCard = !product.functionality;
  return (
    <>
      <Dialog
        open={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
        seedClassName="bg-gradient-no"
      >
        <div
          className="h-[12.5rem] flex items-center justify-center relative bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url("${
              // isDataCard ?
              IMAGES.DATA_CARD_INVENTORY_BANNER
              // : IMAGES.TOOL_CARD_INVENTORY_BANNER
            }")`,
          }}
        >
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-blue-750 px-4 py-2 rounded-t-lg flex items-center gap-1.5 text-base font-bold">
            <Image src={IMAGES.FILLED_INFO} className="h-6 w-6" alt="info" />
            <FormattedMessage id={isDataCard ? "data-card" : "tool-card"} />
          </div>
          <Card
            src={product.logo.path}
            productId={product.id}
            alt={product.title}
            className="w-[4.5rem] -translate-y-3"
          />
        </div>
        <div className="p-4">
          <div className="mt-1 mb-3 text-xl text-stroke-md-blue-900 font-bold">
            {product.title}
          </div>
          <div className="mb-6 text-sm">{product.description}</div>
          <Button color="secondary" fullWidth onClick={goToShop}>
            <FormattedMessage id="buy" />
          </Button>
        </div>
      </Dialog>
      <div
        className="flex justify-center px-1"
        onClick={() => setOpenInfoModal(true)}
      >
        <div className="max-w-[4.25rem] w-full rounded-xl bg-blue-200 box-shadow-0-2 shadow-blue-400 p-1">
          <Card
            productId={product.id}
            src={product.logo.path}
            alt={product.title}
            inventory={amount}
            disabled={!amount}
            className={classNames("w-full -mt-4", cardClassName)}
          />
          <div className="mt-1 flex items-center gap-1 justify-center text-sm text-blue-950 font-bold">
            <Image src={IMAGES.FILLED_INFO} alt="info" className="w-4 h-4" />
            <FormattedMessage id="info" />
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryItem;
