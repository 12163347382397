import { type SVGProps, useRef } from "react";

const CompleteCircleIcon = (props: SVGProps<SVGElement>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      width="8.5rem"
      height="8.25rem"
      viewBox="0 0 136 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={svgRef}
    >
      <g opacity="0.6" filter="url(#filter0_d_3274_5790)">
        <path
          d="M135 64.7529C135 100.099 104.779 128.753 67.5 128.753C30.2208 128.753 0 100.099 0 64.7529C0 29.4067 30.2208 0.75293 67.5 0.75293C104.779 0.75293 135 29.4067 135 64.7529ZM3.89198 64.7529C3.89198 98.0611 32.3703 125.063 67.5 125.063C102.63 125.063 131.108 98.0611 131.108 64.7529C131.108 31.4447 102.63 4.4431 67.5 4.4431C32.3703 4.4431 3.89198 31.4447 3.89198 64.7529Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3274_5790"
          x="0"
          y="0.75293"
          width="135"
          height="131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.00392157 0 0 0 0 0.12549 0 0 0 0 0.333333 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3274_5790"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3274_5790"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CompleteCircleIcon;
