export function groupBy<T>(array: T[], key: string): Record<string, T[]> {
  return array.reduce((result, currentItem) => {
    // Use a function to access nested properties
    const groupKey = key
      .split(".")
      .reduce((acc: any, part: string) => acc && acc[part], currentItem);
    const groupKeyString = String(groupKey);

    if (!result[groupKeyString]) {
      result[groupKeyString] = [];
    }
    result[groupKeyString].push(currentItem);

    return result;
  }, {} as Record<string, T[]>);
}
