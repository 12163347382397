export const showErrorMessage = (e?: string): string =>
  e || "An unknown error occurred";

export const formatAddress = (
  address: string,
  startIndex = 7,
  endIndex = 5
): string => {
  if (!address) return "";
  return `${address.slice(0, startIndex)}...${address.slice(
    address.length - endIndex
  )}`;
};

export const countCharacterOccurrences = (
  str: string,
  char: string
): undefined | number => {
  let count = 0;

  // Loop through each character in the string
  for (let i = 0; i < str.length; i++) {
    if (str[i] === char) {
      count++;
    }
  }

  // Return the count
  return count;
};
