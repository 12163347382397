import { FC, useEffect, useRef, useState } from "react";
import MatchesContent, { MatchesContentRef } from "@/components/pages/matches";
import {
  StepResponseType,
  SubmissionResponseType,
} from "@/helpers/api/types/api-response";
import { dayjs } from "@/utils/dayjs";
import paths from "@/navigation/paths";
import { useNavigate } from "react-router-dom";
import useApi from "@/helpers/api/hooks/use-api";
import { showErrorMessage } from "@/utils/string";
import toast from "react-hot-toast";
import { convertPredictionToString } from "@/components/common/match/utils";
import Page from "@/components/common/page";

const JourneysMatchesPage: FC = () => {
  const [matchesData, setMatchesData] = useState<SubmissionResponseType>();
  const [matchesStep, setMatchesStep] = useState<StepResponseType>();
  const [matchesError, setMatchesError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onExpire = () => navigate(paths.journeys.timeIsUp, { replace: true });
  const { getJourneyMatches, submitPredict } = useApi();
  const matchesContentRef = useRef<MatchesContentRef>(null);
  const fetchMatches = () => {
    setMatchesError(null);
    return getJourneyMatches()
      .then((res) => {
        setMatchesData(res);
        matchesContentRef.current?.setPrediction(
          res.items.map(({ prediction }) => ({
            isRegistered: !!prediction,
            result: convertPredictionToString(prediction),
          }))
        );
        setMatchesStep(res.step);
      })
      .catch(setMatchesError);
  };

  const onSubmit = (prediAmount: number) => {
    setLoading(true);
    return submitPredict()
      .then(() => {
        navigate(paths.journeys.submit, {
          replace: true,
          state: {
            stepNumber: matchesStep!.displayOrder,
            prediAmount,
            startFirstMatchTime: Math.min(
              ...matchesData!.items.map(({ match }) =>
                dayjs(match.startTimestamp).valueOf()
              )
            ),
          },
        });
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  return (
    <Page>
      <MatchesContent
        matchesData={matchesData}
        matchesStep={matchesStep}
        setMatchesData={setMatchesData}
        matchesError={matchesError}
        onExpire={onExpire}
        onRetry={fetchMatches}
        loading={loading}
        onSubmit={onSubmit}
        ref={matchesContentRef}
      />
    </Page>
  );
};

export default JourneysMatchesPage;
